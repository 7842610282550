import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { validate } from '../models/LoginForm';
import Link from '../../../components/Link';
import { routes } from '../../../routesConstants';
import { TextField } from '../../../common/form/fields';
import InlineNotification from '../../../components/InlineNotification';
import { normalizeEmail } from '../../../common/form/normalizers';

const FORM_ID = 'LoginForm';

class LoginForm extends Component {
  render() {
    const { handleSubmit, loginFn, pristine, submitting, error } = this.props;
    return (
      <div className="static-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <Link to="/" className="close">
                <span>×</span>
                <span className="sr-only">Close</span>
              </Link>
              <h4 className="modal-title">Sign in</h4>
            </div>
            <form onSubmit={handleSubmit(loginFn)}>
              <div className="modal-body">
                <Field
                  formId={FORM_ID}
                  name="email"
                  component={TextField}
                  label="Email address"
                  placeholder="Enter your email address"
                  normalize={normalizeEmail}
                />
                <Field
                  formId={FORM_ID}
                  name="password"
                  component={TextField}
                  label="Password"
                  placeholder="Enter your password"
                  type="password"
                />
                {error && <InlineNotification type="danger">{error}</InlineNotification>}
              </div>
              <div className="modal-footer modal-footer-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={pristine || submitting}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
          <div className="row">
            <div className="col-sm-6 text-center small">
              <Link to={routes.ROUTE_AUTH_CREATE_ACCOUNT}>Create account</Link>
            </div>
            <div className="col-sm-6 text-center small">
              <Link to={routes.ROUTE_AUTH_RESET_PASSWORD}>Forgot password?</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  loginFn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_ID,
  validate: validate
})(LoginForm);
