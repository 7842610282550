import { FLAG_BETA, FLAG_EXPERIMENTAL } from '../constants';

const Badge = ({ label, className }) => <div className={`badge ${className}`}>{label}</div>;

const BadgeBeta = () => <Badge label="Beta" className="beta" />;

const BadgeExperimental = () => <Badge label="Experimental" className="experimental" />;

const ComponentBadge = ({ flags = [] }) => {
  const isBeta = flags.includes(FLAG_BETA);
  const isExperimental = flags.includes(FLAG_EXPERIMENTAL);

  if (isExperimental || isBeta) {
    return (
      <>
        {isExperimental && isBeta ? (
          <BadgeBeta />
        ) : (
          <>
            {isBeta && <BadgeBeta />}
            {isExperimental && <BadgeExperimental />}
          </>
        )}
      </>
    );
  }

  return null;
};

export default ComponentBadge;
