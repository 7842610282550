import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Link from '../../../../components/Link';
import { routes } from '../../../../routesConstants';
import SearchInput from '../../../../common/form/SearchInput';
import RequestApprovalForm from './RequestApprovalForm';
import DeprecateAppForm from './DeprecateAppForm';
import RejectionReasonModal from './RejectionReasonModal';
import AppsTableRow from './AppsTableRow';

class AppsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showApprovalModal: false,
      showDeprecationModal: false,
      showRejectionReasonModal: false,
      appToRequest: null,
      appToDeprecate: null,
      appWithRejectionReason: null,
      query: ''
    };

    this.openApprovalModal = this.openApprovalModal.bind(this);
    this.closeApprovalModal = this.closeApprovalModal.bind(this);
    this.openDeprecationModal = this.openDeprecationModal.bind(this);
    this.closeDeprecationModal = this.closeDeprecationModal.bind(this);
    this.showRejectionReasonModal = this.showRejectionReasonModal.bind(this);
    this.closeRejectionReasonModal = this.closeRejectionReasonModal.bind(this);
    this.onChangeFilterFnDebounced = debounce(this.props.onChangeFilterFn, 300);
  }

  componentDidMount() {
    this.setState({ query: this.props.filter || '' });
  }

  openApprovalModal(app) {
    this.setState({
      showApprovalModal: true,
      appToRequest: app
    });
  }

  closeApprovalModal() {
    this.setState({
      showApprovalModal: false,
      appToRequest: null
    });
  }

  openDeprecationModal(app) {
    this.setState({
      showDeprecationModal: true,
      appToDeprecate: app
    });
  }

  closeDeprecationModal() {
    this.setState({
      showDeprecationModal: false,
      appToDeprecate: null
    });
  }

  showRejectionReasonModal(app) {
    this.setState({
      showRejectionReasonModal: true,
      appWithRejectionReason: app
    });
  }

  closeRejectionReasonModal() {
    this.setState({
      showRejectionReasonModal: false,
      appWithRejectionReason: null
    });
  }

  render() {
    const { apps, deleteApp, isDeletingAppFn, approveApp, getDeleteAppErrorFn, deprecateAppFn } =
      this.props;

    return (
      <div>
        {this.state.appWithRejectionReason && (
          <RejectionReasonModal
            app={this.state.appWithRejectionReason}
            showModal={this.state.showRejectionReasonModal}
            closeModalFn={this.closeRejectionReasonModal}
          />
        )}
        {this.state.appToRequest && (
          <RequestApprovalForm
            app={this.state.appToRequest}
            showModal={this.state.showApprovalModal}
            requestApprovalFn={approveApp}
            closeModalFn={this.closeApprovalModal}
          />
        )}
        {this.state.appToDeprecate && (
          <DeprecateAppForm
            app={this.state.appToDeprecate}
            showModal={this.state.showDeprecationModal}
            deprecateAppFn={deprecateAppFn}
            closeModalFn={this.closeDeprecationModal}
          />
        )}
        <table className="table table-hover apps-list">
          <thead className="search-bar">
            <tr>
              <th />
              <th colSpan="2">
                <SearchInput
                  value={this.state.query}
                  onChangeFn={(query) => {
                    this.setState({ query });
                    if (!query) {
                      this.props.onChangeFilterFn(query);
                    } else {
                      this.onChangeFilterFnDebounced(query);
                    }
                  }}
                  placeholder="Filter by ID, name, type or author"
                />
              </th>
              <th />
              <th />
              <th colSpan="2" className="text-right">
                <Link to={routes.ROUTE_HOME_COMPONENTS_NEW} className="btn btn-primary">
                  Add a component
                </Link>
              </th>
            </tr>
          </thead>
          <thead className="no-top-border">
            <tr>
              <th className="column-width-12" />
              <th className="column-width-36">Component name, ID and author</th>
              <th className="column-width-14">Type</th>
              <th className="column-width-8" colSpan="2">
                Version
              </th>
              <th className="column-width-15">Status</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {apps.map((app) => (
              <AppsTableRow
                key={app.id}
                app={app}
                openApprovalModal={this.openApprovalModal}
                openDeprecationModal={this.openDeprecationModal}
                showRejectionReasonModal={this.showRejectionReasonModal}
                deleteApp={deleteApp}
                getDeleteAppErrorFn={getDeleteAppErrorFn}
                isDeletingAppFn={isDeletingAppFn}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

AppsTable.propTypes = {
  apps: PropTypes.array.isRequired,
  deleteApp: PropTypes.func.isRequired,
  isDeletingAppFn: PropTypes.func.isRequired,
  approveApp: PropTypes.func.isRequired,
  deprecateAppFn: PropTypes.func.isRequired,
  getDeleteAppErrorFn: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  onChangeFilterFn: PropTypes.func.isRequired
};

export default AppsTable;
