import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';

import Link from '../components/Link';
import NavItem from '../components/NavItem';
import { routes } from '../routesConstants';
import Logo from '../components/Logo';

// hack: https://github.com/react-bootstrap/react-bootstrap/issues/2644
// wrapping to span quickly fix problem with styles
const CustomAnchor = ({ children, to, anchorClassName, ...rest }) => {
  return (
    <span>
      <Link to={to} className={anchorClassName} {...rest}>
        {children}
      </Link>
    </span>
  );
};

const NavbarOpen = ({ currentPath }) => {
  return (
    <div>
      <Navbar className="navbar-flex">
        <Navbar.Header>
          <Navbar.Brand>
            <Link className="navbar-brand" to="/">
              <Logo />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight={true} activeHref={currentPath} bsStyle="pills">
            <NavItem href={routes.ROUTE_OPEN_COMPONENTS}>Components</NavItem>
            <NavItem href={routes.ROUTE_OPEN_RESOURCES}>Resources</NavItem>
            <NavItem
              href={routes.ROUTE_HOME}
              componentClass={CustomAnchor}
              anchorClassName="btn btn-primary"
            >
              Account
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <hr className="visible-xs" />
    </div>
  );
};
NavbarOpen.propTypes = {
  currentPath: PropTypes.string.isRequired
};

export default NavbarOpen;
