import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

import { SearchSelectField, ToggleButtonGroupRadioField } from '../../../common/form/fields';
import { untouch, change, Field, reduxForm } from 'redux-form';
import { findNameById } from '../../../utils/helpers';
import { validate } from '../models/ManageUserVendorsModal.js';
import InlineNotification from '../../../components/InlineNotification';

export const FORM_ID = 'ManageUserVendorsDialogForm';

const ActionInfoMessage = ({ isAddAction, vendorName, user }) => (
  <p>
    You are about to{' '}
    {isAddAction ? (
      <span>
        add <strong>{user.name}</strong> to <strong>{vendorName}</strong> vendor.
      </span>
    ) : (
      <span>
        remove <strong>{user.name}</strong> from <strong>{vendorName}</strong> vendor.
      </span>
    )}
  </p>
);

class ManageUserVendorsDialog extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModalIfOk = this.closeModalIfOk.bind(this);
  }

  closeModal() {
    this.props.onHide();
    this.props.reset();
  }

  closeModalIfOk(result) {
    if (result === 'ok') {
      this.closeModal();
    }
  }

  handleSubmit(values) {
    const confirmResult = this.props.onConfirm(
      values.action,
      this.props.user.username,
      values.vendor
    );
    return confirmResult.then(this.closeModalIfOk);
  }

  renderFormBody() {
    const { allVendors, user, formStore } = this.props;
    const isAddAction = formStore.action === 'add';
    const vendor = formStore.vendor;

    const vendors = allVendors.filter((vendor) => {
      return isAddAction ? !user.vendors.includes(vendor.id) : user.vendors.includes(vendor.id);
    });

    const vendorsOptions = [].concat(
      vendors.map((v) => ({
        label: v.name + (v.isPublic === false ? ' (private)' : ''),
        value: v.id
      }))
    );

    return (
      <div>
        <Field
          formId={FORM_ID}
          label="Action"
          name="action"
          component={ToggleButtonGroupRadioField}
          data={[
            { name: 'Add', value: 'add' },
            { name: 'Remove', value: 'remove' }
          ]}
          onChange={() => {
            this.props.dispatch(change(FORM_ID, 'vendor', null));
            this.props.dispatch(untouch(FORM_ID, 'vendor'));
          }}
          nameField="name"
          valueField="value"
        />
        <Field
          formId={FORM_ID}
          name="vendor"
          component={SearchSelectField}
          options={vendorsOptions}
          placeholder="Select vendor"
          label="Vendor"
        />

        {vendor && (
          <ActionInfoMessage
            isAddAction={isAddAction}
            vendorName={findNameById(vendor, allVendors)}
            user={user}
          />
        )}
      </div>
    );
  }

  render() {
    const { show, formStore } = this.props;
    const isAddAction = formStore.action === 'add';

    if (!show) return null;
    return (
      <div className="modal-container">
        <Modal show={this.props.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update membership in vendors</Modal.Title>
          </Modal.Header>
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <Modal.Body>
              {this.renderErrorMessage()}
              <div>{this.renderFormBody()}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                disabled={this.props.pristine || this.props.submitting}
                bsSize="large"
                bsStyle="primary"
              >
                {isAddAction ? 'Add' : 'Remove'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }

  renderErrorMessage() {
    if (this.props.error) {
      return <InlineNotification type="danger">{this.props.error}</InlineNotification>;
    }
  }
}

ManageUserVendorsDialog.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  formStore: PropTypes.object,
  user: PropTypes.object,
  onConfirm: PropTypes.func,
  children: PropTypes.any
};

ManageUserVendorsDialog.defaultProps = {};

export default reduxForm({ form: FORM_ID, initialValues: { action: 'add' }, validate })(
  ManageUserVendorsDialog
);
