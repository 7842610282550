import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from './Footer';
import NavbarHome from './NavbarHome';
import NavbarHomeSimple from './NavbarHomeSimple';
import {
  getProfile,
  getAllVendors,
  getCurrentVendor,
  getIsAdmin
} from '../modules/auth/reducers/profile';
import SelectVendor from '../modules/home/containers/SelectVendor';
import EnableMfa from '../modules/home/containers/EnableMfa';
import { switchVendorAndRedirect } from '../modules/auth/actions/profile';
import { logout } from '../modules/auth/actions/logout';
import { loadUsers, inviteUser } from '../modules/home/actions/vendors';
import { loadVendorApps } from '../modules/home/actions/apps';
import config from '../../config';

class LayoutHome extends Component {
  render() {
    const path = this.props.match.location ? this.props.match.location.pathname : '';
    const { children, allVendors, isAdmin, profile } = this.props;
    if (profile.vendors.length === 0 || profile.mfa === false) {
      return (
        <div>
          <NavbarHomeSimple />
          {profile.mfa === false ? <EnableMfa /> : <SelectVendor />}
          <div className="container">
            <Footer />
          </div>
        </div>
      );
    } else if (profile.currentVendor !== null) {
      return (
        <div>
          <NavbarHome
            currentPath={path.slice(config.BASE_PATH.length)}
            inviteUserFn={this.props.inviteUserFn}
            logoutFn={this.props.logout}
            allVendors={allVendors}
            currentVendor={profile.currentVendor}
            onSelectVendor={this.props.loadUsersAndSelectVendor}
            isAdmin={isAdmin}
          />
          {children}
          <div className="container">
            <Footer />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    allVendors: getAllVendors(state),
    currentVendor: getCurrentVendor(state),
    isAdmin: getIsAdmin(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    inviteUserFn: (formValues) => dispatch(inviteUser(formValues)),
    loadUsersAndSelectVendor: (vendor) =>
      Promise.all([dispatch(loadUsers(vendor)), dispatch(loadVendorApps(vendor))]).then(() =>
        dispatch(switchVendorAndRedirect(vendor))
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutHome);
