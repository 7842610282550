import { addNotification as notify, POSITIONS } from 'reapop';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';

export const ADMIN_DELETE_APP_REQUEST = 'ADMIN_DELETE_APP_REQUEST';
export const ADMIN_DELETE_APP_SUCCESS = 'ADMIN_DELETE_APP_SUCCESS';
export const ADMIN_DELETE_APP_FAILURE = 'ADMIN_DELETE_APP_FAILURE';

export const deleteAppRequest = () => {
  return {
    type: ADMIN_DELETE_APP_REQUEST
  };
};

export const deleteAppSuccess = (appId) => {
  return {
    type: ADMIN_DELETE_APP_SUCCESS,
    payload: appId
  };
};
export const deleteAppFailure = (error) => {
  return {
    type: ADMIN_DELETE_APP_FAILURE,
    payload: error
  };
};

export const deleteApp = (vendorId, appId) => (dispatch, getState) => {
  dispatch(deleteAppRequest());
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: getToken(getState())
    }
  };
  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendorId}/apps/${appId}`, params)).then(
    (response) => {
      if (response.ok) {
        dispatch(deleteAppSuccess(appId));
        dispatch(
          notify({
            message: `Component has been removed.`,
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      } else {
        return response.json().then((error) => {
          dispatch(deleteAppFailure(error.errorMessage || 'Could not delete component.'));
        });
      }
    }
  );
};
