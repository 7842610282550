import React from 'react';
import InlineNotification from '../../../components/InlineNotification';

export default () => {
  return (
    <div className="static-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <InlineNotification type="success">Successfully logged in.</InlineNotification>
          </div>
        </div>
      </div>
    </div>
  );
};
