import tokens from './tokens';
import profile from './profile';
import mfa from './mfa';
import { combineReducers } from 'redux';

export default combineReducers({
  tokens,
  profile,
  mfa
});
