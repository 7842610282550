import { combineReducers } from 'redux';
import apps from './apps/index';
import developers from './developers';
import vendors from './vendors';

export default combineReducers({
  apps,
  developers,
  vendors
});
