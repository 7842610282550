import React, { Component } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { reduxForm } from 'redux-form';

import PropTypes from 'prop-types';
import InlineNotification from '../../../components/InlineNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FORM_ID = 'HomeDeleteServiceAccountModal';

class DeleteServiceAccountModal extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { serviceAccount, deleteServiceAccountFn, vendor, closeModalFn } = this.props;
    return deleteServiceAccountFn(vendor, serviceAccount.username).then(() => {
      closeModalFn();
    });
  }

  render() {
    const { handleSubmit, serviceAccount, show, closeModalFn, error } = this.props;
    return (
      <Modal show={show} onHide={closeModalFn}>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete service account</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p className="text-danger">
              <FontAwesomeIcon icon={['far', 'trash-alt']} size="3x" />
            </p>
            <p>
              Do you really want to delete the service account{' '}
              <strong>{serviceAccount.username}?</strong> The credentials you have for this account
              will become invalid.
            </p>
            {error && <InlineNotification type="danger">{error}</InlineNotification>}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                type="submit"
                bsSize="large"
                disabled={this.props.submitting}
                bsStyle="danger"
              >
                Delete
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

DeleteServiceAccountModal.propTypes = {
  vendor: PropTypes.string.isRequired,
  serviceAccount: PropTypes.object.isRequired,
  deleteServiceAccountFn: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_ID
})(DeleteServiceAccountModal);
