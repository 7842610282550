import * as tokenActions from './auth/actions/token';
import { getRefreshToken } from './auth/reducers/tokens';
import config from '../../config';
import { networkError, handleNetworkError } from '../utils/networkError';
import { logout } from './auth/actions/logout';

export const updateRequestOptionsTokens = (options, token, accessToken) => {
  let newOptions = {
    ...options
  };

  if (options.headers) {
    newOptions.headers = {
      ...options.headers
    };
    if (options.headers.Authorization) {
      newOptions.headers.Authorization = token;
    }
  }

  if (options.body) {
    let body = JSON.parse(options.body);
    if (body.accessToken) {
      body.accessToken = accessToken;
    }
    newOptions.body = JSON.stringify(body);
  }
  return newOptions;
};

export const fetchProxy = (url, options) => (dispatch, getState) => {
  return fetch(config.API_URL + url, options).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      handleNetworkError(dispatch);
      // this err object can be caught in "getData" in routes.js
      return Promise.reject(networkError);
    }
  );
};

export const fetchProxyWithTokenRefresh = (url, options) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    fetch(config.API_URL + url, options).then(
      (firstResponse) => {
        if (firstResponse.status !== 401) {
          // we only reauthorize when first request's response is 401
          return resolve(firstResponse);
        }
        dispatch(tokenActions.tokenRefreshRequest());

        fetch(config.API_URL + '/auth/token', {
          method: 'GET',
          headers: {
            Authorization: getRefreshToken(getState())
          }
        }).then(
          (response) => {
            response.json().then((data) => {
              if (response.ok) {
                dispatch(tokenActions.tokenRefreshSuccess(data));
                const updatedOptions = updateRequestOptionsTokens(
                  options,
                  data.token,
                  data.accessToken
                );
                // on successful reauthorization, we resolve promise with original request
                return resolve(fetch(config.API_URL + url, updatedOptions));
              } else {
                dispatch(
                  tokenActions.tokenRefreshFailure(
                    data.errorMessage || data.message || 'Could not refresh token'
                  )
                );
                if (response.status === 401) {
                  dispatch(logout());
                }
                return resolve(firstResponse);
              }
            });
          },
          (error) => {
            handleNetworkError(dispatch);
            // this err object can be caught in "getData" in routes.js
            return reject(networkError);
          }
        );
      },
      (error) => {
        handleNetworkError(dispatch);
        // this err object can be caught in "getData" in routes.js
        return reject(networkError);
      }
    );
  });
};
