import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formValueSelector } from 'redux-form';
import { resetPassword, confirmReset } from '../actions/resetPassword';
import ResetPasswordForm, { FORM_ID as ResetPasswordFormId } from '../components/ResetPasswordForm';
import Head from '../../../components/Head';

const propTypes = {
  resetFn: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

class ResetPassword extends Component {
  render() {
    return (
      <>
        <Head title="Reset password" />
        <ResetPasswordForm
          confirmResetFn={this.props.confirmResetFn}
          confirmEmail={this.props.email}
          resetFn={this.props.resetFn}
        />
      </>
    );
  }
}

ResetPassword.propTypes = propTypes;

const formSelector = formValueSelector(ResetPasswordFormId);

const mapStateToProps = (state) => {
  return {
    email: formSelector(state, 'email')
  };
};

export default connect(mapStateToProps, {
  resetFn: resetPassword,
  confirmResetFn: confirmReset
})(ResetPassword);
