import validateJs from '../../../utils/validate';

export const constraints = {
  expiredOn: {
    format: {
      pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}$',
      message: 'must be in format YYYY-MM-DD'
    }
  }
};

export const validate = (app) => {
  let errors = {};
  const result = validateJs.validate(app, constraints);
  if (result) {
    Object.keys(result).forEach((key) => {
      errors[key] = result[key][0];
    });
  }
  return errors;
};
