import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import Link from '../../../components/Link';
import { TextField } from '../../../common/form/fields';
import { validate } from '../models/CreateAccountForm';
import InlineNotification from '../../../components/InlineNotification';
import AccountCreated from './AccountCreated';
import { normalizeEmail } from '../../../common/form/normalizers';

const FORM_ID = 'CreateAccountForm';

class CreateAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(formValues) {
    const { createAccountFn } = this.props;
    return createAccountFn(formValues).then((result) => {
      if (result && result.email) {
        this.setState({
          email: result.email
        });
      }
    });
  }

  render() {
    return this.state.email !== null ? (
      <AccountCreated email={this.state.email} />
    ) : (
      <div className="static-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <Link to="/" className="close">
                <span>×</span>
                <span className="sr-only">Close</span>
              </Link>
              <h4 className="modal-title">Create account</h4>
            </div>
            {this.renderModalBody()}
          </div>
        </div>
      </div>
    );
  }

  renderModalBody() {
    const { handleSubmit, submitting, pristine, error } = this.props;
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="modal-body">
          <Field
            formId={FORM_ID}
            name="name"
            label="Name"
            component={TextField}
            placeholder="Enter your name"
          />
          <Field
            formId={FORM_ID}
            name="email"
            label="Email"
            placeholder="Enter your email"
            component={TextField}
            normalize={normalizeEmail}
          />
          <Field
            formId={FORM_ID}
            label="Password"
            placeholder="Enter your password"
            component={TextField}
            name="password"
            type="password"
          />
          <Field
            formId={FORM_ID}
            placeholder="Enter the same password as above"
            label="Confirm Password"
            component={TextField}
            type="password"
            name="confirmPassword"
          />
          {error && <InlineNotification type="danger">{error}</InlineNotification>}
        </div>
        <div className="modal-footer">
          <button
            className="btn btn-primary btn-lg"
            disabled={pristine || submitting}
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    );
  }
}
export default reduxForm({
  validate: validate,
  form: FORM_ID
})(CreateAccountForm);
