import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import configureStore from '../configureStore';
import App from './App';

const { persistor, store } = configureStore();

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App store={store} />
        </PersistGate>
      </Provider>
    );
  }
}

export default Root;
