import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import SearchInput from '../../../common/form/SearchInput';
import AddDeveloper from './AddDeveloper';
import DeleteUserModal from './DeleteUserModal';

class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteUserModal: false,
      userToDelete: null,
      deletionType: null
    };
    this.openDeleteUserModal = this.openDeleteUserModal.bind(this);
    this.closeDeleteUserModal = this.closeDeleteUserModal.bind(this);
  }

  openDeleteUserModal(user, deletionType) {
    this.setState({
      showDeleteUserModal: true,
      userToDelete: user,
      deletionType: deletionType
    });
  }

  closeDeleteUserModal() {
    this.setState({
      showDeleteUserModal: false,
      userToDelete: null,
      deletionType: null
    });
  }

  render() {
    const { filter, onChangeFilterFn, users, removeUserFn, vendor, email, inviteUserFn } =
      this.props;

    return (
      <Fragment>
        {this.state.userToDelete && (
          <DeleteUserModal
            show={this.state.showDeleteUserModal}
            user={this.state.userToDelete}
            vendor={vendor}
            closeModalFn={this.closeDeleteUserModal}
            deleteUserFn={removeUserFn}
            deletionType={this.state.deletionType}
          />
        )}
        <table className="table table-hover admin-users-list">
          <thead className="search-bar">
            <tr>
              <th>
                <SearchInput
                  value={filter}
                  onChangeFn={onChangeFilterFn}
                  placeholder="Filter by name or email"
                />
              </th>
              <th className="text-right">
                <AddDeveloper inviteUserFn={inviteUserFn} vendor={vendor} />
              </th>
            </tr>
          </thead>
          <thead className="no-top-border">
            <tr>
              <th className="column-width-50">Developer</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, idx) => {
              const leaving = user.username === email;
              return (
                <tr key={idx}>
                  <td>
                    {leaving ? (
                      <h4>
                        {user.name} <span className="label label-info">You</span>
                      </h4>
                    ) : (
                      <h4>{user.name}</h4>
                    )}
                    <span>{user.username}</span>
                  </td>
                  <td>
                    <button
                      className="btn btn-link btn-link-as-href"
                      onClick={() => {
                        this.openDeleteUserModal(user, leaving ? 'leaving' : 'removing');
                      }}
                    >
                      {leaving ? 'Leave' : 'Remove'}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Fragment>
    );
  }
}
UsersTable.propTypes = {
  filter: PropTypes.string.isRequired,
  onChangeFilterFn: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  removeUserFn: PropTypes.func.isRequired,
  vendor: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  inviteUserFn: PropTypes.func.isRequired
};

export default UsersTable;
