import React from 'react';

export default () => {
  return (
    <div className="markdown-help">
      <h5>Markdown syntax help</h5>
      <div className="markdown-samples">
        <p>*Italic*</p>
        <p>**Bold**</p>
        <p>## Heading 2</p>
        <p>### Heading 3</p>
        <p>[Link](http://a.com)</p>
        <p>![Image](http://url/a.png)</p>
        <p>> Blockquote</p>
        <p>
          * List
          <br />
          * List
          <br />* List
        </p>
        <p>
          1. One
          <br />
          2. Two
          <br />
          3. Three
        </p>
        <p>`Inline code` with backticks</p>
        <p>
          ```
          <br />
          # code block
          <br />
          print '3 backticks or'
          <br />
          print 'indent 4 spaces'
          <br />
          ```
        </p>
      </div>
      <p>
        <a href="http://commonmark.org/help/" target="_blank" rel="noopener noreferrer">
          Check original reference
        </a>
      </p>
    </div>
  );
};
