import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AppsTableAppStatus extends React.Component {
  render() {
    const { publishingStatus, showRejectionReasonFn } = this.props;
    if (publishingStatus === 'requested') {
      return (
        <div>
          <strong className="text-warning">Waiting for publishing</strong>
        </div>
      );
    } else if (publishingStatus === 'rejected') {
      return (
        <div className="text-nowrap">
          <strong className="text-danger">Rejected</strong>{' '}
          <button onClick={showRejectionReasonFn} className="btn btn-link btn-link-as-href">
            <FontAwesomeIcon icon={'info-circle'} />
          </button>
        </div>
      );
    }
    return null;
  }
}

AppsTableAppStatus.propTypes = {
  publishingStatus: PropTypes.string.isRequired,
  showRejectionReasonFn: PropTypes.func.isRequired
};

export default AppsTableAppStatus;
