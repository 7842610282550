import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import { reduxForm, Field } from 'redux-form';

import { TextAreaField } from '../../../../common/form/fields';
import { validate } from '../../models/RejectAppForm';
import InlineNotification from '../../../../components/InlineNotification';

const FORM_ID = 'AdminRejectAppForm';

class RejectAppForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { rejectAppFn, closeModalFn, app } = this.props;
    return rejectAppFn(app.id, values).then(() => {
      closeModalFn();
    });
  }

  render() {
    const { app, showModal, closeModalFn, handleSubmit, submitting, error } = this.props;
    return (
      <Modal show={showModal} onHide={closeModalFn}>
        <Modal.Header closeButton>
          <Modal.Title>Reject component publishing</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Body>
            <p>
              You are about to reject a publishing request for the component{' '}
              <strong>{app.name}</strong> (<code>{app.id}</code>).
            </p>
            <p>Please enter the rejection reason below.</p>
            <Field
              formId={FORM_ID}
              name="reason"
              component={TextAreaField}
              label="Rejection reason"
              placeholder="Type the reason of rejection"
            />
            {error && <InlineNotification type="danger">{error}</InlineNotification>}
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary btn-lg" disabled={submitting}>
              Reject
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

RejectAppForm.propTypes = {
  app: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  rejectAppFn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_ID,
  validate: validate
})(RejectAppForm);
