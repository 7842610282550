import React, { Component } from 'react';
import { connect } from 'react-redux';

import { inviteUser, removeUser, setUsersFilter } from '../actions/vendors';
import { getHumanUsersFiltered } from '../reducers/developers/list';
import { getUsersFilter } from '../reducers/developers/filter';
import { getCurrentVendor, getProfile } from '../../auth/reducers/profile';
import UsersTable from '../components/UsersTable';

class Developers extends Component {
  render() {
    return (
      <div className="container">
        <UsersTable
          email={this.props.profile.email}
          filter={this.props.filter}
          onChangeFilterFn={this.props.setUsersFilter}
          vendor={this.props.vendor}
          users={this.props.users}
          removeUserFn={this.props.removeUser}
          inviteUserFn={this.props.inviteUserFn}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const vendor = getCurrentVendor(state);

  return {
    vendor: vendor,
    users: getHumanUsersFiltered(state, vendor),
    filter: getUsersFilter(state),
    profile: getProfile(state)
  };
};

export default connect(mapStateToProps, {
  removeUser,
  inviteUserFn: inviteUser,
  setUsersFilter
})(Developers);
