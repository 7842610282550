import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Link from '../../../components/Link';
import { getApp } from '../reducers/apps/details';
import { updateApp } from '../actions/appEdit';
import { removeNulls } from '../../../utils/helpers';
import { filterNotAllowedFields } from '../models/EditAppForm';
import EditAppForm, { FORM_ID as AdminEditAppFormId } from '../components/apps/EditAppFormCreator';
import EditAppIconForm, {
  FORM_ID as AdminEditAppIconFormId
} from '../../home/components/apps/EditAppIconForm';
import { uploadAppIcon } from '../../home/actions/editAppIcon';
import { routes } from '../../../routesConstants';

class AppEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({
      showModal: true
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
      uploadLink: null
    });
  }

  render() {
    const { app, editAppFormStore, editAppIconFormStore, updateAppSubmitFn, uploadAppIconFn } =
      this.props;
    return (
      <div className="container">
        <Link to={`${routes.ROUTE_ADMIN_COMPONENTS}/${app.id}`}>&larr; Go to component detail</Link>
        <EditAppForm
          module="admin"
          formId={AdminEditAppFormId}
          app={app}
          initialValues={removeNulls(filterNotAllowedFields(app))}
          formStore={editAppFormStore}
          updateAppSubmitFn={updateAppSubmitFn}
          showModal={this.state.showModal}
          editAppIconOpenModalFn={this.openModal}
        />
        <EditAppIconForm
          app={app}
          uploadAppIconFn={uploadAppIconFn}
          formStore={editAppIconFormStore}
          showModal={this.state.showModal}
          editAppIconCloseModalFn={this.closeModal}
        />
      </div>
    );
  }
}

AppEdit.propTypes = {
  app: PropTypes.object.isRequired,
  editAppFormStore: PropTypes.object,
  editAppIconFormStore: PropTypes.object
};

const editAppFormSelector = formValueSelector(AdminEditAppFormId);
const editAppIconFormSelector = formValueSelector(AdminEditAppIconFormId);

const mapStateToProps = (state, options) => {
  const { appId } = options.match.params;
  return {
    app: getApp(state, appId),
    editAppFormStore: {
      repository: editAppFormSelector(state, 'repository'),
      defaultBucket: editAppFormSelector(state, 'defaultBucket'),
      actions: editAppFormSelector(state, 'actions'),
      logger: editAppFormSelector(state, 'logger'),
      longDescription: editAppFormSelector(state, 'longDescription'),
      uiOptions: editAppFormSelector(state, 'uiOptions'),
      configurationDescription: editAppFormSelector(state, 'configurationDescription')
    },
    editAppIconFormStore: {
      file: editAppIconFormSelector(state, 'file')
    }
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAppSubmitFn: (vendorId, appId, values) => {
      // vendorId is skipped, because admin api call doesn't need it
      return dispatch(updateApp(appId, values));
    },
    uploadAppIconFn: (vendorId, appId, values) => {
      return dispatch(uploadAppIcon(vendorId, appId, values));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppEdit);
