export default [
  {
    id: 'keboola.ex-facebook-ads',
    name: 'Facebook Ads',
    shortDescription: 'Advertise with Facebook',
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.ex-facebook-ads/128/1666644041094.png'
    }
  },
  {
    id: 'keboola.ag-geocoding',
    name: 'Geocoding Augmentation',
    shortDescription: 'Geocoding of locations to GPS coordinates or reversely',
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.ag-geocoding/128/1666646174217.png'
    }
  },
  {
    id: 'keboola.ex-google-analytics-v4',
    name: 'Google Analytics',
    shortDescription:
      'Get the data you need to make intelligent marketing and business decisions with Google Analytics.',
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.ex-google-analytics-v4/128/1666644437445.png'
    }
  },
  {
    id: 'keboola.ex-google-drive',
    name: 'Google Drive',
    shortDescription: 'Online document and file storage',
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.ex-google-drive/128/1666644482955.png'
    }
  },
  {
    id: 'keboola.wr-google-sheets',
    name: 'Google Sheets',
    shortDescription: 'Online spreadsheet storage',
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.wr-google-sheets/128/1666644536092.png'
    }
  },
  {
    id: 'keboola.ex-db-mysql',
    name: 'MySQL',
    shortDescription: "World's most popular open source database",
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.ex-db-mysql/128/1666644582092.png'
    }
  },
  {
    id: 'keboola.ex-db-pgsql',
    name: 'PostgreSQL',
    shortDescription: "The world's most advanced open source database.",
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.ex-db-pgsql/128/1666644620792.png'
    }
  },
  {
    id: 'keboola.wr-redshift-v2',
    name: 'Redshift',
    shortDescription: 'Redshift data warehouse',
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.wr-redshift-v2/128/1666647240905.png'
    }
  },
  {
    id: 'keboola.wr-db-snowflake',
    name: 'Snowflake',
    shortDescription: 'Cloud-Native Elastic Data Warehouse Service',
    icon: {
      128: 'https://ui.keboola-assets.com/developer-portal/icons/keboola.wr-db-snowflake/128/1666647435037.png'
    }
  }
];
