import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import InlineNotification from '../../../components/InlineNotification';
import SetupSoftwareMfaModal from './ConfirmSoftwareMfaModal';

const FORM_ID = 'EnableSoftwareMfaForm';

class EnableSoftwareMfaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSetupSoftwareMfaModal: false,
      secretCode: null
    };
    this.openSetupSoftwareMfaModal = this.openSetupSoftwareMfaModal.bind(this);
    this.closeSetupSoftwareMfaModal = this.closeSetupSoftwareMfaModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openSetupSoftwareMfaModal(secretCode) {
    this.setState({
      showSetupSoftwareMfaModal: true,
      secretCode: secretCode
    });
  }

  closeSetupSoftwareMfaModal() {
    this.setState({
      showSetupSoftwareMfaModal: false,
      secretCode: null
    });
  }

  handleSubmit(formValues) {
    const { enableMfaFn } = this.props;
    return enableMfaFn(formValues).then((secretCode) => {
      this.openSetupSoftwareMfaModal(secretCode);
    });
  }

  render() {
    const { handleSubmit, error, submitting, email, confirmMfaFn, buttonText, buttonClass } =
      this.props;
    return (
      <div>
        {this.state.secretCode && (
          <SetupSoftwareMfaModal
            show={this.state.showSetupSoftwareMfaModal}
            closeModalFn={this.closeSetupSoftwareMfaModal}
            confirmSoftwareMfaFn={confirmMfaFn}
            secretCode={this.state.secretCode}
            email={email}
          />
        )}
        <form onSubmit={handleSubmit(this.handleSubmit)} className="form-inline">
          {error && <InlineNotification type="danger">{error}</InlineNotification>}
          <button type="submit" className={buttonClass} disabled={submitting}>
            {buttonText}
          </button>
        </form>
      </div>
    );
  }
}

EnableSoftwareMfaForm.propTypes = {
  enableMfaFn: PropTypes.func.isRequired,
  confirmMfaFn: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClass: PropTypes.string.isRequired
};

export default reduxForm({
  form: FORM_ID
})(EnableSoftwareMfaForm);
