import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SearchInput extends React.Component {
  render() {
    const { placeholder, value, onChangeFn } = this.props;
    return (
      <div className="form-group search-input no-margin">
        <input
          className="form-control"
          placeholder={placeholder}
          autoFocus
          type="text"
          value={value}
          onChange={(e) => onChangeFn(e.target.value)}
          ref={(input) => {
            this.searchInput = input;
          }}
        />
        <span
          className="search-icon"
          onClick={() => {
            this.searchInput.focus();
          }}
        >
          <FontAwesomeIcon icon={'search'} />
        </span>
        <button
          className="btn btn-link clear-icon"
          onClick={() => {
            onChangeFn('');
            this.searchInput.focus();
          }}
        >
          ×
        </button>
      </div>
    );
  }
}
SearchInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChangeFn: PropTypes.func.isRequired
};

export default SearchInput;
