import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from '../../../../components/ConfirmModal';
import InlineNotification from '../../../../components/InlineNotification';

const RemoveAppConfirmationModal = ({ error, app, deleteAppFn, isDeleting }) => (
  <ConfirmModal
    isConfirming={isDeleting}
    closeOnOkResolve={true}
    onConfirm={() => deleteAppFn(app.vendor.id, app.id)}
    title={`Remove component`}
    text={
      <div>
        {error && <InlineNotification type="danger">{error}</InlineNotification>}
        <p>
          You are about to remove <strong>{app.name}</strong> (<code>{app.id}</code>
          ).
        </p>
      </div>
    }
    buttonLabel="Remove"
  >
    <button className="btn btn-link btn-link-as-href">Remove</button>
  </ConfirmModal>
);
RemoveAppConfirmationModal.propTypes = {
  app: PropTypes.object.isRequired,
  error: PropTypes.string,
  deleteAppFn: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired
};

export { RemoveAppConfirmationModal };
