import selectVendor from './selectVendor';
import apps from './apps/index';
import serviceAccounts from './serviceAccounts/index';
import developers from './developers/index';
import { combineReducers } from 'redux';

export default combineReducers({
  selectVendor,
  apps,
  serviceAccounts,
  developers
});
