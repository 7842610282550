import { combineReducers } from 'redux';
import list from './list';
import details from './details';
import filter from './filter';
import deleteApp from './deleteApp';

export default combineReducers({
  list,
  filter,
  details,
  deleteApp
});
