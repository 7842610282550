import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

class Head extends React.Component {
  render() {
    return (
      <Helmet>
        <title>
          {this.props.title
            ? `${this.props.title} | Keboola Developer Portal`
            : 'Keboola Developer Portal'}
        </title>
        {this.props.metaDescription && (
          <meta name="description" content={this.props.metaDescription} />
        )}
      </Helmet>
    );
  }
}

Head.propTypes = {
  title: PropTypes.string,
  metaDescription: PropTypes.string
};

export default Head;
