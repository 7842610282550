import React from 'react';

export default () => {
  return (
    <span className="app-logo">
      <img src={process.env.PUBLIC_URL + '/images/keboola.svg'} alt="Keboola logo" />
      <strong>Developer Portal</strong>
    </span>
  );
};
