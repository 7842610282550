import React from 'react';
import qs from 'qs';
import makeRouteConfig from 'found/makeRouteConfig';
import Route from 'found/Route';
import RedirectException from 'found/RedirectException';
import HttpError from 'found/HttpError';
import isIe from '@braintree/browser-detection/is-ie';

import { routes, routeParts } from './routesConstants';
import config from '../config';

// auth actions
import { getIsAuthenticated } from './modules/auth/reducers/tokens';
import { getCurrentVendor, getIsAdmin } from './modules/auth/reducers/profile';

import { fetchProfileLazy } from './modules/auth/actions/profile';

// open actions
import { fetchVendors } from './modules/open/actions/vendors';
import {
  fetchApps as openFetchApps,
  fetchAppDetail as openFetchAppDetail
} from './modules/open/actions/apps';

// home actions
import { fetchAppDetail, loadVendorApps } from './modules/home/actions/apps';
import { loadUsers } from './modules/home/actions/vendors';
import { fetchServiceAccounts } from './modules/home/actions/serviceAccounts';
import { getServiceAccounts } from './modules/home/reducers/serviceAccounts/list';

// admin actions
import { fetchAllUsers } from './modules/admin/actions/developers';
import { fetchVendors as adminFetchVendors } from './modules/admin/actions/vendors';
import { fetchApps } from './modules/admin/actions/apps';
import { getAppDetail } from './modules/admin/actions/appDetail';
import { getAppChanges } from './modules/admin/actions/appChanges';

// layout containers
import LayoutHome from './layout/LayoutHome';
import LayoutAdmin from './layout/LayoutAdmin';
import LayoutOpen from './layout/LayoutOpen';
import LayoutOpenSimple from './layout/LayoutOpenSimple';
import LayoutTopLevel from './layout/LayoutTopLevel';

// home containers
import HomeApps from './modules/home/containers/Apps';
import HomeAppDetail from './modules/home/containers/AppDetail';
import HomeAppEdit from './modules/home/containers/AppEdit';
import HomeProfile from './modules/home/containers/Profile';
import HomeDevelopers from './modules/home/containers/Developers';
import HomeAppCreate from './modules/home/containers/AppCreate';
import HomeServiceAccounts from './modules/home/containers/ServiceAccounts';

// admin containers
import AdminApps from './modules/admin/containers/Apps';
import AdminAppDetail from './modules/admin/containers/AppDetail';
import AdminAppChanges from './modules/admin/containers/AppChanges';
import AdminAppEdit from './modules/admin/containers/AppEdit';
import AdminDevelopers from './modules/admin/containers/Developers';
import AdminVendors from './modules/admin/containers/Vendors';
import AdminProfile from './modules/admin/containers/Profile';

// auth containers
import AuthLogin from './modules/auth/containers/Login';
import AuthResetPassword from './modules/auth/containers/ResetPassword';
import AuthCreateAccount from './modules/auth/containers/CreateAccount';

// open containers
import OpenIndex from './modules/open/containers/Index';
import OpenResources from './modules/open/containers/Resources';
import OpenApps from './modules/open/containers/Apps';
import OpenAppDetail from './modules/open/containers/AppDetail';

import NotSupportedBrowser from './components/NotSupportedBrowser';

const resolveWithHttpError = (promise) => {
  return promise.then(
    (status) => status,
    (error) => {
      throw new HttpError(error.status, error.message);
    }
  );
};

const foundRoutes = isIe()
  ? makeRouteConfig(<Route path={config.BASE_PATH + '/*'} Component={NotSupportedBrowser} />)
  : makeRouteConfig(
      <Route path={config.BASE_PATH} Component={LayoutTopLevel}>
        <Route Component={LayoutOpen}>
          <Route Component={OpenIndex} path="/" />
          <Route
            Component={OpenApps}
            path={routes.ROUTE_OPEN_COMPONENTS}
            getData={({
              context: {
                store: { dispatch }
              }
            }) => {
              return resolveWithHttpError(dispatch(openFetchApps()));
            }}
          />
          <Route
            Component={OpenAppDetail}
            path={`${routes.ROUTE_OPEN_COMPONENTS}/:appId`}
            getData={({
              context: {
                store: { dispatch }
              },
              params: { appId }
            }) => {
              return resolveWithHttpError(dispatch(openFetchAppDetail(appId)));
            }}
            scrollToTop
          />
          <Route Component={OpenResources} path={routes.ROUTE_OPEN_RESOURCES} />
        </Route>

        <Route Component={LayoutOpenSimple}>
          <Route Component={AuthLogin} path={routes.ROUTE_AUTH_LOGIN} />
          <Route Component={AuthResetPassword} path={routes.ROUTE_AUTH_RESET_PASSWORD} />
          <Route Component={AuthCreateAccount} path={routes.ROUTE_AUTH_CREATE_ACCOUNT} />
        </Route>

        <Route
          Component={({ children }) => <div>{children}</div>}
          render={({
            Component,
            props,
            match: {
              context: {
                store: { getState }
              }
            }
          }) => {
            if (!getIsAuthenticated(getState())) {
              throw new RedirectException(config.BASE_PATH + routes.ROUTE_AUTH_LOGIN);
            } else {
              return <Component {...props} />;
            }
          }}
        >
          <Route
            Component={LayoutHome}
            path={routes.ROUTE_HOME}
            getData={({
              context: {
                store: { dispatch, getState }
              }
            }) => {
              if (!getIsAuthenticated(getState())) {
                return resolveWithHttpError(dispatch(fetchVendors()));
              } else {
                return resolveWithHttpError(
                  Promise.all([dispatch(fetchVendors()), dispatch(fetchProfileLazy())])
                );
              }
            }}
          >
            <Route
              Component={HomeApps}
              getData={({
                context: {
                  store: { getState, dispatch }
                }
              }) => {
                const vendor = getCurrentVendor(getState());
                if (vendor === null) {
                  return Promise.resolve('ok');
                }
                return resolveWithHttpError(dispatch(loadVendorApps(vendor)));
              }}
            />
            <Route Component={HomeAppCreate} path={routeParts.COMPONENTS + routeParts.NEW} />
            <Route
              Component={HomeAppDetail}
              path={`${routeParts.COMPONENTS}/:appId`}
              getData={({
                params: { appId },
                context: {
                  store: { dispatch, getState }
                }
              }) => {
                const vendorId = getCurrentVendor(getState());
                return resolveWithHttpError(dispatch(fetchAppDetail(vendorId, appId)));
              }}
              scrollToTop
            />
            <Route
              Component={HomeAppEdit}
              path={`${routeParts.COMPONENTS}/:appId${routeParts.EDIT}`}
              getData={({
                params: { appId },
                context: {
                  store: { dispatch, getState }
                }
              }) => {
                const vendorId = getCurrentVendor(getState());
                return resolveWithHttpError(dispatch(fetchAppDetail(vendorId, appId)));
              }}
              scrollToTop
            />
            <Route
              Component={HomeProfile}
              path={routeParts.PROFILE}
              getData={({
                context: {
                  store: { dispatch }
                }
              }) => {
                return resolveWithHttpError(dispatch(fetchProfileLazy()));
              }}
            />
            <Route
              Component={HomeDevelopers}
              getData={({
                context: {
                  store: { getState, dispatch }
                }
              }) => {
                const vendorId = getCurrentVendor(getState());
                return resolveWithHttpError(dispatch(loadUsers(vendorId)));
              }}
              path={routeParts.DEVELOPERS}
            />
            <Route
              Component={HomeServiceAccounts}
              path={routeParts.SERVICE_ACCOUNTS}
              getData={({
                context: {
                  store: { dispatch, getState }
                }
              }) => {
                const vendorId = getCurrentVendor(getState());
                if (getServiceAccounts(getState(), vendorId).length !== 0) {
                  return Promise.resolve('ok');
                }
                return resolveWithHttpError(dispatch(fetchServiceAccounts(vendorId)));
              }}
            />
          </Route>
        </Route>
        <Route
          Component={({ children }) => <div>{children}</div>}
          render={({
            Component,
            props,
            match: {
              context: {
                store: { getState }
              }
            }
          }) => {
            if (!getIsAuthenticated(getState())) {
              throw new RedirectException(config.BASE_PATH + routes.ROUTE_AUTH_LOGIN);
            } else if (!getIsAdmin(getState())) {
              throw new HttpError(403, "You don't have permission to access this resource.");
            } else {
              return <Component {...props} />;
            }
          }}
        >
          <Route Component={LayoutAdmin} path={routes.ROUTE_ADMIN}>
            <Route
              Component={AdminApps}
              path="/"
              getData={({
                context: {
                  store: { dispatch }
                }
              }) => {
                return resolveWithHttpError(dispatch(fetchApps()));
              }}
            />
            <Route
              Component={AdminAppDetail}
              path={`${routeParts.COMPONENTS}/:appId`}
              getData={({
                params: { appId },
                context: {
                  store: { dispatch }
                }
              }) => {
                return resolveWithHttpError(dispatch(getAppDetail(appId)));
              }}
              scrollToTop
            />
            <Route
              Component={AdminAppEdit}
              path={`${routeParts.COMPONENTS}/:appId${routeParts.EDIT}`}
              getData={({
                params: { appId },
                context: {
                  store: { dispatch }
                }
              }) => {
                return resolveWithHttpError(dispatch(getAppDetail(appId)));
              }}
              scrollToTop
            />
            <Route
              Component={AdminAppChanges}
              path={`${routeParts.COMPONENTS}/:appId${routeParts.CHANGES}`}
              getData={({
                params: { appId },
                context: {
                  store: { dispatch }
                }
              }) => {
                return resolveWithHttpError(
                  Promise.all([dispatch(getAppChanges(appId)), dispatch(getAppDetail(appId))])
                );
              }}
              scrollToTop
            />
            <Route
              Component={AdminDevelopers}
              getData={({
                context: {
                  store: { dispatch }
                },
                location: { search }
              }) => {
                search = search[0] === '?' ? search.slice(1) : search;
                const filter = qs.parse(search).filter;
                return resolveWithHttpError(
                  Promise.all([dispatch(fetchAllUsers(filter)), dispatch(adminFetchVendors())])
                );
              }}
              path={routeParts.DEVELOPERS}
            />
            <Route
              Component={AdminVendors}
              path={routeParts.VENDORS}
              getData={({
                context: {
                  store: { dispatch }
                }
              }) => {
                return resolveWithHttpError(dispatch(adminFetchVendors()));
              }}
            />
            <Route
              Component={AdminProfile}
              path={routeParts.PROFILE}
              getData={({
                context: {
                  store: { dispatch }
                }
              }) => {
                return resolveWithHttpError(
                  Promise.all([dispatch(fetchProfileLazy()), dispatch(adminFetchVendors())])
                );
              }}
            />
          </Route>
        </Route>
      </Route>
    );

export { foundRoutes };
