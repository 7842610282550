import * as detailActions from '../../actions/appChanges';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case detailActions.ADMIN_GET_APP_CHANGES_SUCCESS:
      return {
        ...state,
        [action.payload.appId]: action.payload.changes
      };
    default:
      return state;
  }
};

export const getAppChanges = (state, appId) => {
  return state.admin.apps.changes[appId];
};
