import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { TextField, TextAreaField } from '../../../../common/form/fields';
import { validate } from '../../models/CreateVendorForm';
import InlineNotification from '../../../../components/InlineNotification';
import { normalizeEmail } from '../../../../common/form/normalizers';

const FORM_ID = 'CreateVendorForm';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    return this.props.createVendorFn(values);
  }

  render() {
    const { handleSubmit, error, pristine, submitting, switchToSelectVendorFn } = this.props;
    return (
      <div className="static-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Create new vendor</h4>
            </div>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <div className="modal-body">
                <Field
                  formId={FORM_ID}
                  name="name"
                  component={TextField}
                  label="Name"
                  placeholder="Enter your or your company's name"
                />
                <Field
                  formId={FORM_ID}
                  name="email"
                  component={TextField}
                  label="Email"
                  placeholder="Enter your contact email"
                  normalize={normalizeEmail}
                />
                <Field
                  formId={FORM_ID}
                  name="address"
                  component={TextAreaField}
                  label="Address"
                  placeholder="Enter your address"
                  rows="4"
                />

                {error && <InlineNotification type="danger">{error}</InlineNotification>}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => switchToSelectVendorFn()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={pristine || submitting}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

LoginForm.propTypes = {
  createVendorFn: PropTypes.func.isRequired,
  switchToSelectVendorFn: PropTypes.func.isRequired
};

export default reduxForm({
  validate: validate,
  form: FORM_ID
})(LoginForm);
