import React from 'react';

import Link from '../components/Link';
import { routes } from '../routesConstants';
import ContactModal from '../components/ContactModal';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date()
    };
  }

  render() {
    return (
      <div className="page-footer">
        <div className="reverse-links">
          <span>
            © {this.state.currentDate.getFullYear()} {'Keboola :(){:|:&};: s.r.o.'}
          </span>
          <span>
            <Link to={routes.ROUTE_OPEN_COMPONENTS}>Components</Link>
          </span>
          <span>
            <Link to={routes.ROUTE_OPEN_RESOURCES}>Resources</Link>
          </span>
          <span>
            <ContactModal label="Contact" />
          </span>
        </div>
      </div>
    );
  }
}
