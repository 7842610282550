import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import {
  getManageUserVendors,
  getFilter,
  getAllUsersFiltered,
  getVendorFilter
} from '../reducers/developers';
import {
  setUserVendor,
  setUsersFilter,
  setUsersVendorFilter,
  manageUserVendors
} from '../actions/developers';
import { inviteUser } from '../../home/actions/vendors';
import { disableMfa } from '../actions/disableMfa';
import { makeUserAdmin } from '../actions/makeUserAdmin';
import { getProfile } from '../../auth/reducers/profile';
import UsersTable from '../components/UsersTable';
import { FORM_ID as ManageUserVendorsFormId } from '../components/ManageUserVendorsModal';
import { getVendors } from '../reducers/vendors';

class Developers extends Component {
  render() {
    return (
      <div className="container">
        <UsersTable
          inviteUserFn={this.props.inviteUser}
          toggleShowModal={this.props.manageUserVendors}
          isLeaving={(username) => this.props.profile.email === username}
          onSelectVendor={this.props.setUsersVendorFilter}
          vendorFilter={this.props.vendorFilter}
          vendors={this.props.vendors}
          onChangeFilter={this.props.setUsersFilter}
          filter={this.props.filter}
          makeUserAdminFn={this.props.makeUserAdminFn}
          users={this.props.users}
          profile={this.props.profile}
          manageVendorsState={this.props.manageVendorsState}
          manageVendorsFormStore={this.props.manageVendorsFormStore}
          disableMfaFn={this.props.disableMfaFn}
          setUserVendor={this.props.setUserVendor}
        />
      </div>
    );
  }
}

const manageUserVendorsModalSelector = formValueSelector(ManageUserVendorsFormId);

const mapStateToProps = (state) => {
  return {
    users: getAllUsersFiltered(state),
    profile: getProfile(state),
    filter: getFilter(state),
    vendors: getVendors(state),
    vendorFilter: getVendorFilter(state),
    manageVendorsFormStore: manageUserVendorsModalSelector(state, 'action', 'vendor'),
    manageVendorsState: getManageUserVendors(state)
  };
};

export default connect(mapStateToProps, {
  manageUserVendors,
  setUsersFilter,
  setUsersVendorFilter,
  inviteUser,
  disableMfaFn: disableMfa,
  makeUserAdminFn: makeUserAdmin,
  setUserVendor
})(Developers);
