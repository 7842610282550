import React, { Component } from 'react';
import { untouch, change, Field, reduxForm } from 'redux-form';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { TextField, TextAreaField } from '../../../common/form/fields';
import { validate } from '../models/EditVendorForm';
import InlineNotification from '../../../components/InlineNotification';
import { normalizeEmail } from '../../../common/form/normalizers';

const FORM_ID = 'editvendormodal';

const normalizeVendorId = (vendorName) => {
  return (vendorName || '').replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase();
};

class EditVendorModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModalIfOk = this.closeModalIfOk.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.show === true && prevProps.show === false) {
      this.props.dispatch(change(FORM_ID, 'email', this.props.vendor.email));
      this.props.dispatch(change(FORM_ID, 'name', this.props.vendor.name));
      this.props.dispatch(change(FORM_ID, 'address', this.props.vendor.address));
      this.props.dispatch(change(FORM_ID, 'projectUrl', this.props.vendor.projectUrl));
      this.props.dispatch(change(FORM_ID, 'id', this.props.vendor.id));
      this.props.dispatch(untouch(FORM_ID, 'email'));
      this.props.dispatch(untouch(FORM_ID, 'name'));
      this.props.dispatch(untouch(FORM_ID, 'address'));
      this.props.dispatch(untouch(FORM_ID, 'projectUrl'));
    }
  }

  closeModal() {
    this.props.onHide();
    this.props.reset();
  }

  closeModalIfOk(result) {
    if (result === 'ok') {
      this.closeModal();
    }
  }

  isCreating() {
    return !this.props.vendor.id;
  }

  renderFormBody() {
    return (
      <span>
        <Field
          formId={FORM_ID}
          name="name"
          component={TextField}
          placeholder="Enter vendor name"
          label="Name"
          onChange={(event, newValue) => {
            this.props.dispatch(change(FORM_ID, 'id', normalizeVendorId(newValue)));
          }}
        />
        {!this.props.vendor.id && (
          <Field
            formId={FORM_ID}
            name="id"
            component={TextField}
            placeholder="Enter vendor ID"
            label="ID"
          />
        )}
        <Field
          formId={FORM_ID}
          name="address"
          component={TextAreaField}
          placeholder="Enter vendor address"
          label="Address"
          rows="4"
        />
        <Field
          formId={FORM_ID}
          name="email"
          component={TextField}
          placeholder="Enter vendor email"
          label="Email"
          normalize={normalizeEmail}
        />
        <Field
          formId={FORM_ID}
          name="projectUrl"
          component={TextField}
          placeholder="Keboola Connection project URL"
          label="Test project URL"
        />
      </span>
    );
  }

  handleSubmit(values) {
    let valuesToSubmit = { ...values };
    if (!this.isCreating()) delete valuesToSubmit.id;

    return this.props
      .onUpdateVendor(this.props.vendor.id, valuesToSubmit)
      .then(this.closeModalIfOk);
  }

  render() {
    if (!this.props.show) return null;
    return (
      <div className="modal-container">
        <Modal show={this.props.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.isCreating() ? 'Add vendor' : 'Edit vendor'}</Modal.Title>
          </Modal.Header>
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <Modal.Body>
              {this.renderErrorMessage()}
              <div>{this.renderFormBody()}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                disabled={this.props.pristine || this.props.submitting}
                bsSize="large"
                bsStyle="primary"
              >
                {this.isCreating() ? 'Add' : 'Save'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }

  renderErrorMessage() {
    if (this.props.error) {
      return <InlineNotification type="danger">{this.props.error}</InlineNotification>;
    }
  }
}

export default reduxForm({ form: FORM_ID, validate })(EditVendorModal);
