import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../components/Link';
import { routes, routeParts } from '../../../../routesConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AppAdded = ({ module, appId }) => {
  const linkToApps = module === 'admin' ? routes.ROUTE_ADMIN : routes.ROUTE_HOME;
  const linkToEdit =
    module === 'admin'
      ? `${routes.ROUTE_ADMIN_COMPONENTS}/${appId}${routeParts.EDIT}`
      : `${routes.ROUTE_HOME_COMPONENTS}/${appId}${routeParts.EDIT}`;
  return (
    <div className="text-center margin-vertical-4em">
      <h1>Component has been added</h1>
      <p className="text-success">
        <FontAwesomeIcon icon={'check'} size="3x" />
      </p>
      <div className="margin-vertical-3em">
        <p className="font-size-1-5em">
          Your component has been added but it's not working yet - it's private and requires further
          modifications. Before becoming public, the component must be reviewed by a Keboola
          administrator.
        </p>
      </div>
      <div className="margin-vertical-2em">
        <Link to={linkToApps} className="btn btn-lg btn-primary">
          Go to components listing
        </Link>
        <span className="span-side-paddings">{' or '}</span>
        <Link to={linkToEdit} className="btn btn-lg btn-primary">
          Continue to update this component
        </Link>
      </div>
    </div>
  );
};
AppAdded.propTypes = {
  module: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired
};

export default AppAdded;
