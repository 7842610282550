import React, { Component } from 'react';
import Link from '../../../components/Link';
import { routes } from '../../../routesConstants';
import { TextField } from '../../../common/form/fields';
import { Field, reduxForm } from 'redux-form';
import { validate } from '../models/ResetPasswordForm';
import InlineNotification from '../../../components/InlineNotification';
import { normalizeEmail } from '../../../common/form/normalizers';

export const FORM_ID = 'reset-password-dialog';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetSent: false,
      resetSuccess: false,
      successMessage: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(formValues) {
    this.setState({ successMessage: null });
    if (this.state.resetSent) {
      return this.handleCodeSubmit(formValues);
    } else {
      return this.handleEmailSubmit(formValues);
    }
  }

  handleCodeSubmit(formValues) {
    return this.props
      .confirmResetFn(formValues.email, formValues.code, formValues.password)
      .then((result) => {
        if (result === 'ok') {
          this.setState({
            resetSuccess: true,
            successMessage: 'Your password has been reset successfully.'
          });
        }
      });
  }

  handleEmailSubmit(formValues) {
    return this.props.resetFn(formValues.email).then((action) => {
      if (action && action.email) {
        this.setState({
          resetSent: true,
          successMessage:
            'A code has been sent to your email address or phone number. Paste the code below.'
        });
      }
    });
  }

  render() {
    return (
      <div className="static-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <Link to="/" className="close">
                <span>×</span>
                <span className="sr-only">Close</span>
              </Link>
              <h4 className="modal-title">
                {this.state.resetSent ? 'Set new password' : 'Reset your password'}
              </h4>
            </div>
            {this.state.resetSuccess ? this.renderSuccess() : this.renderSetupForm()}
          </div>
        </div>
      </div>
    );
  }

  renderSuccess() {
    return (
      <span>
        <div className="modal-body">{this.renderSuccessMessage()}</div>
        <div className="modal-footer">
          <Link to={routes.ROUTE_AUTH_LOGIN}>Sign in</Link>
        </div>
      </span>
    );
  }

  renderSetupForm() {
    return (
      <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
        <div className="modal-body">
          {this.renderSuccessMessage()}
          {this.state.resetSent ? this.renderConfirmFormGroup() : this.renderEmailFormGroup()}
          {this.renderErrorMessage()}
        </div>
        <div className="modal-footer">
          <button
            disabled={this.props.pristine || this.props.submitting}
            type="submit"
            className="btn btn-primary btn-lg"
          >
            Submit
          </button>
        </div>
      </form>
    );
  }

  renderConfirmFormGroup() {
    return (
      <span>
        <Field
          formId={FORM_ID}
          placeholder="Paste the code from the email"
          name="code"
          type="text"
          component={TextField}
          label="Confirmation code"
        />
        <Field
          formId={FORM_ID}
          component={TextField}
          name="password"
          type="password"
          label="New password"
          placeholder="Enter a new password"
        />
        <Field
          formId={FORM_ID}
          component={TextField}
          name="password2"
          type="password"
          label="Confirm password"
          placeholder="Confirm the new password"
        />
      </span>
    );
  }

  renderEmailFormGroup() {
    return (
      <Field
        formId={FORM_ID}
        component={TextField}
        name="email"
        placeholder="Enter your email address"
        type="text"
        label="Email"
        normalize={normalizeEmail}
      />
    );
  }

  renderSuccessMessage() {
    if (this.state.successMessage) {
      return <InlineNotification type="success">{this.state.successMessage}</InlineNotification>;
    }
  }

  renderErrorMessage() {
    if (this.props.error) {
      return <InlineNotification type="danger">{this.props.error}</InlineNotification>;
    }
  }
}

// ResetPasswordDialog.propTypes = propTypes;

export default reduxForm({ form: FORM_ID, validate })(ResetPasswordForm);
