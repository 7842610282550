import { SubmissionError } from 'redux-form';

import { getToken } from '../../auth/reducers/tokens';
import { getCurrentVendor } from '../../auth/reducers/profile';
import { fetchProxyWithTokenRefresh } from '../../actions';
import { loadVendorApps } from './apps';
import { setDefaultValuesOnCreation } from '../models/App';

export const HOME_CREATE_APP_REQUEST = 'HOME_CREATE_APP_REQUEST';
export const HOME_CREATE_APP_SUCCESS = 'HOME_CREATE_APP_SUCCESS';
export const HOME_CREATE_APP_FAILURE = 'HOME_CREATE_APP_FAILURE';

export const createAppRequest = () => ({ type: HOME_CREATE_APP_REQUEST });
export const createAppSuccess = (vendor, newApp) => ({
  type: HOME_CREATE_APP_SUCCESS,
  vendor,
  newApp
});
export const createAppFailure = () => ({ type: HOME_CREATE_APP_FAILURE });

export const createAppSubmit = (app) => (dispatch, getState) => {
  const vendorId = getCurrentVendor(getState());
  const params = {
    method: 'POST',
    body: JSON.stringify(setDefaultValuesOnCreation(app)),
    headers: {
      Authorization: getToken(getState())
    }
  };
  dispatch(createAppRequest());

  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendorId}/apps`, params)).then(
    (response) => {
      if (response.ok) {
        return dispatch(loadVendorApps(vendorId, true)).then(() => {
          dispatch(createAppSuccess(vendorId, app));
          return vendorId + '.' + app.id;
        });
      } else {
        dispatch(createAppFailure());
        return response.json().then((error) => {
          let errorMessage;
          if (error.errorMessage && error.errorMessage === 'Already exists') {
            errorMessage = 'Component with specified ID already exists.';
          } else {
            errorMessage = error.message || error.errorMessage || 'Error while creating component.';
          }
          throw new SubmissionError({
            _error: errorMessage
          });
        });
      }
    }
  );
};
