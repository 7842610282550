import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchInput from '../../../common/form/SearchInput';
import AddServiceAccountModal from '../components/AddServiceAccountModal';
import DeleteServiceAccountModal from './DeleteServiceAccountModal';

class ServiceAccountsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddServiceAccountModal: false,
      showDeleteServiceAccountModal: false,
      serviceAccountToDelete: null
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openDeleteServiceAccountModal = this.openDeleteServiceAccountModal.bind(this);
    this.closeDeleteServiceAccountModal = this.closeDeleteServiceAccountModal.bind(this);
  }

  openModal() {
    this.setState({
      showAddServiceAccountModal: true
    });
  }

  closeModal() {
    this.setState({
      showAddServiceAccountModal: false
    });
  }

  openDeleteServiceAccountModal(serviceAccount) {
    this.setState({
      showDeleteServiceAccountModal: true,
      serviceAccountToDelete: serviceAccount
    });
  }

  closeDeleteServiceAccountModal() {
    this.setState({
      showDeleteServiceAccountModal: false,
      serviceAccountToDelete: null
    });
  }

  render() {
    const {
      serviceAccounts,
      serviceAccountsFiltered,
      removeUser,
      vendor,
      generateServiceUserFn,
      filter,
      onChangeFilterFn
    } = this.props;

    return (
      <div>
        {this.state.serviceAccountToDelete && (
          <DeleteServiceAccountModal
            show={this.state.showDeleteServiceAccountModal}
            serviceAccount={this.state.serviceAccountToDelete}
            vendor={vendor}
            closeModalFn={this.closeDeleteServiceAccountModal}
            deleteServiceAccountFn={removeUser}
          />
        )}
        <AddServiceAccountModal
          generateAccountFn={generateServiceUserFn}
          vendor={vendor}
          show={this.state.showAddServiceAccountModal}
          closeModalFn={this.closeModal}
        />
        {serviceAccounts.length !== 0 ? (
          <table className="table table-hover admin-users-list">
            <thead className="search-bar">
              <tr>
                <th>
                  <SearchInput
                    value={filter}
                    onChangeFn={onChangeFilterFn}
                    placeholder="Filter by account name"
                  />
                </th>
                <th />
                <th className="text-right">
                  <button className="btn btn-primary" onClick={this.openModal}>
                    Create a service account
                  </button>
                </th>
              </tr>
            </thead>
            <thead className="no-top-border">
              <tr>
                <th className="column-width-50">Account name</th>
                <th className="column-width-30">Description</th>
                <th>Manage</th>
              </tr>
            </thead>
            <tbody>
              {serviceAccountsFiltered.map((user, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <h4>{user.username}</h4>
                    </td>
                    <td>{user.description}</td>
                    <td>
                      <button
                        className="btn btn-link btn-link-as-href"
                        onClick={() => {
                          this.openDeleteServiceAccountModal(user);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="text-center margin-vertical-7em">
            <p>
              <FontAwesomeIcon icon={'terminal'} size="3x" />
            </p>
            <h1>Create a service account</h1>
            <p>Service accounts are used for programmatic access to Developer Portal.</p>
            <div className="margin-vertical-3em">
              <button className="btn btn-primary" onClick={this.openModal}>
                Create a service account
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ServiceAccountsTable.propTypes = {
  serviceAccounts: PropTypes.array.isRequired,
  serviceAccountsFiltered: PropTypes.array.isRequired,
  removeUser: PropTypes.func.isRequired,
  vendor: PropTypes.string.isRequired,
  generateServiceUserFn: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  onChangeFilterFn: PropTypes.func.isRequired
};

export default ServiceAccountsTable;
