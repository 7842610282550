import React from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SelectField from '../../../common/form/SelectField';
import SearchInput from '../../../common/form/SearchInput';
import { findNameById } from '../../../utils/helpers';
import { KEBOOLA_VENDOR_ID } from '../../../constants';
import AddDeveloper from '../../home/components/AddDeveloper';
import { PENDING_VENDORS_OPTION } from '../reducers/developers';
import ManageUserVendorsModal from './ManageUserVendorsModal';
import DisableMfaModal from './DisableMfaModal';
import MakeUserAdminModal from './MakeUserAdminModal';

const Vendors = ({ vendors, allVendors, onShowManageModal }) => (
  <div>
    <ul className="list-unstyled">
      {vendors.map((v, idx) => (
        <li key={idx} className={v.indexOf('_v') === 0 ? 'break-word' : ''}>
          {findNameById(v, allVendors)}
        </li>
      ))}
    </ul>
    <span onClick={onShowManageModal} className="btn btn-link">
      <FontAwesomeIcon icon={'pencil-alt'} />
    </span>
  </div>
);

const optionsForVendorFilter = (vendors) => {
  const options = [].concat(vendors);
  const allVendors = [
    { value: '', label: 'All Vendors' },
    { value: PENDING_VENDORS_OPTION, label: 'All Pending Vendors' }
  ];
  return allVendors.concat(options.map((v) => ({ label: v.name, value: v.id })));
};

class UsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisableMfaModal: false,
      showMakeUserAdminModal: false,
      userToDisableMfa: null,
      userToMakeAdmin: null
    };
    this.openResetMfaModal = this.openResetMfaModal.bind(this);
    this.closeResetMfaModal = this.closeResetMfaModal.bind(this);
    this.openMakeUserAdminModal = this.openMakeUserAdminModal.bind(this);
    this.closeMakeUserAdminModal = this.closeMakeUserAdminModal.bind(this);
  }

  openResetMfaModal(user) {
    this.setState({
      showDisableMfaModal: true,
      userToDisableMfa: user
    });
  }

  closeResetMfaModal() {
    this.setState({
      showDisableMfaModal: false,
      userToDisableMfa: null
    });
  }

  openMakeUserAdminModal(user) {
    this.setState({
      showMakeUserAdminModal: true,
      userToMakeAdmin: user
    });
  }

  closeMakeUserAdminModal() {
    this.setState({
      showMakeUserAdminModal: false,
      userToMakeAdmin: null
    });
  }

  render() {
    const {
      users,
      isLeaving,
      vendors,
      manageVendorsFormStore,
      manageVendorsState,
      toggleShowModal,
      setUserVendor,
      onSelectVendor,
      vendorFilter,
      onChangeFilter,
      inviteUserFn,
      filter,
      disableMfaFn,
      makeUserAdminFn
    } = this.props;

    return (
      <div>
        <ManageUserVendorsModal
          onConfirm={setUserVendor}
          show={manageVendorsState.show}
          onHide={() => toggleShowModal(false, {})}
          formStore={manageVendorsFormStore}
          allVendors={vendors}
          user={manageVendorsState.user}
        />

        <DisableMfaModal
          show={this.state.showDisableMfaModal}
          user={this.state.userToDisableMfa}
          closeModalFn={this.closeResetMfaModal}
          disableMfaFn={disableMfaFn}
        />

        <MakeUserAdminModal
          show={this.state.showMakeUserAdminModal}
          user={this.state.userToMakeAdmin}
          closeModalFn={this.closeMakeUserAdminModal}
          makeUserAdminFn={makeUserAdminFn}
        />

        <table className="table table-hover admin-users-list">
          <thead className="search-bar">
            <tr>
              <th>
                <SearchInput
                  value={filter}
                  onChangeFn={onChangeFilter}
                  placeholder="Search by developer name or email"
                />
              </th>
              <th>
                <SelectField
                  value={vendorFilter}
                  onChangeFn={onSelectVendor}
                  options={optionsForVendorFilter(vendors)}
                />
              </th>
              <th className="text-right">
                <AddDeveloper allVendors={vendors} inviteUserFn={inviteUserFn} />
              </th>
            </tr>
          </thead>
          <thead className="no-top-border">
            <tr>
              <th className="column-width-50">Developer</th>
              <th className="column-width-25">Vendors</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, idx) => {
              const leaving = isLeaving(user.username);
              const createdOn = format(parseISO(user.createdOn), 'dd/MM/yyyy, HH:mm');
              return (
                <tr key={idx}>
                  <td>
                    {leaving ? (
                      <h4>
                        {user.name} <span className="label label-info">You</span>
                      </h4>
                    ) : (
                      <h4>{user.name}</h4>
                    )}
                    <div>{user.username}</div>
                    <div>{createdOn}</div>
                  </td>
                  <td>
                    <Vendors
                      onShowManageModal={() => toggleShowModal(true, user)}
                      vendors={user.vendors}
                      allVendors={vendors}
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-link btn-link-as-href"
                      onClick={() => {
                        this.openResetMfaModal(user);
                      }}
                    >
                      Disable MFA
                    </button>
                    {user.vendors.includes(KEBOOLA_VENDOR_ID) && (
                      <>
                        <br />
                        <button
                          className="btn btn-link btn-link-as-href"
                          onClick={() => {
                            this.openMakeUserAdminModal(user);
                          }}
                        >
                          Make user an admin
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

UsersTable.propTypes = {
  disableMfaFn: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  inviteUserFn: PropTypes.func.isRequired,
  isLeaving: PropTypes.func.isRequired,
  makeUserAdminFn: PropTypes.func.isRequired,
  manageVendorsFormStore: PropTypes.object.isRequired,
  manageVendorsState: PropTypes.object.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onSelectVendor: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  setUserVendor: PropTypes.func.isRequired,
  toggleShowModal: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  vendorFilter: PropTypes.string.isRequired,
  vendors: PropTypes.array.isRequired
};

export default UsersTable;
