import React from 'react';
import PropTypes from 'prop-types';

const DescriptionBlock = ({ children }) => {
  return <div className="description-block">{children}</div>;
};
DescriptionBlock.propTypes = {
  children: PropTypes.node.isRequired
};

export { DescriptionBlock };
