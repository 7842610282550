import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Link from '../../../components/Link';
import { getApp } from '../reducers/apps/details';
import AppDetailBox from '../../../components/apps/AppDetailBox';
import { routes } from '../../../routesConstants';

class AppDetail extends Component {
  render() {
    const { app } = this.props;
    return (
      <div>
        <div className="container">
          <Link to={routes.ROUTE_ADMIN}>&larr; Go to components listing</Link>
          <AppDetailBox module="admin" app={app} />
        </div>
      </div>
    );
  }
}

AppDetail.propTypes = {
  app: PropTypes.object.isRequired
};

const mapStateToProps = (state, options) => {
  const { appId } = options.match.params;
  return {
    app: getApp(state, appId)
  };
};

export default connect(mapStateToProps)(AppDetail);
