import React from 'react';
import Footer from './Footer';
import NavbarOpenSimple from './NavbarOpenSimple';

export default ({ children }) => {
  return (
    <div>
      <NavbarOpenSimple />
      {children}
      <div className="container">
        <Footer />
      </div>
    </div>
  );
};
