import { addNotification as notify, POSITIONS } from 'reapop';
import { SubmissionError } from 'redux-form';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';

const ADMIN_DISABLE_USERS_MFA_REQUEST = 'ADMIN_DISABLE_USERS_MFA_REQUEST';
const ADMIN_DISABLE_USERS_MFA_SUCCESS = 'ADMIN_DISABLE_USERS_MFA_SUCCESS';
const ADMIN_DISABLE_USERS_MFA_FAILURE = 'ADMIN_DISABLE_USERS_MFA_FAILURE';

const disableUsersMfaRequest = () => {
  return {
    type: ADMIN_DISABLE_USERS_MFA_REQUEST
  };
};

const disableUsersMfaSuccess = () => {
  return {
    type: ADMIN_DISABLE_USERS_MFA_SUCCESS
  };
};

const disableUsersMfaFailure = () => {
  return {
    type: ADMIN_DISABLE_USERS_MFA_FAILURE
  };
};

const disableMfa = (email) => (dispatch, getState) => {
  dispatch(disableUsersMfaRequest());
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: getToken(getState())
    }
  };
  return dispatch(fetchProxyWithTokenRefresh(`/admin/users/${email}/mfa`, params)).then(
    (response) => {
      if (response.ok) {
        dispatch(disableUsersMfaSuccess());
        dispatch(
          notify({
            message: `MFA has been disabled for ${email}`,
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      } else {
        return response.json().then((data) => {
          dispatch(disableUsersMfaFailure());
          throw new SubmissionError({
            _error: data.errorMessage || 'Error while disabling MFA for user.'
          });
        });
      }
    }
  );
};

export { disableMfa };
