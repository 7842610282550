export const AUTH_LOGIN_WAITING_FOR_MFA = 'AUTH_LOGIN_WAITING_FOR_MFA';
export const AUTH_LOGIN_CANCEL_MFA = 'AUTH_LOGIN_CANCEL_MFA';

export const waitingForMfa = (data) => {
  return {
    type: AUTH_LOGIN_WAITING_FOR_MFA,
    payload: {
      data: data
    }
  };
};

export const cancelMfa = () => {
  return {
    type: AUTH_LOGIN_CANCEL_MFA
  };
};
