import subDays from 'date-fns/subDays';
import format from 'date-fns/format';

import { fetchProxyWithTokenRefresh } from '../../actions';
import { getToken } from '../../auth/reducers/tokens';
import { COMPONENT_CHANGES_DAYS } from '../../../constants';

export const ADMIN_GET_APP_CHANGES_REQUEST = 'ADMIN_GET_APP_CHANGES_REQUEST';
export const ADMIN_GET_APP_CHANGES_SUCCESS = 'ADMIN_GET_APP_CHANGES_SUCCESS';
export const ADMIN_GET_APP_CHANGES_FAILURE = 'ADMIN_GET_APP_CHANGES_FAILURE';

const getAppChangesRequest = () => {
  return {
    type: ADMIN_GET_APP_CHANGES_REQUEST
  };
};

export const getAppChangesSuccess = (appId, data) => {
  return {
    type: ADMIN_GET_APP_CHANGES_SUCCESS,
    payload: {
      appId: appId,
      changes: data
    }
  };
};

const getAppChangesFailure = () => ({
  type: ADMIN_GET_APP_CHANGES_FAILURE
});

export const getAppChanges = (appId) => (dispatch, getState) => {
  dispatch(getAppChangesRequest());
  const params = {
    method: 'GET',
    headers: {
      Authorization: getToken(getState())
    }
  };

  const sinceParam = format(subDays(new Date(), COMPONENT_CHANGES_DAYS), 'yyyy-MM-dd');

  return dispatch(
    fetchProxyWithTokenRefresh(`/admin/changes?appId=${appId}&since=${sinceParam}`, params)
  ).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        dispatch(getAppChangesSuccess(appId, data));
        return 'ok';
      } else {
        dispatch(getAppChangesFailure());
        return Promise.reject({
          status: response.status,
          message: data.errorMessage || 'Could not fetch component changes.'
        });
      }
    });
  });
};
