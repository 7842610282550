import validateJs from '../../../utils/validate';

const constraints = {
  name: {
    presence: { allowEmpty: false },
    length: { maximum: 64 },
    format: {
      message: 'can only contain a-z, A-Z, 0-9, and underscore _ and has max length 64 chars',

      pattern: /^[a-z0-9A-Z_]+$/
    }
  },

  description: {
    length: { maximum: 256 }
  }
};

export const validate = (app) => {
  let errors = {};
  const result = validateJs.validate(app, constraints);
  if (result) {
    Object.keys(result).forEach((key) => {
      errors[key] = result[key][0];
    });
  }
  return errors;
};
