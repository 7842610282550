import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';

import { EmailLink } from '../components/externalLinks';

class ContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.setState({ show: true });
  }

  closeModal() {
    this.setState({ show: false });
  }

  render() {
    return (
      <React.Fragment>
        <button onClick={this.showModal} className={this.props.className} type="button">
          {this.props.label}
        </button>
        <Modal show={this.state.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Contact us</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center font-size-1-5em">
              <EmailLink email="support@keboola.com" />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-lg btn-primary" onClick={this.closeModal}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

ContactModal.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

ContactModal.defaultProps = {
  label: 'Contact us',
  className: 'btn btn-link btn-link-as-href'
};

export default ContactModal;
