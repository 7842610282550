import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import reactSelectStyles from '../../../../styles/reactSelectStyles';

import { DescriptionBlock } from '../custom';

const MultiSelectField = ({
  formId,
  input,
  placeholder,
  description,
  label,
  options,
  creatable,
  meta: { touched, error }
}) => {
  const selectOptions = options.map((uiOption) => ({
    label: uiOption,
    value: uiOption
  }));

  return (
    <FormGroup
      controlId={`${formId}-${input.name}`}
      validationState={touched && error ? 'error' : null}
    >
      <ControlLabel>{label}</ControlLabel>
      {creatable === true ? (
        <CreatableSelect
          {...input}
          isMulti
          isClearable
          placeholder={placeholder}
          options={selectOptions}
          value={selectOptions.filter((option) => input.value.includes(option.value))}
          onChange={(selected) => {
            const newVal = [];
            if (selected) {
              selected.forEach((item) => {
                newVal.push(item.value);
              });
            }
            input.onChange(newVal);
          }}
          onBlur={() => input.onBlur(input.value)}
          styles={reactSelectStyles}
        />
      ) : (
        <Select
          {...input}
          isMulti
          isClearable
          placeholder={placeholder}
          options={selectOptions}
          value={selectOptions.filter((option) => input.value.includes(option.value))}
          onChange={(selected) => {
            const newVal = [];
            if (selected) {
              selected.forEach((item) => {
                newVal.push(item.value);
              });
            }
            input.onChange(newVal);
          }}
          onBlur={() => input.onBlur(input.value)}
          styles={reactSelectStyles}
        />
      )}
      {touched && error && <HelpBlock>{error}</HelpBlock>}
      {description && <DescriptionBlock>{description}</DescriptionBlock>}
    </FormGroup>
  );
};

MultiSelectField.propTypes = {
  formId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  description: PropTypes.node,
  meta: PropTypes.object.isRequired,
  creatable: PropTypes.bool
};

MultiSelectField.defaultProps = {
  creatable: false
};

export default MultiSelectField;
