import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import NewLineToBr from '../../../../components/NewLineToBr';

class RejectionReasonModal extends React.Component {
  render() {
    const { app, showModal, closeModalFn } = this.props;
    return (
      <Modal show={showModal} onHide={closeModalFn}>
        <Modal.Header closeButton>
          <Modal.Title>{app.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Publishing request for component has been <strong>rejected</strong>, please see the
            following reason of rejection and update the component accordingly.
          </p>
          <p className="component-publishing-rejection-reason">
            <NewLineToBr text={app.publishingRequest.rejectionReason} />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-lg btn-primary" onClick={closeModalFn}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
RejectionReasonModal.propTypes = {
  app: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired
};

export default RejectionReasonModal;
