import { fetchProxyWithTokenRefresh } from '../../actions';
import { getToken } from '../../auth/reducers/tokens';

export const HOME_FETCH_SERVICE_ACCOUNTS_REQUEST = 'HOME_FETCH_SERVICE_ACCOUNTS_REQUEST';
export const HOME_FETCH_SERVICE_ACCOUNTS_SUCCESS = 'HOME_FETCH_SERVICE_ACCOUNTS_SUCCESS';
export const HOME_FETCH_SERVICE_ACCOUNTS_FAILURE = 'HOME_FETCH_SERVICE_ACCOUNTS_FAILURE';

const fetchServiceAccountRequest = () => {
  return {
    type: HOME_FETCH_SERVICE_ACCOUNTS_REQUEST
  };
};

export const fetchServiceAccountsSuccess = (vendorId, data) => {
  return {
    type: HOME_FETCH_SERVICE_ACCOUNTS_SUCCESS,
    payload: {
      vendorId,
      data
    }
  };
};

const fetchServiceAccountsFailure = () => {
  return {
    type: HOME_FETCH_SERVICE_ACCOUNTS_FAILURE
  };
};

export const fetchServiceAccounts = (vendorId) => (dispatch, getState) => {
  dispatch(fetchServiceAccountRequest());

  const params = {
    method: 'GET',
    headers: {
      Authorization: getToken(getState())
    }
  };

  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendorId}/users?service=1`, params)).then(
    (response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(fetchServiceAccountsSuccess(vendorId, data));
          return 'ok';
        } else {
          dispatch(fetchServiceAccountsFailure());
          return Promise.reject({
            status: response.status,
            message: data.errorMessage || 'Could not fetch service accounts'
          });
        }
      });
    }
  );
};

export const HOME_SERVICE_ACCOUNTS_SET_FILTER_QUERY = 'HOME_SERVICE_ACCOUNTS_SET_FILTER_QUERY';

export const setFilterQuery = (query) => (dispatch, getState) => {
  dispatch({
    type: HOME_SERVICE_ACCOUNTS_SET_FILTER_QUERY,
    payload: query
  });
};
