import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { getCurrentVendor } from '../../auth/reducers/profile';
import { createAppSubmit } from '../actions/createApp';
import CreateAppForm, { FORM_ID as CreateAppFormId } from '../components/apps/CreateAppForm';
import AppCreated from '../components/apps/AppAdded';

class CreateApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appCreated: false,
      appId: null
    };
  }

  componentDidMount() {
    this.setState({
      appCreated: false,
      appId: null
    });
  }

  render() {
    const { vendorId, formStore, createAppSubmitFn } = this.props;
    return (
      <div className="container">
        {this.state.appCreated ? (
          <AppCreated module="home" appId={this.state.appId} />
        ) : (
          <CreateAppForm
            vendorId={vendorId}
            formStore={formStore}
            createAppSubmitFn={createAppSubmitFn}
            setAppAddedToTrueFn={(appId) => {
              this.setState({
                appCreated: true,
                appId: appId
              });
            }}
          />
        )}
      </div>
    );
  }
}

const formSelector = formValueSelector(CreateAppFormId);

const mapStateToProps = (state) => ({
  vendorId: getCurrentVendor(state),
  formStore: {
    name: formSelector(state, 'name'),
    type: formSelector(state, 'type')
  }
});

export default connect(mapStateToProps, {
  createAppSubmitFn: createAppSubmit
})(CreateApp);
