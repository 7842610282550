import * as actions from '../../actions/apps';

export default (state = '', action) => {
  if (action.type === actions.HOME_APPS_SET_FILTER) return action.filter;
  return state;
};

const localizeState = (state) => state.home.apps;

export const getFilter = (state) => localizeState(state).filter;
