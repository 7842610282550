import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import { reduxForm, Field } from 'redux-form';

import InlineNotification from '../../../../components/InlineNotification';
import { TextField } from '../../../../common/form/fields';
import { validate } from '../../models/DeprecateAppForm';

const FORM_ID = 'DeprecateAppForm';

class DeprecateAppForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { deprecateAppFn, closeModalFn, app } = this.props;
    return deprecateAppFn(app.vendor.id, app.id, values).then(() => {
      closeModalFn();
    });
  }

  render() {
    const { app, showModal, closeModalFn, handleSubmit, submitting, error } = this.props;
    return (
      <Modal show={showModal} onHide={closeModalFn}>
        <Modal.Header closeButton>
          <Modal.Title>Deprecate component</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Body>
            <p>
              You are about to deprecate the component{' '}
              <strong>
                {app.name} ({app.id}).
              </strong>
            </p>
            <Field
              formId={FORM_ID}
              name="expiredOn"
              component={TextField}
              label="Date of expiration"
              placeholder="Date of expiration, in YYYY-MM-DD format"
            />
            {error && <InlineNotification type="danger">{error}</InlineNotification>}
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-danger btn-lg" disabled={submitting}>
              Deprecate
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
DeprecateAppForm.propTypes = {
  app: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  deprecateAppFn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_ID,
  validate: validate
})(DeprecateAppForm);
