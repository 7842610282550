import React from 'react';

import Link from '../../../../components/Link';
import { routes } from '../../../../routesConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoApps = () => {
  return (
    <div className="text-center margin-vertical-7em">
      <p>
        <FontAwesomeIcon icon={'upload'} size="3x" />
      </p>
      <h1>Add your first component</h1>
      <div className="margin-vertical-3em">
        <Link to={routes.ROUTE_HOME_COMPONENTS_NEW} className="btn btn-primary">
          Add a component
        </Link>
      </div>
    </div>
  );
};

export default NoApps;
