import React from 'react';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Select from 'react-select';
import reactSelectStyles from '../../../../styles/reactSelectStyles';

const SearchSelectField = ({
  formId,
  input,
  placeholder,
  label,
  options,
  meta: { touched, error }
}) => {
  return (
    <FormGroup
      controlId={`${formId}-${input.name}`}
      validationState={touched && error ? 'error' : null}
    >
      <ControlLabel>{label}</ControlLabel>
      <Select
        {...input}
        isClearable={false}
        options={options}
        value={options.find((option) => option.value === input.value)}
        onChange={(selected) => input.onChange(selected.value)}
        onBlur={() => input.onBlur(input.value)}
        placeholder={placeholder}
        styles={reactSelectStyles}
      />
      {touched && error && <HelpBlock>{error}</HelpBlock>}
    </FormGroup>
  );
};

export default SearchSelectField;
