import React from 'react';
import PropTypes from 'prop-types';
import { routeParts, routes } from '../../../../routesConstants';
import { Icon } from '../../../../components/apps/detail';
import Link from '../../../../components/Link';
import { RemoveAppConfirmationModal } from './confirmationModals';
import AppsTableAppStatus from './AppsTableAppStatus';
import AppStatus from './AppStatus';
import { getComponentTypeLabel } from '../../../../helpers';

class AppsTableRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.app.updatedOn !== nextProps.app.updatedOn;
  }

  render() {
    const { app } = this.props;

    return (
      <tr>
        <td className="full-height">
          <Link className="block-link" to={`${routes.ROUTE_HOME_COMPONENTS}/${app.id}`}>
            <Icon app={app} />
          </Link>
        </td>
        <td className="full-height">
          <Link className="block-link" to={`${routes.ROUTE_HOME_COMPONENTS}/${app.id}`}>
            <div className="app-name">
              <h4>{app.name ? app.name : <span>&nbsp;</span>}</h4>
              <div className="small">{app.id}</div>
              <div>{app.createdBy}</div>
            </div>
          </Link>
        </td>
        <td>{getComponentTypeLabel(app.type)}</td>
        <td>{app.version}</td>
        <td>
          <AppsTableAppStatus
            publishingStatus={app.publishingStatus}
            publishingRequest={app.publishingRequest}
            showRejectionReasonFn={() => this.props.showRejectionReasonModal(app)}
          />
          {!app.isPublic && !app.isDeprecated && app.publishingStatus !== 'requested' && (
            <button
              onClick={() => this.props.openApprovalModal(app)}
              className="btn btn-link btn-link-as-href"
            >
              Request publishing
            </button>
          )}
        </td>
        <td>
          <AppStatus
            isPublic={app.isPublic}
            isDeprecated={app.isDeprecated}
            expiredOn={app.expiredOn}
          />
        </td>
        <td>
          <Link to={`${routes.ROUTE_HOME_COMPONENTS}/${app.id}${routeParts.EDIT}`}>Edit</Link>
          <br />
          <RemoveAppConfirmationModal
            error={this.props.getDeleteAppErrorFn(app.id)}
            app={app}
            deleteAppFn={this.props.deleteApp}
            isDeleting={this.props.isDeletingAppFn(app.id)}
          />
          <button
            className="btn btn-link btn-link-as-href"
            onClick={(e) => {
              e.preventDefault();
              this.props.openDeprecationModal(app);
            }}
          >
            Deprecate
          </button>
        </td>
      </tr>
    );
  }
}

AppsTableRow.propTypes = {
  app: PropTypes.object.isRequired,
  openApprovalModal: PropTypes.func.isRequired,
  openDeprecationModal: PropTypes.func.isRequired,
  showRejectionReasonModal: PropTypes.func.isRequired,
  deleteApp: PropTypes.func.isRequired,
  getDeleteAppErrorFn: PropTypes.func.isRequired,
  isDeletingAppFn: PropTypes.func.isRequired
};

export default AppsTableRow;
