import * as actionTypes from '../../actions/apps';

export const initialState = {
  query: '',
  type: null,
  vendor: null,
  stage: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_APPS_SET_FILTER_TYPE:
      return {
        ...state,
        type: action.payload.type
      };
    case actionTypes.OPEN_APPS_SET_FILTER_VENDOR:
      return {
        ...state,
        vendor: action.payload.vendorId
      };
    case actionTypes.OPEN_APPS_SET_FILTER_QUERY:
      return {
        ...state,
        query: action.payload.query
      };
    case actionTypes.OPEN_APPS_SET_FILTER_STAGE:
      return {
        ...state,
        stage: action.payload.stage
      };
    default:
      return state;
  }
};
