import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';

export const ADMIN_REJECT_APP_REQUEST = 'ADMIN_REJECT_APP_REQUEST';
export const ADMIN_REJECT_APP_SUCCESS = 'ADMIN_REJECT_APP_SUCCESS';
export const ADMIN_REJECT_APP_FAILURE = 'ADMIN_REJECT_APP_FAILURE';

export const rejectAppRequest = () => {
  return {
    type: ADMIN_REJECT_APP_REQUEST
  };
};

export const rejectAppSuccess = (app) => {
  return {
    type: ADMIN_REJECT_APP_SUCCESS,
    payload: {
      app: app
    }
  };
};
export const rejectAppFailure = () => {
  return {
    type: ADMIN_REJECT_APP_FAILURE
  };
};

export const rejectApp = (appId, formValues) => (dispatch, getState) => {
  dispatch(rejectAppRequest());
  const params = {
    method: 'POST',
    body: JSON.stringify(formValues),
    headers: {
      Authorization: getToken(getState())
    }
  };
  return dispatch(fetchProxyWithTokenRefresh(`/admin/apps/${appId}/reject`, params)).then(
    (response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(rejectAppSuccess(data));
          dispatch(
            notify({
              message: `The request for publishing has been rejected.`,
              status: 'success',
              dismissible: true,
              dismissAfter: 6000,
              position: POSITIONS.topCenter
            })
          );
          return 'ok';
        } else {
          dispatch(rejectAppFailure());
          throw new SubmissionError({
            _error: data.errorMessage || 'Error while rejecting publishing request.'
          });
        }
      });
    }
  );
};
