import React from 'react';
import { connect } from 'react-redux';

import Link from '../../../components/Link';
import Head from '../../../components/Head';
import { getAppDetail } from '../reducers/apps/details';
import AppDetailBox from '../../../components/apps/AppDetailBox';
import { componentTypes } from '../../home/models/App';
import { routes } from '../../../routesConstants';
import { getComponentTypeLabel } from '../../../helpers';

const getTitle = (app) => {
  return componentTypes.includes(app.type)
    ? `${app.name} ${getComponentTypeLabel(app.type)}`
    : app.name;
};

class AppDetail extends React.Component {
  render() {
    return (
      <>
        <Head
          title={getTitle(this.props.appDetail)}
          metaDescription={this.props.appDetail.shortDescription}
        />
        <div className="container">
          <Link to={routes.ROUTE_OPEN_COMPONENTS}>&larr; Go to components listing</Link>
          <AppDetailBox module="open" app={this.props.appDetail} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, options) => {
  const { appId } = options.match.params;
  const localState = state.open.apps;
  return {
    appId,
    appDetail: getAppDetail(localState.details, appId)
  };
};
AppDetail = connect(mapStateToProps)(AppDetail);

export default AppDetail;
