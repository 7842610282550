import { COMPONENT_TYPE_EXTRACTOR, COMPONENT_TYPE_WRITER } from './modules/home/models/App';

const getComponentTypeLabel = (type) => {
  if (type === COMPONENT_TYPE_EXTRACTOR) {
    return 'data source';
  }
  if (type === COMPONENT_TYPE_WRITER) {
    return 'data destination';
  }
  return type;
};

export { getComponentTypeLabel };
