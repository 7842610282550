import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

import { TextField, SelectField } from '../../../../common/form/fields';
import { AVAILABLE_OPTIONS, createWebalizedAppId } from '../../models/App';
import { validate } from '../../models/CreateAppForm';
import InlineNotification from '../../../../components/InlineNotification';

export const FORM_ID = 'CreateAppForm';

const renderIdField = ({ formId, input, label, prefix, placeholder, meta: { touched, error } }) => {
  return (
    <FormGroup
      controlId={`${formId}-${input.name}`}
      validationState={touched && error ? 'error' : null}
    >
      <ControlLabel>{label}</ControlLabel>
      <InputGroup>
        <InputGroup.Addon>{prefix}.</InputGroup.Addon>
        <FormControl {...input} placeholder={placeholder} type="text" />
      </InputGroup>
      {touched && error && <HelpBlock>{error}</HelpBlock>}
    </FormGroup>
  );
};

class CreateAppForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { createAppSubmitFn, setAppAddedToTrueFn, reset } = this.props;
    return createAppSubmitFn(values).then((appId) => {
      reset();
      setAppAddedToTrueFn(appId);
    });
  }

  render() {
    const { formStore, pristine, submitting, handleSubmit, vendorId, error, change } = this.props;
    return (
      <div className="row">
        <div className="col-md-offset-3 col-md-6">
          <h1 className="text-center">Add your component</h1>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <Field
              formId={FORM_ID}
              name="name"
              component={TextField}
              label="Name"
              placeholder="Name of your component without its type suffix (e.g. MySQL)"
              onChange={(event, newValue) => {
                change('id', createWebalizedAppId(newValue, formStore.type));
              }}
              autoFocus
            />
            <Field
              formId={FORM_ID}
              name="type"
              component={SelectField}
              label="Type"
              data={AVAILABLE_OPTIONS.type}
              nameField="name"
              valueField="value"
              placeholder="Please select ..."
              onChange={(event, newValue) => {
                change('id', createWebalizedAppId(formStore.name, newValue));
              }}
            />
            <Field
              formId={FORM_ID}
              name="id"
              component={renderIdField}
              label="ID"
              placeholder="Component ID (e.g. ex-mysql)"
              prefix={vendorId}
            />

            {error && <InlineNotification type="danger">{error}</InlineNotification>}

            <div className="text-center">
              <button
                disabled={pristine || submitting}
                type="submit"
                className="btn btn-primary btn-lg"
              >
                Add component
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default reduxForm({
  form: FORM_ID,
  validate: validate
})(CreateAppForm);
