import React from 'react';
import PropTypes from 'prop-types';
import Link from 'found/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { routes } from '../../../routesConstants';
import config from '../../../../config';
import NewLineToBr from '../../../components/NewLineToBr';
import { EmailLink, Link as ExternalLink } from '../../../components/externalLinks';
import SearchInput from '../../../common/form/SearchInput';
import EditVendorModal from './EditVendorModal';
import ApproveVendorModal from './ApproveVendorModal';

class VendorsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVendor: null
    };
  }

  render() {
    const {
      filter,
      onChangeFilter,
      vendors,
      onToggleEditVendor,
      vendorEditorState,
      onUpdateVendor,
      onToggleVendorApprove,
      showApproveModal,
      approveVendor
    } = this.props;

    return (
      <div>
        <ApproveVendorModal
          onApproveVendor={approveVendor}
          onHide={() => {
            this.setState({ selectedVendor: null });
            onToggleVendorApprove(false);
          }}
          show={showApproveModal}
          vendor={this.state.selectedVendor}
        />
        <EditVendorModal
          onUpdateVendor={onUpdateVendor}
          onHide={() => onToggleEditVendor(false, {})}
          show={vendorEditorState.show}
          vendor={vendorEditorState.vendor}
        />
        <table className="table table-hover">
          <thead className="search-bar">
            <tr>
              <th>
                <SearchInput
                  value={filter}
                  onChangeFn={onChangeFilter}
                  placeholder="Filter by vendor ID, name or email"
                />
              </th>
              <th className="text-right" colSpan="4">
                <button
                  type="button"
                  onClick={() => onToggleEditVendor(true, {})}
                  className="btn btn-primary"
                >
                  Add vendor
                </button>
              </th>
            </tr>
          </thead>
          <thead className="no-top-border">
            <tr>
              <th className="column-width-50">Name, email and address</th>
              <th className="column-width-16">ID</th>
              <th>Status</th>
              <th className="column-width-16">Test project</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {vendors.map((vendor, idx) => (
              <tr key={idx}>
                <td>
                  <h4>{vendor.name}</h4>
                  <ul className="list-unstyled font-size-smaller">
                    {vendor.email && (
                      <li>
                        <EmailLink email={vendor.email} />
                      </li>
                    )}
                    <li>
                      <NewLineToBr text={vendor.address} />
                    </li>
                  </ul>
                </td>
                <td>
                  {vendor.id.indexOf('_') !== 0 && (
                    <Link
                      to={{
                        pathname: config.BASE_PATH + routes.ROUTE_ADMIN,
                        query: { query: vendor.id }
                      }}
                    >
                      {vendor.id}
                    </Link>
                  )}
                </td>
                <td>
                  {vendor.isPublic && vendor.isApproved ? (
                    <strong className="text-success">Public, Approved</strong>
                  ) : (
                    (vendor.isPublic ? 'Public' : 'Private') +
                    ', ' +
                    (vendor.isApproved ? 'Approved' : 'Waiting')
                  )}
                  <br />
                  {vendor.isTrusted && (
                    <strong className="text-primary">
                      <FontAwesomeIcon icon={'check-circle'} /> Trusted
                    </strong>
                  )}
                </td>
                <td className="word-break-all">
                  {vendor.projectUrl && (
                    <ExternalLink className="font-size-smaller" href={vendor.projectUrl}>
                      Open project
                    </ExternalLink>
                  )}
                </td>
                <td>
                  <button
                    onClick={() => {
                      onToggleEditVendor(true, vendor);
                    }}
                    className="btn btn-link btn-link-as-href"
                  >
                    Edit
                  </button>
                  {!vendor.isApproved && (
                    <button
                      className="btn btn-link btn-link-as-href"
                      onClick={() => {
                        this.setState({ selectedVendor: vendor }, () =>
                          onToggleVendorApprove(true)
                        );
                      }}
                    >
                      Approve vendor
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

VendorsTable.propTypes = {
  filter: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  vendors: PropTypes.array.isRequired,
  onToggleEditVendor: PropTypes.func.isRequired,
  vendorEditorState: PropTypes.object.isRequired,
  onUpdateVendor: PropTypes.func.isRequired,
  onToggleVendorApprove: PropTypes.func.isRequired,
  showApproveModal: PropTypes.bool.isRequired,
  approveVendor: PropTypes.func.isRequired
};

export default VendorsTable;
