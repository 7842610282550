import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../components/Link';
import { routes } from '../../../routesConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccountCreated = ({ email }) => {
  return (
    <div className="static-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <Link to="/" className="close">
              <span>×</span>
            </Link>
            <h4 className="modal-title">Account created</h4>
          </div>
          <div className="modal-body text-center">
            <p className="text-success">
              <FontAwesomeIcon icon={'check'} size="3x" />
            </p>
            <div className="margin-vertical-3em">
              <p>
                A confirmation link has been sent to
                <br />
                <strong>{email},</strong>
                <br />
                please click on the confirmation link to verify the account.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <div className="text-center">
              <Link to={routes.ROUTE_AUTH_LOGIN} className="btn btn-lg btn-link btn-link-bold">
                Sign in
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
AccountCreated.propTypes = {
  email: PropTypes.string.isRequired
};

export default AccountCreated;
