import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { reduxForm } from 'redux-form';
import InlineNotification from '../../../../components/InlineNotification';

const FORM_ID = 'AdminPublishAppForm';

class PublishAppForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { approveAppFn, closeModalFn, app } = this.props;
    return approveAppFn(app.id).then(() => {
      closeModalFn();
    });
  }

  render() {
    const { app, showModal, closeModalFn, handleSubmit, submitting, error } = this.props;
    return (
      <Modal show={showModal} onHide={closeModalFn}>
        <Modal.Header closeButton>
          <Modal.Title>Publish component</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Body>
            <p>
              You are about to publish the component <strong>{app.name}</strong> (
              <code>{app.id}</code>).
            </p>
            <p>
              The component will become public and will be available for all Keboola Connection
              users.
            </p>
            {error && <InlineNotification type="danger">{error}</InlineNotification>}
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn btn-primary btn-lg" disabled={submitting}>
              Publish
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export default reduxForm({
  form: FORM_ID
})(PublishAppForm);
