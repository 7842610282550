import * as detailActions from '../../actions/appDetail';
import * as deleteActions from '../../actions/appDelete';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case detailActions.ADMIN_GET_APP_DETAIL_SUCCESS:
      return {
        ...state,
        [action.payload.app.id]: action.payload.app
      };
    case deleteActions.ADMIN_DELETE_APP_SUCCESS:
      const { [action.payload]: appToDelete, ...details } = state;
      return details;
    default:
      return state;
  }
};

export const getApp = (state, appId) => {
  return state.admin.apps.details[appId];
};
