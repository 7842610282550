import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

import { DescriptionBlock } from '../custom';

const TextField = ({
  formId,
  input,
  type,
  label,
  placeholder,
  description,
  meta: { touched, error },
  autoFocus,
  readOnly
}) => {
  return (
    <FormGroup
      controlId={`${formId}-${input.name}`}
      validationState={touched && error ? 'error' : null}
    >
      <ControlLabel>{label}</ControlLabel>
      <FormControl
        {...input}
        type={type}
        placeholder={placeholder}
        autoFocus={autoFocus}
        readOnly={readOnly}
      />
      {touched && error && <HelpBlock>{error}</HelpBlock>}
      {description && <DescriptionBlock>{description}</DescriptionBlock>}
    </FormGroup>
  );
};
TextField.propTypes = {
  formId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  description: PropTypes.node,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string
};
TextField.defaultProps = {
  type: 'text'
};

export default TextField;
