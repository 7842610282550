import * as selectVendorActions from '../actions/selectVendor';

export const initialState = {
  vendorSelected: false,
  vendorCreated: false
};

const selectVendor = (state = initialState, action) => {
  switch (action.type) {
    case selectVendorActions.HOME_JOIN_TO_VENDOR_SUCCESS:
      return {
        ...state,
        vendorSelected: true,
        vendorCreated: false
      };
    case selectVendorActions.HOME_CREATE_NEW_VENDOR_SUCCESS:
      return {
        ...state,
        vendorSelected: false,
        vendorCreated: true
      };
    default:
      return state;
  }
};

export default selectVendor;

export const getIsVendorSelected = (state) => {
  return state.home.selectVendor.vendorSelected;
};

export const getIsVendorCreated = (state) => {
  return state.home.selectVendor.vendorCreated;
};
