import React, { Component } from 'react';
import { untouch, change, Field, reduxForm } from 'redux-form';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { TextField } from '../../../common/form/fields';
import { validate } from '../models/ApproveVendorForm';
import InlineNotification from '../../../components/InlineNotification';

const FORM_ID = 'approvevendormodal';

class ApproveVendorModal extends Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModalIfOk = this.closeModalIfOk.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show === false && this.props.show === true) {
      this.props.dispatch(change(FORM_ID, 'tmpId', this.props.vendor.id));
      this.props.dispatch(untouch(FORM_ID, 'tmpId'));
    }
  }

  closeModal() {
    this.props.onHide();
    this.props.reset();
  }

  closeModalIfOk(result) {
    if (result === 'ok') {
      this.closeModal();
    }
  }

  renderFormBody() {
    return (
      <span>
        <Field
          readOnly
          formId={FORM_ID}
          name="tmpId"
          component={TextField}
          placeholder="Enter temporary id"
          label="Temporary Id"
        />

        <Field
          autoFocus
          formId={FORM_ID}
          name="newId"
          component={TextField}
          placeholder="Enter new id for vendor"
          label="New Vendor Id"
        />
      </span>
    );
  }

  handleSubmit(values) {
    return this.props.onApproveVendor(values).then(this.closeModalIfOk);
  }

  render() {
    return (
      <div className="modal-container">
        <Modal show={this.props.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Approve Vendor</Modal.Title>
          </Modal.Header>
          <form onSubmit={this.props.handleSubmit(this.handleSubmit)}>
            <Modal.Body>
              {this.renderErrorMessage()}
              <div>{this.renderFormBody()}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type="submit"
                disabled={this.props.pristine || this.props.submitting}
                bsSize="large"
                bsStyle="primary"
              >
                Confirm
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }

  renderErrorMessage() {
    if (this.props.error) {
      return <InlineNotification type="danger">{this.props.error}</InlineNotification>;
    }
  }
}

export default reduxForm({ form: FORM_ID, validate })(ApproveVendorModal);
