import React from 'react';
import { connect } from 'react-redux';

import Head from '../../../components/Head';
import { createAccount } from '../actions/createAccount';
import CreateAccountForm from '../components/CreateAccountForm';

class CreateAccount extends React.Component {
  render() {
    return (
      <>
        <Head title="Create Account" />
        <CreateAccountForm createAccountFn={this.props.createAccountFn} />
      </>
    );
  }
}

export default connect(null, {
  createAccountFn: createAccount
})(CreateAccount);
