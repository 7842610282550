import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Modal from 'react-bootstrap/lib/Modal';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import { DescriptionBlock } from '../custom';
import { ImageRenderer } from '../../../components/apps/detail';

class TextAreaMarkdownWithPreviewField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preview: false
    };
    this.togglePreview = this.togglePreview.bind(this);
  }

  togglePreview() {
    this.setState({ preview: !this.state.preview });
  }

  render() {
    const {
      formId,
      input,
      label,
      placeholder,
      description,
      previewValue,
      rows,
      meta: { touched, error }
    } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-sm-7">
            <span className="input-label">Configuration description</span>
          </div>
          <div className="col-sm-5 text-right">
            {previewValue && (
              <button
                type="button"
                className={'btn btn-sm btn-link btn-link-as-href'}
                onClick={this.togglePreview}
              >
                Preview
              </button>
            )}
          </div>
        </div>
        <div>
          <FormGroup
            controlId={`${formId}-${input.name}`}
            validationState={touched && error ? 'error' : null}
          >
            {label && <ControlLabel>{label}</ControlLabel>}
            <FormControl
              {...input}
              componentClass="textarea"
              placeholder={placeholder}
              rows={rows || ''}
            />
            {touched && error && <HelpBlock>{error}</HelpBlock>}
            {description && <DescriptionBlock>{description}</DescriptionBlock>}
          </FormGroup>
          <Modal bsSize="large" show={this.state.preview} onHide={this.togglePreview}>
            <Modal.Header closeButton>
              <Modal.Title>Configuration description preview</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactMarkdown skipHtml remarkPlugins={[gfm]} components={{ img: ImageRenderer }}>
                {previewValue}
              </ReactMarkdown>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
TextAreaMarkdownWithPreviewField.propTypes = {
  formId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  previewValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  description: PropTypes.node,
  rows: PropTypes.string,
  meta: PropTypes.object.isRequired
};

export default TextAreaMarkdownWithPreviewField;
