import validateJs from '../../../utils/validate';

const constraints = {
  tmpId: {
    presence: { allowEmpty: false },
    length: {
      maximum: 32
    },
    format: {
      pattern: /^[a-z0-9-_.]+$/,
      message: 'can only contain alphanumeric characters and dash.'
    }
  },
  newId: {
    presence: { allowEmpty: false },
    length: {
      maximum: 32
    },
    format: {
      pattern: /^[a-z0-9-]+$/
    }
  }
};

export const validate = (values) => {
  let errors = {};
  const result = validateJs.validate(values, constraints);
  if (result) {
    Object.keys(result).forEach((key) => {
      errors[key] = result[key][0];
    });
  }
  return errors;
};
