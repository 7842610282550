import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getAppChanges } from '../reducers/apps/changes';
import { getApp } from '../reducers/apps/details';
import AppChangesTable from '../components/apps/AppChangesTable';
import { routes } from '../../../routesConstants';
import Link from '../../../components/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COMPONENT_CHANGES_DAYS } from '../../../constants';

class AppChanges extends Component {
  render() {
    const { changes, app } = this.props;
    return (
      <div>
        <div className="container">
          <Link to={routes.ROUTE_ADMIN}>&larr; Go to components listing</Link>
          {changes.length > 0 ? (
            <AppChangesTable changes={changes} app={app} />
          ) : (
            <div className="text-center margin-vertical-4em">
              <p>
                <FontAwesomeIcon icon={['far', 'meh']} size="3x" />
              </p>
              <p className="font-size-1-5em">
                No component changes in last {COMPONENT_CHANGES_DAYS} days.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

AppChanges.propTypes = {
  changes: PropTypes.array.isRequired
};

const mapStateToProps = (state, options) => {
  const { appId } = options.match.params;
  return {
    changes: getAppChanges(state, appId),
    app: getApp(state, appId)
  };
};

export default connect(mapStateToProps)(AppChanges);
