import React from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapMenuItem from 'react-bootstrap/lib/MenuItem';
import Link from './Link';

const MenuItem = ({ children, href, componentClass, ...rest }) => {
  return (
    <ReactBootstrapMenuItem to={href} href={href} componentClass={componentClass || Link} {...rest}>
      {children}
    </ReactBootstrapMenuItem>
  );
};

MenuItem.propTypes = {
  href: PropTypes.string.isRequired
};

export default MenuItem;
