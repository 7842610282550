import React, { Component } from 'react';
import { connect } from 'react-redux';

import Footer from './Footer';
import NavbarAdmin from './NavbarAdmin';
import { getProfile, getAllVendors, getCurrentVendor } from '../modules/auth/reducers/profile';
import { switchVendorAndRedirect } from '../modules/auth/actions/profile';
import { logout } from '../modules/auth/actions/logout';
import { loadUsers, inviteUser } from '../modules/home/actions/vendors';
import { loadVendorApps } from '../modules/home/actions/apps';
import config from '../../config';

class LayoutAdmin extends Component {
  render() {
    const path = this.props.match.location ? this.props.match.location.pathname : '';
    const { children, allVendors, profile } = this.props;
    if (profile.name !== null) {
      return (
        <div>
          <NavbarAdmin
            currentPath={path.slice(config.BASE_PATH.length)}
            logoutFn={this.props.logout}
            allVendors={allVendors}
            onSelectVendor={this.props.loadUsersAndSelectVendor}
            profile={profile}
          />
          {children}
          <div className="container">
            <Footer />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    allVendors: getAllVendors(state),
    currentVendor: getCurrentVendor(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    inviteUserFn: (formValues) => dispatch(inviteUser(formValues)),
    loadUsersAndSelectVendor: (vendor) =>
      Promise.all([dispatch(loadUsers(vendor)), dispatch(loadVendorApps(vendor))]).then(() =>
        dispatch(switchVendorAndRedirect(vendor))
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutAdmin);
