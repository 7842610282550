import { combineReducers } from 'redux';

import list from './list';
import filter from './filter';
import details from './details';

export default combineReducers({
  list,
  filter,
  details
});
