const mfaChallengeType = {
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
  SMS_MFA: 'SMS_MFA'
};

const KEBOOLA_VENDOR_ID = 'keboola';

const COMPONENT_CHANGES_DAYS = 90;

const FLAG_BETA = 'appInfo.beta';
const FLAG_EXPERIMENTAL = 'appInfo.experimental';

export {
  mfaChallengeType,
  KEBOOLA_VENDOR_ID,
  COMPONENT_CHANGES_DAYS,
  FLAG_BETA,
  FLAG_EXPERIMENTAL
};
