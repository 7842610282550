import * as actions from '../../actions/serviceAccounts';

export const initialState = {
  query: ''
};

export default (state = initialState, action) => {
  if (action.type === actions.HOME_SERVICE_ACCOUNTS_SET_FILTER_QUERY)
    return {
      ...state,
      query: action.payload
    };
  return state;
};

export const getFilterQuery = (state) => {
  return state.home.serviceAccounts.filter.query;
};
