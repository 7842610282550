import validateJs from '../../../utils/validate';

const constraints = {
  email: {
    presence: { allowEmpty: false },
    email: true
  },
  password: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/,
      message:
        'must contain at least 8 characters and at least one lowercase letter, one uppercase letter and one number.'
    }
  },

  code: {
    presence: { allowEmpty: false }
  }
};

export const validate = (values) => {
  const { password, password2 } = values;
  const result = validateJs.validate(values, constraints) || {};
  let errors = Object.keys(result).reduce((memo, key) => {
    memo[key] = result[key][0];
    return memo;
  }, {});
  if (password && password2 && password !== password2) {
    errors['password2'] = 'Paswords must match';
  }
  return errors;
};
