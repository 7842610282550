import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import Button from 'react-bootstrap/lib/Button';
import gfm from 'remark-gfm';

import RequestApprovalForm from '../../modules/home/components/apps/RequestApprovalForm';
import { approveApp } from '../../modules/home/actions/apps';
import NewLineToBr from '../NewLineToBr';
import { EmailLink } from '../externalLinks';
import { getComponentTypeLabel } from '../../helpers';
import { getIcon } from '../../utils/helpers';
import ComponentBadge from '../../components/ComponentBadge';
import { FLAG_BETA, FLAG_EXPERIMENTAL } from '../../constants';

const is3rdParty = (app) => {
  return app.vendor.type !== 'keboola';
};

const PublicFlagsList = (app) => {
  const isBeta = app.uiOptions.includes(FLAG_BETA);
  const isExperimental = app.uiOptions.includes(FLAG_EXPERIMENTAL);

  return (
    <ul className="list-unstyled">
      {(isBeta || isExperimental) && (
        <li className="stage">
          Stage - <ComponentBadge flags={app.uiOptions} />
        </li>
      )}
      <li>ID - {app.id}</li>
      {app.type ? <li>Component type - {getComponentTypeLabel(app.type)}</li> : null}
      {app.categories.length ? <li>Categories - {app.categories.join(', ')}</li> : null}
      {is3rdParty(app) ? <li>3rd party component</li> : null}
      {is3rdParty(app) ? (
        <li>Support is provided by its author, not Keboola</li>
      ) : (
        <li>Support is provided by Keboola</li>
      )}
      {app.uiOptions.includes('appInfo.fullAccess') ? (
        <li>Will have full access to the project including all its data.</li>
      ) : null}
      {app.fees ? <li>Extra charge to use this component</li> : null}
      {app.uiOptions.includes('appInfo.dataIn') ? (
        <li>Extracts data from outside sources</li>
      ) : null}
      {app.uiOptions.includes('appInfo.dataOut') ? (
        <li>Sends data outside of Keboola Connection</li>
      ) : null}
      {app.licenseUrl ? (
        <li className="overflow-ellipsis">
          License: <a href={app.licenseUrl}>{app.licenseUrl}</a>
        </li>
      ) : null}
      {app.documentationUrl ? (
        <li className="overflow-ellipsis">
          Documentation: <a href={app.documentationUrl}>{app.documentationUrl}</a>
        </li>
      ) : null}
      {app.sourceCodeUrl && (
        <li className="overflow-ellipsis">
          Source code: <a href={app.sourceCodeUrl}>{app.sourceCodeUrl}</a>
        </li>
      )}
    </ul>
  );
};

const HomeFlagsList = (app) => {
  return (
    <ul className="list-unstyled">
      <li>
        Component status: <AppStatus app={app} />
      </li>
      <li>Default bucket: {app.defaultBucket ? 'Enabled' : 'Disabled'}</li>
      {app.defaultBucket && <li>Default bucket stage: {app.defaultBucketStage}</li>}
      {app.network ? <li>Network: {app.network}</li> : null}
      {app.uiOptions.length > 0 ? <li>UI options: {app.uiOptions.join(', ')}</li> : null}
      {app.actions.length > 0 ? <li>Actions: {app.actions.join(', ')}</li> : null}
      <li>
        {'Forwards token: '}
        {app.forwardToken && app.forwardToken === true ? 'Yes' : 'No'}
      </li>
      <li>
        {'Forwards token details: '}
        {app.forwardTokenDetails && app.forwardTokenDetails === true ? 'Yes' : 'No'}
      </li>
      {app.requiredMemory ? <li>Required memory: {app.requiredMemory}</li> : null}
      {app.processTimeout ? <li>Process timeout: {app.processTimeout}s</li> : null}
    </ul>
  );
};

const AppStatus = ({ app }) => {
  if (app.isPublic) {
    return 'Public';
  }

  if (app.publishingStatus === 'requested') {
    return 'Private (waiting for publishing)';
  }

  if (app.isDeprecated) {
    return 'Private, Deprecated';
  }

  return 'Private';
};

export const PublicFlags = ({ app }) => {
  return <div>{PublicFlagsList(app)}</div>;
};

export const HomeFlags = ({ app }) => {
  return (
    <div>
      {PublicFlagsList(app)}
      {HomeFlagsList(app)}
    </div>
  );
};

export const Icon = ({ app }) => {
  return (
    <img
      height={64}
      className="app-icon"
      src={getIcon(app)}
      alt={app.name + ' icon'}
      loading="lazy"
    />
  );
};

export const Vendor = ({ app: { vendor } }) => {
  return (
    <div>
      <p>Developed by</p>
      <ul className="list-unstyled">
        <li>
          <strong>{vendor.name}</strong>
        </li>
        <li>
          <NewLineToBr text={vendor.address} />
        </li>
        <li>
          <EmailLink email={vendor.email} />
        </li>
      </ul>
    </div>
  );
};

export const ShortDescription = ({ app: { shortDescription } }) => {
  return <p className="text-center font-size-smaller">{shortDescription}</p>;
};

export const ImageRenderer = ({ src, alt }) => (
  <img className="img-responsive" src={src} alt={alt} />
);

export const LongDescription = ({ description, noDescriptionMessage }) => {
  return description ? (
    <div className="long-description-preview">
      <ReactMarkdown skipHtml remarkPlugins={[gfm]} components={{ img: ImageRenderer }}>
        {description}
      </ReactMarkdown>
    </div>
  ) : noDescriptionMessage ? (
    <p>{noDescriptionMessage}</p>
  ) : null;
};

export const RequestApprovalButton = connect(null, { approveApp })((props) => {
  if (props.app.isPublic || props.app.isDeprecated || props.app.publishingStatus === 'requested') {
    return null;
  }

  const [showModal, openModal] = useState(false);

  return (
    <>
      <Button bsStyle="success" bsSize="lg" onClick={() => openModal(true)}>
        Request publishing
      </Button>
      <RequestApprovalForm
        app={props.app}
        showModal={showModal}
        requestApprovalFn={props.approveApp}
        closeModalFn={() => openModal(false)}
      />
    </>
  );
});
