import { combineReducers } from 'redux';
import * as actionTypes from '../actions/vendors';

const filter = (state = '', action) => {
  if (action.type === actionTypes.ADMIN_VENDORS_SET_FILTER) {
    return action.filter;
  }
  return state;
};

const editor = (state = { show: false, vendor: {} }, action) => {
  if (action.type === actionTypes.ADMIN_VENDORS_ON_EDIT) {
    return { ...state, show: action.show, vendor: action.vendor };
  }
  return state;
};

const toggleApprove = (state = false, action) => {
  if (action.type === actionTypes.ADMIN_VENDORS_TOGGLE_APPROVE) {
    return action.show;
  }
  return state;
};

function vendorContains(vendor, value) {
  const propsToSearch = ['email', 'name', 'id'];
  return propsToSearch.reduce(
    (memo, prop) => memo || vendor[prop].toLowerCase().indexOf(value.toLowerCase()) >= 0,
    false
  );
}

const localizeState = (state) => state.admin.vendors;

export const getFilter = (state) => localizeState(state).filter;

export const getVendors = (state) => {
  return state.admin.vendors.list;
};

export const getFilteredVendors = (state) => {
  const filter = getFilter(state);
  const vendors = getVendors(state);
  return vendors.filter((v) => vendorContains(v, filter));
};

export const getVendorEditorState = (state) => ({ ...localizeState(state).editor });
export const getToggleApprove = (state) => localizeState(state).toggleApprove;

const list = (state = [], action) => {
  if (action.type === actionTypes.ADMIN_FETCH_VENDORS_SUCCESS) {
    return action.payload.vendors;
  }
  return state;
};

export default combineReducers({
  filter,
  editor,
  toggleApprove,
  list
});
