import { FLAG_BETA, FLAG_EXPERIMENTAL } from '../../../constants';

const APP_REPOSITORY_TYPE_ECR = 'ecr';
const APP_REPOSITORY_TYPE_QUAY = 'quay';
const APP_REPOSITORY_TYPE_DOCKER_HUB = 'dockerhub';
const APP_REPOSITORY_TYPE_KEBOOLA_BUILDER = 'builder';

const COMPONENT_TYPE_APPLICATION = 'application';
const COMPONENT_TYPE_EXTRACTOR = 'extractor';
const COMPONENT_TYPE_WRITER = 'writer';
const COMPONENT_TYPE_TRANSFORMATION = 'transformation';
const COMPONENT_TYPE_PROCESSOR = 'processor';
const COMPONENT_TYPE_CODE_PATTERN = 'code-pattern';
const COMPONENT_TYPE_DATA_APP = 'data-app';

const componentTypes = [
  COMPONENT_TYPE_APPLICATION,
  COMPONENT_TYPE_EXTRACTOR,
  COMPONENT_TYPE_PROCESSOR,
  COMPONENT_TYPE_TRANSFORMATION,
  COMPONENT_TYPE_WRITER,
  COMPONENT_TYPE_CODE_PATTERN,
  COMPONENT_TYPE_DATA_APP
];

const AVAILABLE_OPTIONS = {
  uiOptions: [
    'genericDockerUI',
    'genericDockerUI-rows',
    'genericDockerUI-tableInput',
    'genericDockerUI-fileInput',
    'genericDockerUI-tableOutput',
    'genericDockerUI-fileOutput',
    'genericDockerUI-authorization',
    'genericDockerUI-processors',
    'genericDockerUI-resetState',
    'genericDockerUI-simpleTableInput',
    'genericCodeBlocksUI',
    'genericTemplatesUI',
    'genericPackagesUI',
    'appInfo.dataIn',
    'appInfo.dataOut',
    FLAG_EXPERIMENTAL,
    FLAG_BETA
  ],
  type: [
    {
      name: 'Data source (Extractor)',
      value: COMPONENT_TYPE_EXTRACTOR
    },
    {
      name: 'Data destination (Writer)',
      value: COMPONENT_TYPE_WRITER
    },
    {
      name: 'Transformation',
      value: COMPONENT_TYPE_TRANSFORMATION
    },
    {
      name: 'Application',
      value: COMPONENT_TYPE_APPLICATION
    },
    {
      name: 'Processor',
      value: COMPONENT_TYPE_PROCESSOR
    },
    {
      name: 'Code pattern',
      value: COMPONENT_TYPE_CODE_PATTERN
    },
    {
      name: 'Data app',
      value: COMPONENT_TYPE_DATA_APP
    },
    {
      name: 'Other',
      value: 'other'
    }
  ],
  repository: {
    type: [
      {
        name: 'AWS ECR',
        value: APP_REPOSITORY_TYPE_ECR
      },
      {
        name: 'Quay',
        value: APP_REPOSITORY_TYPE_QUAY
      },
      {
        name: 'Docker Hub',
        value: APP_REPOSITORY_TYPE_DOCKER_HUB
      },
      {
        name: 'Keboola Builder',
        value: APP_REPOSITORY_TYPE_KEBOOLA_BUILDER
      }
    ]
  },
  complexity: [
    {
      name: 'Easy',
      value: 'easy'
    },
    {
      name: 'Medium',
      value: 'medium'
    },
    {
      name: 'Hard',
      value: 'hard'
    }
  ],
  categories: [
    'API',
    'Accounting',
    'Advertisement',
    'Analytics',
    'CRM',
    'Data Visualization',
    'Database',
    'E-commerce',
    'ERP',
    'File Storage',
    'Marketing',
    'Monitoring',
    'Project Management',
    'Social'
  ],
  stagingStorageInput: [
    { name: 'Local', value: 'local' },
    { name: 'AWS S3', value: 's3' },
    { name: 'Workspace Snowflake', value: 'workspace-snowflake' },
    { name: 'Workspace Synapse', value: 'workspace-synapse' },
    { name: 'Workspace Redshift', value: 'workspace-redshift' },
    { name: 'Workspace Exasol', value: 'workspace-exasol' },
    { name: 'Workspace Teradata', value: 'workspace-teradata' },
    { name: 'Workspace BigQuery', value: 'workspace-bigquery' },
    { name: 'Workspace ABS', value: 'workspace-abs' },
    { name: 'ABS', value: 'abs' },
    { name: 'None', value: 'none' }
  ],
  stagingStorageOutput: [
    { name: 'Local', value: 'local' },
    { name: 'Workspace Snowflake', value: 'workspace-snowflake' },
    { name: 'Workspace Synapse', value: 'workspace-synapse' },
    { name: 'Workspace Redshift', value: 'workspace-redshift' },
    { name: 'Workspace ABS', value: 'workspace-abs' },
    { name: 'Workspace Exasol', value: 'workspace-exasol' },
    { name: 'Workspace Teradata', value: 'workspace-teradata' },
    { name: 'Workspace BigQuery', value: 'workspace-bigquery' },
    { name: 'None', value: 'none' }
  ],
  features: [
    'container-root-user',
    'dev-branch-configuration-unsafe',
    'dev-branch-job-blocked',
    'dev-mapping-allowed',
    'allow-use-file-storage-only',
    'allow-tag-override',
    'has-sample-data',
    'has-simplified-ui',
    'mlflow-artifacts-access',
    'container-tcpkeepalive-60s-override'
  ],
  dataTypeSupport: [
    { name: 'Authoritative', value: 'authoritative' },
    { name: 'Hints', value: 'hints' },
    { name: 'None', value: 'none' }
  ],
  allowedProcessorPosition: [
    { name: 'Any', value: 'any' },
    { name: 'Before', value: 'before' },
    { name: 'After', value: 'after' }
  ]
};

const AVAILABLE_STAGE_OPTIONS = [
  {
    name: 'Beta',
    value: 'beta'
  },
  {
    name: 'Experimental',
    value: 'experimental'
  }
];

const webalizedAppTypesMap = {
  [COMPONENT_TYPE_EXTRACTOR]: 'ex',
  [COMPONENT_TYPE_WRITER]: 'wr',
  [COMPONENT_TYPE_APPLICATION]: 'app',
  [COMPONENT_TYPE_PROCESSOR]: 'processor',
  [COMPONENT_TYPE_CODE_PATTERN]: 'code-pattern',
  [COMPONENT_TYPE_DATA_APP]: 'data-app'
};

function webalize(text) {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-/g, '') // Trim dashes from the start
    .replace(/-$/g, ''); // Trim dashes from the end
}

const createWebalizedAppId = (appName, appType) => {
  if (!appName || !appType) {
    return '';
  }

  if (webalizedAppTypesMap[appType]) {
    return `${webalizedAppTypesMap[appType]}-${webalize(appName)}`;
  }

  return webalize(appName);
};

const setDefaultValuesOnCreation = (app) => {
  let defaultValues = app;

  if (
    [
      COMPONENT_TYPE_APPLICATION,
      COMPONENT_TYPE_EXTRACTOR,
      COMPONENT_TYPE_WRITER,
      COMPONENT_TYPE_TRANSFORMATION
    ].includes(defaultValues.type)
  ) {
    defaultValues = {
      ...defaultValues,
      uiOptions: ['genericDockerUI'],
      repository: {
        ...defaultValues.repository,
        type: APP_REPOSITORY_TYPE_ECR
      }
    };
  }

  if ([COMPONENT_TYPE_EXTRACTOR].includes(defaultValues.type)) {
    defaultValues = {
      ...defaultValues,
      defaultBucket: true,
      defaultBucketStage: 'in'
    };
  }

  if ([COMPONENT_TYPE_CODE_PATTERN].includes(defaultValues.type)) {
    defaultValues = {
      ...defaultValues,
      actions: ['generate']
    };
  }

  return defaultValues;
};

export {
  AVAILABLE_OPTIONS,
  AVAILABLE_STAGE_OPTIONS,
  createWebalizedAppId,
  setDefaultValuesOnCreation,
  componentTypes,
  COMPONENT_TYPE_DATA_APP,
  COMPONENT_TYPE_CODE_PATTERN,
  COMPONENT_TYPE_EXTRACTOR,
  COMPONENT_TYPE_WRITER
};
