import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import reactSelectStyles from '../../../styles/reactSelectStyles';

const SelectField = ({ value, onChangeFn, options }) => {
  return (
    <Select
      isClearable
      options={options}
      value={options.find((option) => option.value === value)}
      onChange={(selected) => {
        onChangeFn(selected ? selected.value : '');
      }}
      styles={reactSelectStyles}
    />
  );
};

SelectField.propTypes = {
  value: PropTypes.string.isRequired,
  onChangeFn: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default SelectField;
