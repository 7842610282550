import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { login, loginWithMfa } from '../actions/login';
import { cancelMfa } from '../actions/mfa';
import Head from '../../../components/Head';
import { getIsWaitingForMfa, getMfaChallenge } from '../reducers/mfa';
import { getIsAuthenticated } from '../reducers/tokens';
import LoginForm from '../components/LoginForm';
import MfaForm from '../components/MfaForm';
import LoginSuccess from '../components/LoginSuccess';

class Login extends Component {
  render() {
    const { isWaitingForMfa, isAuthenticated, loginWithMfaFn, cancelMfaFn, mfaChallenge } =
      this.props;

    if (isAuthenticated) {
      return (
        <div>
          <LoginSuccess />
        </div>
      );
    }

    if (isWaitingForMfa) {
      return (
        <div>
          <MfaForm loginFn={loginWithMfaFn} cancelFn={cancelMfaFn} mfaChallenge={mfaChallenge} />
        </div>
      );
    }

    return (
      <>
        <Head title="Sign in" />
        <LoginForm loginFn={this.props.loginFn} />
      </>
    );
  }
}

Login.propTypes = {
  loginFn: PropTypes.func.isRequired,
  loginWithMfaFn: PropTypes.func.isRequired,
  cancelMfaFn: PropTypes.func.isRequired,
  isWaitingForMfa: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  mfaChallenge: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    isWaitingForMfa: getIsWaitingForMfa(state),
    isAuthenticated: getIsAuthenticated(state),
    mfaChallenge: getMfaChallenge(state)
  };
};

export default connect(mapStateToProps, {
  loginFn: login,
  cancelMfaFn: cancelMfa,
  loginWithMfaFn: loginWithMfa
})(Login);
