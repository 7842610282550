import React from 'react';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import AppChangesDiffModal from './AppChangesDiffModal';
import { Icon } from '../../../../components/apps/detail';
import { COMPONENT_CHANGES_DAYS } from '../../../../constants';
import { getComponentTypeLabel } from '../../../../helpers';

class AppChangesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChangesDiffModal: false,
      changes: null
    };
    this.openChangesDiffModal = this.openChangesDiffModal.bind(this);
    this.closeChangesDiffModal = this.closeChangesDiffModal.bind(this);
  }

  openChangesDiffModal(changes) {
    this.setState({
      showChangesDiffModal: true,
      changes: changes
    });
  }

  closeChangesDiffModal() {
    this.setState({
      showChangesDiffModal: false,
      changes: null
    });
  }

  render() {
    const { changes, app } = this.props;
    return (
      <div>
        <h2 className="text-center">
          <Icon app={app} /> {app.name} <small>{getComponentTypeLabel(app.type)}</small>
        </h2>
        <p className="text-center">
          Showing component changes from last {COMPONENT_CHANGES_DAYS} days.
        </p>
        {this.state.changes && (
          <AppChangesDiffModal
            changes={this.state.changes}
            showModal={this.state.showChangesDiffModal}
            closeModalFn={this.closeChangesDiffModal}
          />
        )}
        <table className="table table-hover">
          <thead className="no-top-border">
            <tr>
              <th>Changed on</th>
              <th>Changed by</th>
              <th>Changed attributes</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {changes
              .filter((change) => {
                return change.changes.length > 0;
              })
              .map((change, index) => {
                return (
                  <tr key={index}>
                    <td>{format(parseISO(change.createdOn), 'dd/MM/yyyy, HH:mm')}</td>
                    <td>{change.createdBy}</td>
                    <td>
                      {change.changes.map((item, idx) => {
                        return (
                          <span key={idx}>
                            {item.attribute}
                            {idx < change.changes.length - 1 && <span>, </span>}
                          </span>
                        );
                      })}
                    </td>
                    <td>
                      <button
                        className="btn btn-link"
                        onClick={() => {
                          this.openChangesDiffModal(change.changes);
                        }}
                      >
                        Show diff
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AppChangesTable;
