import React from 'react';
import PropTypes from 'prop-types';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Button from 'react-bootstrap/lib/Button';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';

import AceEditor from 'react-ace';
import { config } from 'ace-builds';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-tomorrow';
const jsonWorkerUrl = new URL('ace-builds/src-noconflict/worker-json', import.meta.url);

import FormPreview from '../ConfigurationSchemaFormPreview';
import { DescriptionBlock } from '../custom';

config.setModuleUrl('ace/mode/json_worker', jsonWorkerUrl);

class JsonEditorField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openPreview: false
    };
  }

  render() {
    let initialValue;
    if (this.props.input.value === null) {
      initialValue = '';
    } else {
      try {
        initialValue =
          typeof this.props.input.value === 'object'
            ? JSON.stringify(this.props.input.value, null, '  ')
            : this.props.input.value;
      } catch (err) {
        initialValue = this.props.input.value;
      }
    }

    const controlId = `${this.props.formId}-${this.props.input.name}`;
    const showFormPreview = !!(
      this.props.formPreview &&
      this.props.input.value &&
      !this.props.meta.error
    );

    return (
      <>
        <FormGroup
          controlId={controlId}
          validationState={this.props.meta.touched && this.props.meta.error ? 'error' : null}
        >
          {showFormPreview ? (
            <Row>
              <Col sm={7}>
                <ControlLabel>{this.props.label}</ControlLabel>
              </Col>
              <Col sm={5} className="text-right">
                <Button
                  bsStyle="link"
                  className="btn-link-as-href"
                  onClick={() => this.setState({ openPreview: true })}
                >
                  Preview
                </Button>
              </Col>
            </Row>
          ) : (
            <ControlLabel>{this.props.label}</ControlLabel>
          )}
          <AceEditor
            mode="json"
            theme="tomorrow"
            onChange={(value) => {
              this.props.input.onChange(value);
            }}
            onBlur={(event, editor) => {
              if (!editor) {
                return;
              }

              const value = editor.getValue();
              const normalizedValue = this.props.normalizeOnBlur
                ? this.props.normalizeOnBlur(value)
                : value;
              this.props.input.onBlur(normalizedValue);
            }}
            name={`${controlId}-editor`}
            value={initialValue}
            editorProps={{ $blockScrolling: true }}
            fontSize={14}
            tabSize={2}
            width="100%"
            height="15em"
          />

          {this.props.meta.touched && this.props.meta.error && (
            <HelpBlock>{this.props.meta.error}</HelpBlock>
          )}
          {this.props.description && <DescriptionBlock>{this.props.description}</DescriptionBlock>}
        </FormGroup>
        {showFormPreview && (
          <FormPreview
            show={this.state.openPreview}
            onHide={() => this.setState({ openPreview: false })}
            schema={this.props.input.value}
          />
        )}
      </>
    );
  }
}

JsonEditorField.propTypes = {
  formId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  normalizeOnBlur: PropTypes.func,
  description: PropTypes.node,
  formPreview: PropTypes.bool
};

export default JsonEditorField;
