import React from 'react';
import createConnectedRouter from 'found/createConnectedRouter';
import createRender from 'found/createRender';
import resolver from 'found/resolver';
import { ScrollManager } from 'found-scroll';

import LayoutOpenSimple from '../layout/LayoutOpenSimple';
import InlineNotification from '../components/InlineNotification';

const renderErrorMessage = ({ status, data }) => {
  const errorMessage = data || '';
  const errorStatus = status || '';
  switch (status) {
    case 0:
      // network err
      return errorMessage;
    case 404:
      // not found errors (most common)
      return 'Page not found. ' + errorMessage;
    default:
      // other errors
      return errorStatus + ' ' + errorMessage + '. Contact us at support@keboola.com.';
  }
};

const render = createRender({
  renderError: ({ error }) => (
    <LayoutOpenSimple>
      <div className="container">
        <InlineNotification type="danger">{renderErrorMessage(error)}</InlineNotification>
      </div>
    </LayoutOpenSimple>
  )
});

const shouldUpdateScroll = (prevRenderArgs, { routes }) => {
  if (!routes) {
    return false;
  }
  return routes.some((route) => route.scrollToTop);
};

const ConnectedRouter = createConnectedRouter({
  render: (args) => {
    return (
      <ScrollManager renderArgs={args} shouldUpdateScroll={shouldUpdateScroll}>
        {render(args)}
      </ScrollManager>
    );
  }
});

export default ({ store }) => {
  return <ConnectedRouter resolver={resolver} matchContext={{ store }} />;
};
