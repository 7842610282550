import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import ReactBootstrapMenuItem from 'react-bootstrap/lib/MenuItem';

import Link from '../components/Link';
import { logout } from '../modules/auth/actions/logout';
import { routes } from '../routesConstants';
import Logo from '../components/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
  logoutFn: PropTypes.func.isRequired
};

class NavbarHomeSimple extends Component {
  render() {
    return (
      <Navbar>
        <Navbar.Header>
          <Navbar.Brand>
            <Link className="navbar-brand" to={routes.ROUTE_HOME}>
              <Logo />
            </Link>
          </Navbar.Brand>
        </Navbar.Header>
        <Nav pullRight>
          <NavDropdown title="Account" id="admin-navbar-nav-account-dropdown">
            <ReactBootstrapMenuItem onClick={this.props.logoutFn}>
              Logout <FontAwesomeIcon icon={'long-arrow-alt-left'} pull="right" />
            </ReactBootstrapMenuItem>
          </NavDropdown>
        </Nav>
      </Navbar>
    );
  }
}

NavbarHomeSimple.propTypes = propTypes;

export default connect(null, {
  logoutFn: logout
})(NavbarHomeSimple);
