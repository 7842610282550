import * as actionTypes from '../../actions/apps';
import { FLAG_BETA, FLAG_EXPERIMENTAL } from '../../../../constants';

export default (state = [], action) => {
  switch (action.type) {
    case actionTypes.OPEN_FETCH_APPS_SUCCESS:
      return action.response;
    default:
      return state;
  }
};

function appContains(app, value) {
  const propsToSearch = ['id', 'name', 'shortDescription'];
  return propsToSearch.reduce((memo, prop) => {
    return memo || (app[prop] && app[prop].toLowerCase().indexOf(value.toLowerCase()) >= 0);
  }, false);
}

export const getVisibleApps = (state, filter) => {
  let apps = state.filter((a) => a.type && a.type !== 'other');
  if (filter.type) {
    apps = apps.filter((app) => app.type === filter.type);
  }

  if (filter.vendor) {
    apps = apps.filter((app) => app.vendor.type === filter.vendor);
  }

  if (filter.query) {
    const { query } = filter;
    apps = apps.filter((app) => appContains(app, query));
  }

  if (filter.stage) {
    const { stage } = filter;
    apps = apps.filter((app) => {
      if (stage === 'beta') {
        return app.uiOptions.includes(FLAG_BETA);
      }

      if (stage === 'experimental') {
        return app.uiOptions.includes(FLAG_EXPERIMENTAL);
      }
    });
  }

  return apps;
};

export const hasApps = (state) => {
  return state.length > 0;
};
