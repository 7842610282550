import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
  return (
    <div className="not-supported-browser">
      <div className="not-supported-browser-wrapper">
        <div className="margin-vertical-4em">
          <p>
            <FontAwesomeIcon icon={['far', 'meh']} size="3x" />
          </p>
          <p className="font-size-1-5em">
            Your browser is too old and not supported by this application.
          </p>
          <p>
            To use the Developer Portal, you'll need to use a more modern web browser like Google
            Chrome, Mozilla Firefox, Safari, Opera or Microsoft Edge.
          </p>
        </div>
      </div>
    </div>
  );
};
