import { SubmissionError } from 'redux-form';
import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';

export const HOME_JOIN_TO_VENDOR_REQUEST = 'HOME_JOIN_TO_VENDOR_REQUEST';
export const HOME_JOIN_TO_VENDOR_SUCCESS = 'HOME_JOIN_TO_VENDOR_SUCCESS';
export const HOME_JOIN_TO_VENDOR_FAILURE = 'HOME_JOIN_TO_VENDOR_FAILURE';

export const requestToJoinVendorRequest = () => {
  return {
    type: HOME_JOIN_TO_VENDOR_REQUEST
  };
};

export const requestToJoinVendorSuccess = () => {
  return {
    type: HOME_JOIN_TO_VENDOR_SUCCESS
  };
};

export const requestToJoinVendorFailure = () => ({
  type: HOME_JOIN_TO_VENDOR_FAILURE
});

export const selectVendor =
  ({ vendorId }) =>
  (dispatch, getState) => {
    dispatch(requestToJoinVendorRequest());

    return dispatch(
      fetchProxyWithTokenRefresh('/vendors/' + vendorId + '/users', {
        method: 'POST',
        headers: {
          Authorization: getToken(getState())
        }
      })
    ).then((response) => {
      if (response.ok) {
        dispatch(requestToJoinVendorSuccess());
      } else {
        dispatch(requestToJoinVendorFailure());
        return response.json().then((error) => {
          throw new SubmissionError({
            _error: error.message || error.errorMessage || 'Could not join selected vendor.'
          });
        });
      }
    });
  };

export const HOME_CREATE_NEW_VENDOR_REQUEST = 'HOME_CREATE_NEW_VENDOR_REQUEST';
export const HOME_CREATE_NEW_VENDOR_SUCCESS = 'HOME_CREATE_NEW_VENDOR_SUCCESS';
export const HOME_CREATE_NEW_VENDOR_FAILURE = 'HOME_CREATE_NEW_VENDOR_FAILURE';

export const requestToCreateVendorRequest = () => {
  return {
    type: HOME_CREATE_NEW_VENDOR_REQUEST
  };
};

export const requestToCreateVendorSuccess = () => {
  return {
    type: HOME_CREATE_NEW_VENDOR_SUCCESS
  };
};

export const requestToCreateVendorFailure = (errorMessage) => ({
  type: HOME_CREATE_NEW_VENDOR_FAILURE,
  errorMessage
});

export const createVendor = (values) => (dispatch, getState) => {
  dispatch(requestToCreateVendorRequest());

  return dispatch(
    fetchProxyWithTokenRefresh('/vendors', {
      method: 'POST',
      body: JSON.stringify(values),
      headers: {
        Authorization: getToken(getState())
      }
    })
  ).then(
    (response) => {
      if (response.ok) {
        dispatch(requestToCreateVendorSuccess());
      } else {
        dispatch(requestToCreateVendorFailure());
        return response.json().then((error) => {
          throw new SubmissionError({
            _error: error.message || error.errorMessage || 'Could not create vendor.'
          });
        });
      }
    },
    (error) => {
      // todo: network err, global reducer?
    }
  );
};
