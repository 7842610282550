import * as EditAppForm from '../../home/models/EditAppForm';
import { filterObject } from '../../../utils/helpers';

const allowedProperties = EditAppForm.allowedProperties.concat([
  'isPublic',
  'isDeprecated',
  'forwardToken',
  'forwardTokenDetails',
  'requiredMemory',
  'processTimeout',
  'permissions',
  'features',
  'complexity',
  'categories'
]);

const filterNotAllowedFields = (values) => {
  return filterObject(allowedProperties, values);
};

const adminConstraints = {
  ...EditAppForm.constraints,
  requiredMemory: {
    memoryLimit: true
  },
  processTimeout: {
    numericality: true
  },
  permissions: {
    array: true
  },
  features: {
    array: true
  }
};

const validate = (app) => {
  return EditAppForm.validateWithConstraints(app, adminConstraints);
};

export { filterNotAllowedFields, validate };
