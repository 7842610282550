import * as actionTypes from '../actions/developers';
import { combineReducers } from 'redux';
import { getVendors } from '../reducers/vendors';

export const PENDING_VENDORS_OPTION = '...';

const usersInitialState = {
  data: [],
  apiFilter: ''
};
const users = (state = usersInitialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_FETCH_ALL_USERS_SUCCESS:
      return { ...state, data: action.data, apiFilter: action.apiFilter || '' };
    default:
      return state;
  }
};

const filter = (state = '', action) => {
  if (action.type === actionTypes.ADMIN_ALL_USERS_SET_FILTER) {
    return action.filter;
  }
  return state;
};

function userContains(user, value) {
  const propsToSearch = ['username', 'name'];
  return propsToSearch.reduce(
    (memo, prop) => memo || user[prop].toLowerCase().indexOf(value.toLowerCase()) >= 0,
    false
  );
}

const vendorFilter = (state = '', action) => {
  if (action.type === actionTypes.ADMIN_ALL_USERS_SET_VENDOR_FILTER) {
    return action.vendor;
  }
  return state;
};

const statusFilter = (state = '', action) => {
  if (action.type === actionTypes.ADMIN_ALL_USERS_SET_STATUS_FILTER) {
    return action.status;
  }
  return state;
};

const manageVendorsModal = (state = { show: false, user: {} }, action) => {
  if (action.type === actionTypes.ADMIN_ALL_USERS_MANAGE_VENDORS) {
    return { ...state, show: action.show, user: action.user };
  }
  return state;
};

const localizeState = (state) => state.admin.developers;

export const getAllUsers = (state) => localizeState(state).users.data;
export const getFilter = (state) => localizeState(state).filter;
export const getVendorFilter = (state) => localizeState(state).vendorFilter;
export const getStatusFilter = (state) => localizeState(state).statusFilter;

const hasPendingVendors = (allVendorsIds, userVendors) =>
  userVendors.find((v) => !allVendorsIds.includes(v));

// sort as follows: disabled users last then unconfirmed users last,
// rest sort by name alphabetically
const sortUsers = (users) => {
  return users.sort((a, b) => {
    if (a.isEnabled === b.isEnabled) {
      if (a.status === b.status) {
        return a.name.toLowerCase().codePointAt() - b.name.toLowerCase().codePointAt();
      } else {
        return b.status.codePointAt() - a.status.codePointAt();
      }
    } else {
      return a.isEnabled ? -1 : 1;
    }
  });
};
export const getAllUsersFiltered = (state) => {
  const filter = getFilter(state);
  const vendorFilter = getVendorFilter(state);
  const allVendorsIds = getVendors(state).map((v) => v.id);
  const filterPendingVendors = vendorFilter === PENDING_VENDORS_OPTION;
  const statusFilter = getStatusFilter(state);
  const nonServiceUsers = getAllUsers(state);
  const filterDisabledUsers = statusFilter === 'Disabled';
  const statusUsers = nonServiceUsers.filter(
    (u) =>
      !statusFilter ||
      (filterDisabledUsers ? !u.isEnabled : u.status.toLowerCase() === statusFilter.toLowerCase())
  );
  const vendorUsers = statusUsers.filter(
    (u) =>
      !vendorFilter ||
      (filterPendingVendors
        ? hasPendingVendors(allVendorsIds, u.vendors)
        : u.vendors.includes(vendorFilter))
  );
  return sortUsers(vendorUsers.filter((u) => userContains(u, filter)));
};

export const getApiFilter = (state) => localizeState(state).users.apiFilter;

export const getManageUserVendors = (state) => localizeState(state).manageVendorsModal;

export default combineReducers({ users, filter, vendorFilter, statusFilter, manageVendorsModal });
