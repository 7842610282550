import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';

import { DescriptionBlock } from '../custom';

const ToggleButtonGroupRadioField = ({
  formId,
  input,
  label,
  data,
  nameField,
  valueField,
  description
}) => {
  return (
    <FormGroup controlId={`${formId}-${input.name}`}>
      <span className="input-label">{label}</span>
      <br />
      <ToggleButtonGroup {...input} type="radio">
        {data.map((item, index) => {
          return (
            <ToggleButton key={index} value={item[valueField]}>
              {item[nameField]}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      {description && <DescriptionBlock>{description}</DescriptionBlock>}
    </FormGroup>
  );
};
ToggleButtonGroupRadioField.propTypes = {
  formId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  nameField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  description: PropTypes.node
};

export default ToggleButtonGroupRadioField;
