import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import reactSelectStyles from '../../../../styles/reactSelectStyles';
import { DescriptionBlock } from '../custom';

const SelectField = ({
  formId,
  input,
  label,
  placeholder,
  description,
  data,
  nameField,
  valueField,
  meta: { touched, error }
}) => {
  const options = data.map((option) => {
    return { value: option[valueField], label: option[nameField] };
  });

  return (
    <FormGroup
      controlId={`${formId}-${input.name}`}
      validationState={touched && error ? 'error' : null}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <Select
        {...input}
        options={options}
        placeholder={placeholder}
        value={options.find((option) => option.value === input.value)}
        onChange={(selected) => input.onChange(selected.value)}
        onBlur={() => input.onBlur(input.value)}
        styles={reactSelectStyles}
      />
      {touched && error && <HelpBlock>{error}</HelpBlock>}
      {description && <DescriptionBlock>{description}</DescriptionBlock>}
    </FormGroup>
  );
};
SelectField.propTypes = {
  formId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  nameField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.node
};

export default SelectField;
