import validateJs from '../../../utils/validate';

const constraints = {
  name: {
    presence: { allowEmpty: false }
  },
  email: {
    presence: { allowEmpty: false },
    email: true
  },
  password: {
    presence: { allowEmpty: false },
    format: {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}/,
      message:
        'must contain at least 8 characters and at least one lowercase letter, one uppercase letter and one number.'
    }
  },
  confirmPassword: {
    presence: { allowEmpty: false },
    equality: 'password'
  }
};

export const validate = (values) => {
  let errors = {};
  const result = validateJs.validate(values, constraints);
  if (result) {
    Object.keys(result).forEach((key) => {
      errors[key] = result[key][0];
    });
  }
  return errors;
};
