import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppsTable from '../components/apps/AppsTable';
import { getFilteredApps } from '../reducers/apps/index';
import { getDeletionError, getIsDeleting } from '../reducers/apps/appDelete';
import { getFilter } from '../reducers/apps/filter';
import { setFilterQuery, setFilterStatus } from '../actions/apps';
import { deleteApp } from '../actions/appDelete';
import { approveApp } from '../actions/appApprove';
import { rejectApp } from '../actions/appReject';

class Apps extends Component {
  render() {
    const {
      apps,
      filter,
      setFilterQueryFn,
      setFilterStatusFn,
      deletionError,
      isDeleting,
      deleteAppFn,
      approveAppFn,
      rejectAppFn
    } = this.props;
    return (
      <div className="container">
        <AppsTable
          apps={apps}
          filter={filter}
          setFilterQueryFn={setFilterQueryFn}
          setFilterStatusFn={setFilterStatusFn}
          deletionError={deletionError}
          isDeleting={isDeleting}
          deleteAppFn={deleteAppFn}
          approveAppFn={approveAppFn}
          rejectAppFn={rejectAppFn}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    apps: getFilteredApps(state),
    filter: getFilter(state),
    isDeleting: getIsDeleting(state),
    deletionError: getDeletionError(state)
  };
};

export default connect(mapStateToProps, {
  setFilterQueryFn: setFilterQuery,
  setFilterStatusFn: setFilterStatus,
  deleteAppFn: deleteApp,
  approveAppFn: approveApp,
  rejectAppFn: rejectApp
})(Apps);
