import { SubmissionError } from 'redux-form';
import { fetchProxy } from '../../actions';

export const AUTH_CREATE_ACCOUNT_REQUEST = 'AUTH_CREATE_ACCOUNT_REQUEST';
export const AUTH_CREATE_ACCOUNT_SUCCESS = 'AUTH_CREATE_ACCOUNT_SUCCESS';
export const AUTH_CREATE_ACCOUNT_FAILURE = 'AUTH_CREATE_ACCOUNT_FAILURE';

export const createAccountRequest = () => ({ type: AUTH_CREATE_ACCOUNT_REQUEST });
export const createAccountSuccess = (email) => ({
  type: AUTH_CREATE_ACCOUNT_SUCCESS,
  email: email
});
export const createAccountFailure = (err) => ({
  type: AUTH_CREATE_ACCOUNT_FAILURE,
  errorMessage: err
});

export const createAccount = (account) => (dispatch) => {
  const { name, email, password } = account;
  const body = { name, email, password };
  dispatch(createAccountRequest());
  return dispatch(
    fetchProxy('/auth/signup', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' }
    })
  ).then((response) => {
    if (response.ok) {
      return dispatch(createAccountSuccess(email));
    } else {
      return response.json().then((data) => {
        dispatch(createAccountFailure(data.errorMessage || 'There was a problen'));
        throw new SubmissionError({ _error: data.errorMessage || 'There was a problem' });
      });
    }
  });
};
