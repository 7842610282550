import React from 'react';
import PropTypes from 'prop-types';
import ButtonToolbar from 'react-bootstrap/lib/ButtonToolbar';

import {
  Icon,
  Vendor,
  ShortDescription,
  LongDescription,
  HomeFlags,
  PublicFlags,
  RequestApprovalButton
} from './detail';
import Link from '../Link';
import { Link as ExternalLink } from '../externalLinks';
import { routes } from '../../routesConstants';
import { createKbcComponentUrl } from '../../utils/helpers';

const renderFlags = (module, app) => {
  switch (module) {
    case 'admin':
    case 'home':
      return <HomeFlags app={app} />;
    default:
      return <PublicFlags app={app} />;
  }
};

const AppDetailBox = ({ module, app, vendor }) => {
  const editAppLink =
    module === 'admin'
      ? `${routes.ROUTE_ADMIN_COMPONENTS}/${app.id}/edit`
      : `${routes.ROUTE_HOME_COMPONENTS}/${app.id}/edit`;
  const kbcComponentUrl =
    module === 'home' && vendor.projectUrl
      ? createKbcComponentUrl(vendor.projectUrl, app.type, app.id)
      : null;
  return (
    <div>
      <h1 className="text-center">{app.name}</h1>
      <ShortDescription app={app} />
      <div className="app-detail-header">
        <div className="image-box">
          <div className="wrapper">
            <Icon app={app} />
          </div>
        </div>
        <div className="flags-box flags-box-view-mode font-size-smaller">
          <div className="wrapper">
            {renderFlags(module, app)}
            {(module === 'admin' || module === 'home') &&
              (!app.repository || !app.repository.uri) && (
                <div className="alert alert-info text-center">
                  This component is not deployed properly (or has not been deployed yet) and cannot
                  be used in Keboola Connection.{' '}
                  <ExternalLink href="https://developers.keboola.com/extend/component/deployment/">
                    Read more
                  </ExternalLink>{' '}
                  about Component Deployment.
                </div>
              )}
          </div>
        </div>
        <div className="vendor-box vendor-box-view-mode font-size-smaller">
          <div className="wrapper">
            <Vendor app={app} />
          </div>
        </div>
      </div>
      {(module === 'admin' || module === 'home') && (
        <ButtonToolbar className="text-center margin-top-4em">
          <Link to={editAppLink} className="btn btn-primary btn-lg">
            Edit
          </Link>
          <RequestApprovalButton app={app} />
        </ButtonToolbar>
      )}
      {kbcComponentUrl && (
        <div className="text-center margin-top-2em">
          <ExternalLink href={kbcComponentUrl} className="btn btn-link btn-lg">
            Open in a test project
          </ExternalLink>
        </div>
      )}
      <div className="row margin-top-4em">
        <div className="col-md-6 col-md-push-3">
          <LongDescription description={app.longDescription} />
        </div>
      </div>
    </div>
  );
};

AppDetailBox.propTypes = {
  module: PropTypes.string.isRequired,
  app: PropTypes.object.isRequired,
  vendor: PropTypes.object
};

export default AppDetailBox;
