import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { JSONEditor } from '@json-editor/json-editor';
import Modal from 'react-bootstrap/lib/Modal';
import Alert from 'react-bootstrap/lib/Alert';
import Ajv from 'ajv';
import draft from 'ajv/lib/refs/json-schema-draft-04.json';

window.DOMPurify = DOMPurify; // to allow HTML format in titles/headers and descriptions

const ajv = new Ajv({
  schemaId: 'id',
  allErrors: true,
  unknownFormats: ['password']
});

ajv.addMetaSchema(draft);

const INITIAL_STATE = {
  error: null
};

class ConfigurationSchemaPreview extends Component {
  constructor(props) {
    super(props);

    this.jsonEditor = null;
    this.jsonEditorRef = null;

    this.state = INITIAL_STATE;

    this.initEditor = this.initEditor.bind(this);
  }

  render() {
    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={this.initEditor}
        onExited={() => {
          this.setState(INITIAL_STATE);
          this.destroyEditor();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Form preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-preview">
          {this.renderError()}
          <div ref={(node) => (this.jsonEditorRef = node)} />
        </Modal.Body>
      </Modal>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Alert bsStyle="danger">
        <strong>Schema is not valid</strong>
        <p>{this.state.error}</p>
      </Alert>
    );
  }

  initEditor() {
    if (typeof this.props.schema === 'string') {
      return this.setState({ error: 'value is not valid JSON' });
    } else if (JSON.stringify(this.props.schema) === '{}') {
      return this.setState({ error: 'empty object' });
    }

    try {
      ajv.validateSchema({
        $schema: 'http://json-schema.org/draft-04/schema#',
        ...this.props.schema
      });

      const error = this.getError();

      if (error) {
        let message = `${error.dataPath} - ${error.message}`;

        if (error.params.allowedValues) {
          message += ` (${error.params.allowedValues.join(', ')})`;
        }

        return this.setState({ error: message });
      }

      this.jsonEditor = new JSONEditor(this.jsonEditorRef, {
        theme: 'bootstrap3',
        schema: this.props.schema,
        disable_array_delete_all_rows: true,
        disable_array_delete_last_row: true,
        disable_array_reorder: true,
        disable_collapse: true,
        disable_edit_json: true,
        disable_properties: true,
        prompt_before_delete: false
      });
    } catch (error) {
      this.setState({ error: 'Provided configuration schema is not valid' });
      this.destroyEditor();
    }
  }

  destroyEditor() {
    if (this.jsonEditor) {
      this.jsonEditor.destroy();
      this.jsonEditor = null;
    }
  }

  getError() {
    if (!ajv.errors) {
      return null;
    }

    return ajv.errors.filter((error) => !this.isRequiredShouldBeArrayError(error))[0];
  }

  isRequiredShouldBeArrayError(error) {
    return error.message === 'should be array' && error.dataPath.endsWith('.required');
  }
}

ConfigurationSchemaPreview.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  schema: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default ConfigurationSchemaPreview;
