import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VendorRequested = ({ logoutFn }) => {
  return (
    <div className="static-modal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Sent to confirmation</h4>
          </div>
          <div className="modal-body text-center">
            <p className="text-success">
              <FontAwesomeIcon icon={'check'} size="3x" />
            </p>
            <div className="margin-vertical-3em">
              <p>
                Please wait until an administrator confirms your request. You'll receive an email.
              </p>
            </div>
          </div>
          <div className="modal-footer modal-footer-center">
            <button onClick={logoutFn} className="btn btn-primary btn-lg">
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
VendorRequested.propTypes = {
  logoutFn: PropTypes.func.isRequired
};

export default VendorRequested;
