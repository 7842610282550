import React from 'react';
import PropTypes from 'prop-types';

import { AVAILABLE_OPTIONS, AVAILABLE_STAGE_OPTIONS } from '../../../home/models/App';
import SearchInput from '../../../../common/form/SearchInput';
import Select from 'react-select';
import reactSelectStyles from '../../../../../styles/reactSelectStyles';

const FilterBar = ({
  filter,
  setFilterQueryFn,
  setFilterVendorFn,
  setFilterTypeFn,
  setFilterStageFn
}) => {
  const filterTypeOptions = [{ name: 'All Types', value: null }]
    .concat(AVAILABLE_OPTIONS.type)
    .map((item) => ({ label: item.name, value: item.value }));

  const filterStageOptions = [{ name: 'All Stages', value: null }]
    .concat(AVAILABLE_STAGE_OPTIONS)
    .map((item) => ({ label: item.name, value: item.value }));

  return (
    <div className="open-apps-filter">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="filters">
          <SearchInput
            value={filter.query}
            onChangeFn={setFilterQueryFn}
            placeholder="Search by name, author, etc."
          />

          <div className="form-group">
            <Select
              options={filterTypeOptions}
              value={filterTypeOptions.find((option) => option.value === filter.type)}
              onChange={(selected) => {
                setFilterTypeFn(selected ? selected.value : null);
              }}
              styles={reactSelectStyles}
            />
          </div>

          <div className="form-group">
            <Select
              options={filterStageOptions}
              value={filterStageOptions.find((option) => option.value === filter.stage)}
              onChange={(selected) => {
                setFilterStageFn(selected ? selected.value : null);
              }}
              styles={reactSelectStyles}
            />
          </div>
        </div>

        <div className="checkbox">
          <label>
            <input
              checked={filter.vendor === 'keboola'}
              onChange={(e) =>
                e.target.checked ? setFilterVendorFn('keboola') : setFilterVendorFn(null)
              }
              type="checkbox"
            />{' '}
            Supported by Keboola
          </label>
        </div>
      </form>
    </div>
  );
};

FilterBar.propTypes = {
  filter: PropTypes.object.isRequired,
  setFilterQueryFn: PropTypes.func.isRequired,
  setFilterVendorFn: PropTypes.func.isRequired,
  setFilterTypeFn: PropTypes.func.isRequired
};

export default FilterBar;
