import * as appsSelectorsList from '../reducers/apps/list';
import * as appsSelectorsDetails from '../reducers/apps/details';
import { fetchProxy } from '../../actions';

export const OPEN_APPS_SET_FILTER_TYPE = 'OPEN_APPS_SET_FILTER_TYPE';
export const OPEN_APPS_SET_FILTER_STAGE = 'OPEN_APPS_SET_FILTER_STAGE';
export const OPEN_APPS_SET_FILTER_VENDOR = 'OPEN_APPS_SET_FILTER_VENDOR';
export const OPEN_APPS_SET_FILTER_QUERY = 'OPEN_APPS_SET_FILTER_QUERY';

export const OPEN_FETCH_APP_DETAIL_SUCCESS = 'OPEN_FETCH_APP_DETAIL_SUCCESS';
export const OPEN_FETCH_APP_DETAIL_REQUEST = 'OPEN_FETCH_APP_DETAIL_REQUEST';
export const OPEN_FETCH_APP_DETAIL_FAILURE = 'OPEN_FETCH_APP_DETAIL_FAILURE';

export const OPEN_FETCH_APPS_SUCCESS = 'OPEN_FETCH_APPS_SUCCESS';
export const OPEN_FETCH_APPS_REQUEST = 'OPEN_FETCH_APPS_REQUEST';
export const OPEN_FETCH_APPS_FAILURE = 'OPEN_FETCH_APPS_FAILURE';

const appsIndexRequest = () => {
  return {
    type: OPEN_FETCH_APPS_REQUEST
  };
};

const appsIndexFailure = () => {
  return {
    type: OPEN_FETCH_APPS_FAILURE
  };
};

const receiveApps = (response) => ({
  type: OPEN_FETCH_APPS_SUCCESS,
  response: response
});

const appDetailRequest = () => {
  return {
    type: OPEN_FETCH_APP_DETAIL_REQUEST
  };
};

const appDetailFailure = () => {
  return {
    type: OPEN_FETCH_APP_DETAIL_FAILURE
  };
};

const receiveAppDetail = (response, appId) => {
  return {
    type: OPEN_FETCH_APP_DETAIL_SUCCESS,
    response,
    appId
  };
};

export const setFilterStage = (stage) => {
  return {
    type: OPEN_APPS_SET_FILTER_STAGE,
    payload: {
      stage
    }
  };
};

export const setFilterType = (type) => {
  return {
    type: OPEN_APPS_SET_FILTER_TYPE,
    payload: {
      type
    }
  };
};

export const setFilterVendor = (vendorId) => {
  return {
    type: OPEN_APPS_SET_FILTER_VENDOR,
    payload: {
      vendorId
    }
  };
};

export const setFilterQuery = (query) => {
  return {
    type: OPEN_APPS_SET_FILTER_QUERY,
    payload: {
      query
    }
  };
};
export const fetchAppDetail = (appId) => (dispatch, getState) => {
  if (appsSelectorsDetails.hasAppDetail(getState().open.apps.details, appId)) {
    return Promise.resolve('ok');
  }
  dispatch(appDetailRequest());
  return dispatch(fetchProxy(`/apps/${appId}`)).then((response) => {
    return response.json().then((data) => {
      if (!response.ok) {
        dispatch(appDetailFailure());
        return Promise.reject({
          status: response.status,
          message: data.errorMessage || 'Could not fetch component detail.'
        });
      }
      dispatch(receiveAppDetail(data, appId));
      return 'ok';
    });
  });
};

export const fetchApps = () => (dispatch, getState) => {
  if (appsSelectorsList.hasApps(getState().open.apps.list)) {
    return Promise.resolve('ok');
  }
  dispatch(appsIndexRequest());
  return dispatch(fetchProxy('/apps')).then((response) => {
    return response.json().then((data) => {
      if (!response.ok) {
        dispatch(appsIndexFailure());
        return Promise.reject({
          status: response.status,
          message: data.errorMessage || 'Could not fetch apps.'
        });
      }
      dispatch(receiveApps(data));
      return 'ok';
    });
  });
};
