import React from 'react';
import PropTypes from 'prop-types';
import ReactBootstrapNavItem from 'react-bootstrap/lib/NavItem';
import Link from './Link';

const NavItem = ({ children, href, componentClass, ...rest }) => {
  return (
    <ReactBootstrapNavItem to={href} href={href} componentClass={componentClass || Link} {...rest}>
      {children}
    </ReactBootstrapNavItem>
  );
};

NavItem.propTypes = {
  href: PropTypes.string.isRequired
};

export default NavItem;
