import * as actionTypes from '../../actions/apps';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HOME_DELETE_APP_REQUEST:
      return { ...state, [action.appId]: { deleting: true } };
    case actionTypes.HOME_DELETE_APP_SUCCESS:
      return { ...state, [action.appId]: { deleting: false } };
    case actionTypes.HOME_DELETE_APP_FAILURE:
      return { ...state, [action.appId]: { deleting: false, errorMessage: action.errorMessage } };
    default:
      return state;
  }
};

const localizeState = (state) => state.home.apps.deleteApp;

const deletingApp = (state, appId) => localizeState(state)[appId] || {};

export const isDeletingApp = (state) => (appId) => deletingApp(state, appId).deleting || false;
export const getDeleteErrorMessage = (state) => (appId) => deletingApp(state, appId).errorMessage;
