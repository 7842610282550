import React from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

class AppStatus extends React.Component {
  render() {
    const { isPublic, isDeprecated, expiredOn } = this.props;
    if (isPublic) {
      return (
        <div>
          <strong className="text-success">Public</strong>
        </div>
      );
    } else if (isDeprecated) {
      return (
        <div>
          <strong className="text-danger">Deprecated</strong>
          {expiredOn && (
            <React.Fragment>
              <br />
              {format(parseISO(expiredOn), 'dd/MM/yyyy')}
            </React.Fragment>
          )}
        </div>
      );
    } else {
      return <div>Private</div>;
    }
  }
}

AppStatus.propTypes = {
  isPublic: PropTypes.bool.isRequired,
  isDeprecated: PropTypes.bool.isRequired,
  expiredOn: PropTypes.string
};

export default AppStatus;
