export const routeParts = {
  // app modules
  HOME: '/~', // root path for logged in users
  ADMIN: '/admin', // root path for logged in users as admins
  AUTH: '/auth', // auth

  // sections
  DEVELOPERS: '/developers',
  SERVICE_ACCOUNTS: '/service-accounts',
  PROFILE: '/profile',
  COMPONENTS: '/components',
  VENDORS: '/vendors',
  RESOURCES: '/resources',
  CONTACT: '/contact',

  // parts
  NEW: '/new',
  EDIT: '/edit',
  CHANGES: '/changes'
};

export const routes = {
  // home routes
  ROUTE_HOME: routeParts.HOME,
  ROUTE_HOME_DEVELOPERS: routeParts.HOME + routeParts.DEVELOPERS,
  ROUTE_HOME_SERVICE_ACCOUNTS: routeParts.HOME + routeParts.SERVICE_ACCOUNTS,
  ROUTE_HOME_PROFILE: routeParts.HOME + routeParts.PROFILE,
  ROUTE_HOME_COMPONENTS: routeParts.HOME + routeParts.COMPONENTS,
  ROUTE_HOME_COMPONENTS_NEW: routeParts.HOME + routeParts.COMPONENTS + routeParts.NEW,

  // admin routes
  ROUTE_ADMIN: routeParts.ADMIN,
  ROUTE_ADMIN_DEVELOPERS: routeParts.ADMIN + routeParts.DEVELOPERS,
  ROUTE_ADMIN_VENDORS: routeParts.ADMIN + routeParts.VENDORS,
  ROUTE_ADMIN_PROFILE: routeParts.ADMIN + routeParts.PROFILE,
  ROUTE_ADMIN_COMPONENTS: routeParts.ADMIN + routeParts.COMPONENTS,
  ROUTE_ADMIN_COMPONENTS_NEW: routeParts.ADMIN + routeParts.COMPONENTS + routeParts.NEW,

  // auth routes
  ROUTE_AUTH_CREATE_ACCOUNT: routeParts.AUTH + '/create-account',
  ROUTE_AUTH_LOGIN: routeParts.AUTH + '/login',
  ROUTE_AUTH_RESET_PASSWORD: routeParts.AUTH + '/reset-password',

  // open routes
  ROUTE_OPEN_COMPONENTS: routeParts.COMPONENTS,
  ROUTE_OPEN_RESOURCES: routeParts.RESOURCES,
  ROUTE_OPEN_CONTACT: routeParts.CONTACT
};
