import FarceActions from 'farce/Actions';

import { routes } from '../../../routesConstants';
import config from '../../../../config';
import { getToken } from '../reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';
import { hasFetchedProfile } from '../reducers/profile';

export const AUTH_USER_SELECT_VENDOR = 'AUTH_USER_SELECT_VENDOR';

const selectCurrentVendor = (newVendor) => {
  return {
    type: AUTH_USER_SELECT_VENDOR,
    vendor: newVendor
  };
};

const redirectAfterVendorSwitch = () => {
  return FarceActions.push(config.BASE_PATH + routes.ROUTE_HOME);
};

export const switchVendorAndRedirect = (vendorId) => (dispatch, getState) => {
  dispatch(selectCurrentVendor(vendorId));
  dispatch(redirectAfterVendorSwitch());
};

export const AUTH_FETCH_PROFILE_REQUEST = 'AUTH_FETCH_PROFILE_REQUEST';
export const AUTH_FETCH_PROFILE_SUCCESS = 'AUTH_FETCH_PROFILE_SUCCESS';
export const AUTH_FETCH_PROFILE_FAILURE = 'AUTH_FETCH_PROFILE_FAILURE';

const fetchProfileRequest = () => ({
  type: AUTH_FETCH_PROFILE_REQUEST
});

export const fetchProfileSuccess = (data) => ({
  type: AUTH_FETCH_PROFILE_SUCCESS,
  payload: {
    data: data
  }
});

const fetchProfileFailure = () => ({
  type: AUTH_FETCH_PROFILE_FAILURE
});

export const fetchProfileLazy = () => (dispatch, getState) => {
  if (hasFetchedProfile(getState())) {
    return Promise.resolve();
  }
  return dispatch(fetchProfile());
};

export const fetchProfile = () => (dispatch, getState) => {
  dispatch(fetchProfileRequest());

  const params = {
    method: 'GET',
    headers: {
      Authorization: getToken(getState())
    }
  };

  return dispatch(fetchProxyWithTokenRefresh(`/auth/profile`, params)).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        dispatch(fetchProfileSuccess(data));
        return 'ok';
      } else {
        dispatch(fetchProfileFailure());
        return Promise.reject({
          status: response.status,
          message: data.errorMessage || 'Could not get user profile'
        });
      }
    });
  });
};
