import React, { Component } from 'react';
import { connect } from 'react-redux';

import { generateServiceUser, removeUser } from '../actions/vendors';
import { setFilterQuery } from '../actions/serviceAccounts';
import { getCurrentVendor, getProfile } from '../../auth/reducers/profile';
import ServiceAccountsTable from '../components/ServiceAccountsTable';
import { getServiceAccountsFiltered, getServiceAccounts } from '../reducers/serviceAccounts/list';
import { getFilterQuery } from '../reducers/serviceAccounts/filter';

class ServiceAccounts extends Component {
  render() {
    const {
      vendorId,
      filterQuery,
      generateServiceUserFn,
      serviceAccounts,
      serviceAccountsFiltered,
      isRemovingUser,
      setFilterQuery,
      removeUser
    } = this.props;

    return (
      <div className="container">
        <ServiceAccountsTable
          vendor={vendorId}
          filter={filterQuery}
          onChangeFilterFn={setFilterQuery}
          generateServiceUserFn={generateServiceUserFn}
          serviceAccounts={serviceAccounts}
          serviceAccountsFiltered={serviceAccountsFiltered}
          removeUser={removeUser}
          isRemovingUser={isRemovingUser}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const vendor = getCurrentVendor(state);

  return {
    vendorId: vendor,
    serviceAccountsFiltered: getServiceAccountsFiltered(state, vendor),
    serviceAccounts: getServiceAccounts(state, vendor),
    filterQuery: getFilterQuery(state),
    profile: getProfile(state)
  };
};

export default connect(mapStateToProps, {
  removeUser,
  generateServiceUserFn: generateServiceUser,
  setFilterQuery
})(ServiceAccounts);
