import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import Link from '../../../components/Link';
import { TextField } from '../../../common/form/fields';
import { validate } from '../models/MfaForm';
import InlineNotification from '../../../components/InlineNotification';
import { mfaChallengeType } from '../../../constants';

const FORM_ID = 'MfaForm';

class MfaForm extends Component {
  handleCancel() {
    this.props.cancelFn();
  }

  render() {
    const { loginFn, handleSubmit, error, submitting, pristine } = this.props;
    return (
      <div className="static-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <Link to="/" className="close">
                <span>×</span>
                <span className="sr-only">Close</span>
              </Link>
              <h4 className="modal-title">Multi-factor authentication</h4>
            </div>
            <form onSubmit={handleSubmit(loginFn)}>
              <div className="modal-body">
                <div className="text-center">{this.renderMfaChallenge()}</div>
                <Field
                  formId={FORM_ID}
                  name="code"
                  component={TextField}
                  label="MFA Code"
                  placeholder="Enter the MFA code"
                  autoFocus
                />
                {error && <InlineNotification type="danger">{error}</InlineNotification>}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-link" onClick={() => this.handleCancel()}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={pristine || submitting}
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  renderMfaChallenge() {
    const { mfaChallenge } = this.props;

    if (mfaChallenge === mfaChallengeType.SOFTWARE_TOKEN_MFA) {
      return (
        <React.Fragment>
          <p>
            <img
              src={process.env.PUBLIC_URL + '/images/mfa.svg'}
              alt="Multi-factor authentication logo"
              className="mfa-form-logo"
            />
          </p>
          <p>Enter code from application which you used to setup MFA.</p>
        </React.Fragment>
      );
    } else if (mfaChallenge === mfaChallengeType.SMS_MFA) {
      return <p>Enter code from SMS you received.</p>;
    } else {
      return null;
    }
  }
}

MfaForm.propTypes = {
  loginFn: PropTypes.func.isRequired,
  cancelFn: PropTypes.func.isRequired,
  mfaChallenge: PropTypes.string
};

export default reduxForm({
  form: FORM_ID,
  validate: validate
})(MfaForm);
