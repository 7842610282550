import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getVendorsList } from '../../open/reducers/vendors';
import { selectVendor, createVendor } from '../actions/selectVendor';
import { getIsVendorSelected, getIsVendorCreated } from '../reducers/selectVendor';
import SelectVendorForm from '../components/selectVendor/SelectVendorForm';
import CreateVendorForm from '../components/selectVendor/CreateVendorForm';
import VendorRequested from '../components/selectVendor/VendorRequested';
import { logout } from '../../../modules/auth/actions/logout';

class SelectVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatingVendor: false
    };
  }

  render() {
    const { vendors, isVendorSelected, isVendorCreated, createVendorFn, selectVendorFn, logoutFn } =
      this.props;
    if (isVendorSelected || isVendorCreated) {
      return <VendorRequested logoutFn={logoutFn} />;
    } else if (this.state.creatingVendor) {
      return (
        <CreateVendorForm
          createVendorFn={createVendorFn}
          switchToSelectVendorFn={() => {
            this.setState({ creatingVendor: false });
          }}
        />
      );
    } else {
      return (
        <SelectVendorForm
          vendors={vendors}
          selectVendorFn={selectVendorFn}
          switchToCreateVendorFn={() => {
            this.setState({ creatingVendor: true });
          }}
        />
      );
    }
  }
}

SelectVendor.propTypes = {
  vendors: PropTypes.array.isRequired,
  selectVendorFn: PropTypes.func.isRequired,
  createVendorFn: PropTypes.func.isRequired,
  logoutFn: PropTypes.func.isRequired,
  isVendorSelected: PropTypes.bool.isRequired,
  isVendorCreated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  return {
    vendors: getVendorsList(state),
    isVendorSelected: getIsVendorSelected(state),
    isVendorCreated: getIsVendorCreated(state)
  };
};

export default connect(mapStateToProps, {
  selectVendorFn: selectVendor,
  createVendorFn: createVendor,
  logoutFn: logout
})(SelectVendor);
