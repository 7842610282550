import { SubmissionError } from 'redux-form';

import { fetchProxy } from '../../actions';

export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE';

export const AUTH_CONFIRM_PASSWORD_REQUEST = 'AUTH_CONFIRM_PASSWORD_REQUEST';
export const AUTH_CONFIRM_PASSWORD_SUCCESS = 'AUTH_CONFIRM_PASSWORD_SUCCESS';
export const AUTH_CONFIRM_PASSWORD_FAILURE = 'AUTH_CONFIRM_PASSWORD_FAILURE';
export const AUTH_RESET_PASSWORD_REINITIALIZE = 'AUTH_RESET_PASSWORD_REINITIALIZE';

export const resetPasswordRequest = () => {
  return {
    type: AUTH_RESET_PASSWORD_REQUEST
  };
};

export const resetPasswordSuccess = (email) => {
  return {
    type: AUTH_RESET_PASSWORD_SUCCESS,
    email: email
  };
};

export const resetPasswordFailure = (errorMessage) => {
  return {
    type: AUTH_RESET_PASSWORD_FAILURE,
    errorMessage
  };
};

export const resetPassword = (email) => (dispatch) => {
  dispatch(resetPasswordRequest());
  return dispatch(
    fetchProxy(`/auth/forgot/${email}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    })
  ).then(
    (response) => {
      if (response.ok) {
        return dispatch(resetPasswordSuccess(email));
      } else {
        return response.json().then((data) => {
          dispatch(resetPasswordFailure(data.errorMessage || 'There was a problem'));
          throw new SubmissionError({ _error: data.errorMessage || 'There was a problem' });
        });
      }
    },
    (error) => {
      // todo: network err, global reducer?
    }
  );
};

export const confirmPasswordRequest = () => {
  return {
    type: AUTH_CONFIRM_PASSWORD_REQUEST
  };
};

export const confirmPasswordSuccess = () => {
  return {
    type: AUTH_CONFIRM_PASSWORD_SUCCESS
  };
};

export const confirmPasswordFailure = (errorMessage) => {
  return {
    type: AUTH_CONFIRM_PASSWORD_FAILURE,
    errorMessage
  };
};

export const confirmReset = (email, code, password) => (dispatch) => {
  dispatch(confirmPasswordRequest());
  return dispatch(
    fetchProxy(`/auth/forgot/${email}/confirm`, {
      method: 'POST',
      body: JSON.stringify({ code, password }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  ).then(
    (response) => {
      if (response.ok) {
        dispatch(confirmPasswordSuccess());
        return 'ok';
      } else {
        return response.json().then((data) => {
          dispatch(confirmPasswordFailure(data.errorMessage || 'There was a problem'));
          throw new SubmissionError({ _error: data.errorMessage || 'There was a problem' });
        });
      }
    },
    (error) => {
      // todo: network err, global reducer?
    }
  );
};
