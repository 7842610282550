import { fetchProxyWithTokenRefresh } from '../../actions';
import { getToken } from '../../auth/reducers/tokens';
import { getApps } from '../reducers/apps';

export const ADMIN_APPS_FETCH_REQUEST = 'ADMIN_APPS_FETCH_REQUEST';
export const ADMIN_APPS_FETCH_SUCCESS = 'ADMIN_APPS_FETCH_SUCCESS';
export const ADMIN_APPS_FETCH_FAILURE = 'ADMIN_APPS_FETCH_FAILURE';

const appsListRequest = () => {
  return {
    type: ADMIN_APPS_FETCH_REQUEST
  };
};

export const appsListSuccess = (data) => {
  return {
    type: ADMIN_APPS_FETCH_SUCCESS,
    payload: data
  };
};

const appsListFailure = () => {
  return {
    type: ADMIN_APPS_FETCH_FAILURE
  };
};

export const fetchApps =
  (forceLoad) =>
  (dispatch, getState, offset = 0, limit = 1000) => {
    if (!forceLoad && getApps(getState()).length) {
      return Promise.resolve('ok');
    }

    dispatch(appsListRequest());

    const params = {
      method: 'GET',
      headers: {
        Authorization: getToken(getState())
      }
    };

    const query = new URLSearchParams({
      offset,
      limit
    });

    return dispatch(fetchProxyWithTokenRefresh(`/admin/apps?${query}`, params)).then((response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(appsListSuccess(data));

          if (data.length < limit) {
            return 'ok';
          }

          return fetchApps(true)(dispatch, getState, offset + limit);
        } else {
          dispatch(appsListFailure());
          return Promise.reject({
            status: response.status,
            message: data.errorMessage || 'Could not fetch components.'
          });
        }
      });
    });
  };

export const ADMIN_APPS_SET_FILTER_QUERY = 'ADMIN_APPS_SET_FILTER_QUERY';
export const ADMIN_APPS_SET_FILTER_STATUS = 'ADMIN_APPS_SET_FILTER_STATUS';

export const setFilterQuery = (query) => (dispatch, getState) => {
  dispatch({
    type: ADMIN_APPS_SET_FILTER_QUERY,
    payload: { query }
  });
};

export const setFilterStatus = (status) => (dispatch, getState) => {
  dispatch({
    type: ADMIN_APPS_SET_FILTER_STATUS,
    payload: { status }
  });
};
