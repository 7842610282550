import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import FarceActions from 'farce/Actions';
import BrowserProtocol from 'farce/BrowserProtocol';
import createHistoryEnhancer from 'farce/createHistoryEnhancer';
import queryMiddleware from 'farce/queryMiddleware';
import createMatchEnhancer from 'found/createMatchEnhancer';
import Matcher from 'found/Matcher';

import reducers from './modules/reducers';
import { foundRoutes } from './routes';
import openQueryParameterMiddleware from './utils/openQueryParameterMiddleware';

export default () => {
  const middlewares = [
    thunk, // keep first, adding functions support
    openQueryParameterMiddleware,
    loadingBarMiddleware({
      promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE']
    })
  ];

  if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger());
  }

  const store = createStore(
    reducers,
    composeWithDevTools(
      applyMiddleware(...middlewares),
      createHistoryEnhancer({
        protocol: new BrowserProtocol(),
        middlewares: [queryMiddleware]
      }),
      createMatchEnhancer(new Matcher(foundRoutes))
    )
  );
  const persistor = persistStore(store);

  store.dispatch(FarceActions.init());

  return { store, persistor };
};
