import { hideLoading } from 'react-redux-loading-bar';
import { addNotification as notify, POSITIONS, STATUS } from 'reapop';

const NO_INTERNET_ERROR_MESSAGE =
  'There is a problem in a communication between your browser and our servers.' +
  ' Check your internet connection and try to reload page.' +
  ' If your connection is ok and the problem persists please contact us at support@keboola.com.';

export const handleNetworkError = (dispatch) => {
  dispatch(hideLoading()); // don't bother user with loading bar
  dispatch(
    notify({
      id: 'network-error', // we want to avoid multiple err notifications
      message: NO_INTERNET_ERROR_MESSAGE,
      status: STATUS.error,
      dismissible: true,
      dismissAfter: 10000,
      position: POSITIONS.topCenter
    })
  );
};

export const networkError = {
  status: 0,
  message: NO_INTERNET_ERROR_MESSAGE
};
