import './sentry';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faInfoCircle,
  faPencilAlt,
  faExternalLinkAlt,
  faCheck,
  faCheckCircle,
  faTerminal,
  faLongArrowAltLeft,
  faSearch,
  faExclamationTriangle,
  faSignOutAlt,
  faUpload,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane, faLightbulb, faTrashAlt, faMeh } from '@fortawesome/free-regular-svg-icons';

import Root from './scripts/modules/Root';
import './styles/styles.css';

library.add(
  faUser,
  faInfoCircle,
  faPencilAlt,
  faExternalLinkAlt,
  faCheck,
  faCheckCircle,
  faTerminal,
  faLongArrowAltLeft,
  faSearch,
  faExclamationTriangle,
  faSignOutAlt,
  faUpload,
  faPlus,
  faPaperPlane,
  faLightbulb,
  faTrashAlt,
  faMeh
);

createRoot(document.getElementById('root')).render(<Root />);
