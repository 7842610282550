import { addNotification as notify, POSITIONS } from 'reapop';
import { SubmissionError } from 'redux-form';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';

const ADMIN_MAKE_USER_ADMIN_REQUEST = 'ADMIN_MAKE_USER_ADMIN_REQUEST';
const ADMIN_MAKE_USER_ADMIN_SUCCESS = 'ADMIN_MAKE_USER_ADMIN_SUCCESS';
const ADMIN_MAKE_USER_ADMIN_FAILURE = 'ADMIN_MAKE_USER_ADMIN_FAILURE';

const makeUserAdminRequest = () => {
  return {
    type: ADMIN_MAKE_USER_ADMIN_REQUEST
  };
};

const makeUserAdminSuccess = () => {
  return {
    type: ADMIN_MAKE_USER_ADMIN_SUCCESS
  };
};

const makeUserAdminFailure = () => {
  return {
    type: ADMIN_MAKE_USER_ADMIN_FAILURE
  };
};

const makeUserAdmin = (email) => (dispatch, getState) => {
  dispatch(makeUserAdminRequest());
  const params = {
    method: 'POST',
    headers: {
      Authorization: getToken(getState())
    }
  };
  return dispatch(fetchProxyWithTokenRefresh(`/admin/users/${email}/admin`, params)).then(
    (response) => {
      if (response.ok) {
        dispatch(makeUserAdminSuccess());
        dispatch(
          notify({
            message: `User ${email} has been marked as admin.`,
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      } else {
        return response.json().then((data) => {
          dispatch(makeUserAdminFailure());
          throw new SubmissionError({
            _error: data.errorMessage || 'Error while making user an admin.'
          });
        });
      }
    }
  );
};

export { makeUserAdmin };
