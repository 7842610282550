import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { reduxForm, Field } from 'redux-form';

import { TextField } from '../../../common/form/fields';
import ClickToSelect from '../../../components/ClickToSelect';
import { validate } from '../models/AddServiceAccountModal';

import PropTypes from 'prop-types';
import InlineNotification from '../../../components/InlineNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FORM_ID = 'AddServiceAccountModal';

class AddServiceAccountModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal() {
    const { closeModalFn, destroy } = this.props;
    this.setState({
      credentials: null
    });
    destroy();
    closeModalFn();
  }

  render() {
    return this.state.credentials ? this.renderCredentials() : this.renderForm();
  }

  handleSubmit(formValues) {
    this.setState({ credentials: null });
    return this.props
      .generateAccountFn(this.props.vendor, formValues)
      .then((credentials) => this.setState({ credentials: credentials }));
  }

  renderErrorMessage() {
    if (!!this.props.error) {
      return <InlineNotification type="danger">{this.props.error}</InlineNotification>;
    }
  }

  renderCredentials() {
    const { credentials } = this.state;
    const { show } = this.props;
    return (
      <Modal show={show} onHide={this.closeModal} className="service-account-credentials">
        <Modal.Header closeButton>
          <Modal.Title>The account has been created</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center service-account-credentials-note">
            Make sure to <strong>copy these credentials.</strong> You won't be able to see them
            again!
          </p>
          <div className="service-account-credentials-credentials">
            <h6>Username</h6>
            <ClickToSelect>
              <pre className="cursor-pointer">{credentials.username}</pre>
            </ClickToSelect>

            <h6>Password</h6>
            <ClickToSelect>
              <pre className="cursor-pointer">{credentials.password}</pre>
            </ClickToSelect>

            <p className="help-block small margin-vertical-1em">
              <FontAwesomeIcon icon={['far', 'lightbulb']} /> Hint: Click the username or password
              to select it.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  renderForm() {
    const { handleSubmit, vendor, show } = this.props;
    return (
      <Modal show={show} onHide={this.closeModal}>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Create a service account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center">
              You're creating new service account for <strong>{vendor}</strong> vendor.
            </p>
            <Field
              formId={FORM_ID}
              name="name"
              component={TextField}
              label="Name"
              placeholder="Name for new account"
            />
            <Field
              formId={FORM_ID}
              name="description"
              component={TextField}
              placeholder="Description"
              label="Description"
            />
            {this.renderErrorMessage()}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                type="submit"
                bsSize="large"
                disabled={this.props.submitting || this.props.pristine}
                bsStyle="primary"
              >
                Create account
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

AddServiceAccountModal.propTypes = {
  vendor: PropTypes.string.isRequired,
  generateAccountFn: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_ID,
  validate: validate
})(AddServiceAccountModal);
