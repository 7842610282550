import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Head from '../../../components/Head';
import resources from '../components/resources';

export default () => {
  return (
    <>
      <Head title="Resources" />
      <div className="resources">
        <div className="container text-center">
          <h1 className="bottom-margin">Resources</h1>
          <div className="tiles-container">
            {resources.map((item, idx) => {
              return (
                <a
                  key={idx}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tile with-hover-effect"
                >
                  <div>
                    <div className="resource-title">
                      <span>
                        {item.title}{' '}
                        <sup>
                          <FontAwesomeIcon icon={'external-link-alt'} />
                        </sup>
                      </span>
                    </div>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
