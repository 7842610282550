import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { fetchProxyWithTokenRefresh } from '../../../actions';
import { getToken } from '../../../auth/reducers/tokens';

export const HOME_DEPRECATE_APP_REQUEST = 'HOME_DEPRECATE_APP_REQUEST';
export const HOME_DEPRECATE_APP_SUCCESS = 'HOME_DEPRECATE_APP_SUCCESS';
export const HOME_DEPRECATE_APP_FAILURE = 'HOME_DEPRECATE_APP_FAILURE';

const deprecateAppRequest = () => ({ type: HOME_DEPRECATE_APP_REQUEST });
export const deprecateAppSuccess = (data) => {
  return {
    type: HOME_DEPRECATE_APP_SUCCESS,
    payload: {
      app: data
    }
  };
};
const deprecateAppFailure = () => ({ type: HOME_DEPRECATE_APP_FAILURE });

export const deprecateApp = (vendorId, appId, formValues) => (dispatch, getState) => {
  const state = getState();
  dispatch(deprecateAppRequest());
  const params = {
    method: 'POST',
    body: JSON.stringify(formValues),
    headers: {
      Authorization: getToken(state)
    }
  };
  return dispatch(
    fetchProxyWithTokenRefresh(`/vendors/${vendorId}/apps/${appId}/deprecate`, params)
  ).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        dispatch(deprecateAppSuccess(data));
        dispatch(
          notify({
            message: `The component has been deprecated.`,
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      } else {
        dispatch(deprecateAppFailure());
        throw new SubmissionError({
          _error: data.errorMessage || 'Error while deprecating component.'
        });
      }
    });
  });
};
