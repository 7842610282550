import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import EditAppForm from './EditAppForm';
import { validate } from '../../../home/models/EditAppForm';
export const FORM_ID = 'EditAppForm';

let EditAppFormCreated = reduxForm({
  form: FORM_ID,
  validate: validate
})(EditAppForm);

const selector = formValueSelector(FORM_ID);

const mapStateToProps = (state) => {
  return { type: selector(state, 'type') };
};

export default connect(mapStateToProps)(EditAppFormCreated);
