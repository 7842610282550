import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { reduxForm, Field, change } from 'redux-form';

import { TextField, SearchSelectField } from '../../../common/form/fields';
import { validate } from '../models/AddDeveloper';
import { normalizeEmail } from '../../../common/form/normalizers';
import InlineNotification from '../../../components/InlineNotification';

export const FORM_ID = 'AddDeveloperForm';

class AddDeveloper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.setState({ show: true });
  }

  closeModal() {
    this.props.destroy();
    this.setState({
      show: false
    });
  }

  componentDidUpdate() {
    if (this.props.vendor) {
      this.props.dispatch(change(FORM_ID, 'vendor', this.props.vendor));
    }
  }

  render() {
    return (
      <span>
        <button onClick={this.showModal} className="btn btn-primary">
          Invite a developer
        </button>
        {this.renderModal()}
      </span>
    );
  }

  handleSubmit(formValues) {
    return this.props.inviteUserFn(formValues).then(() => {
      this.closeModal();
    });
  }

  renderModal() {
    const { handleSubmit, pristine, submitting, vendor } = this.props;
    return (
      <Modal show={this.state.show} onHide={this.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Invite a developer</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Body>
            <p>
              You're going to invite a new developer
              {vendor && (
                <span>
                  {' '}
                  to the vendor <strong>{vendor}</strong>
                </span>
              )}
              {'.'}
            </p>
            {this.renderErrorMessage()}
            {this.renderInputForm()}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                type="submit"
                bsSize="large"
                disabled={submitting || pristine}
                bsStyle="primary"
              >
                Invite
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  renderErrorMessage() {
    if (!!this.props.error) {
      return <InlineNotification type="danger">{this.props.error}</InlineNotification>;
    }
  }

  renderInputForm() {
    const vendorsOptions = []
      .concat(this.props.allVendors || [])
      .map((v) => ({ label: v.name, value: v.id }));
    return (
      <span>
        <Field
          formId={FORM_ID}
          name="email"
          component={TextField}
          label="Email address"
          placeholder="Please enter the recipient's email address"
          normalize={normalizeEmail}
        />
        {!this.props.vendor && (
          <Field
            formId={FORM_ID}
            name="vendor"
            component={SearchSelectField}
            options={vendorsOptions}
            placeholder="Select vendor"
            label="Vendor"
          />
        )}
      </span>
    );
  }
}

AddDeveloper.propTypes = {
  allVendors: PropTypes.array,
  vendor: PropTypes.string,
  inviteUserFn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_ID,
  validate: validate
})(AddDeveloper);
