import React from 'react';
import AppBox from './AppBox';

const PublicApps = ({ apps, showComponentType = true }) => {
  return (
    <div className="tiles-container open-apps-list">
      {apps.map(function (app) {
        return <AppBox app={app} key={app.id} showComponentType={showComponentType} />;
      })}
    </div>
  );
};

export default PublicApps;
