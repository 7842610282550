import React from 'react';
import debounce from 'lodash/debounce';
import SelectField from '../../../../common/form/SelectField';
import SearchInput from '../../../../common/form/SearchInput';
import RejectionReasonModal from '../../../home/components/apps/RejectionReasonModal';
import PublishAppForm from './PublishAppForm';
import RejectAppForm from './RejectAppForm';
import AppsTableRow from './AppsTableRow';

const optionsStatusFilter = () => {
  return [
    {
      label: 'All',
      value: ''
    },
    {
      label: 'Public',
      value: 'public'
    },
    {
      label: 'Private',
      value: 'private'
    }
  ];
};

class AppsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPublishModal: false,
      showRejectModal: false,
      showRejectionReasonModal: false,
      appToPublish: null,
      appToReject: null,
      appWithRejectionReason: null,
      query: ''
    };

    this.openPublishModal = this.openPublishModal.bind(this);
    this.closePublishModal = this.closePublishModal.bind(this);
    this.openRejectModal = this.openRejectModal.bind(this);
    this.closeRejectModal = this.closeRejectModal.bind(this);
    this.showRejectionReasonModal = this.showRejectionReasonModal.bind(this);
    this.closeRejectionReasonModal = this.closeRejectionReasonModal.bind(this);
    this.setFilterQueryFnDebounced = debounce(this.props.setFilterQueryFn, 300);
  }

  componentDidMount() {
    this.setState({ query: this.props.filter.query || '' });
  }

  openPublishModal(app) {
    this.setState({
      showPublishModal: true,
      appToPublish: app
    });
  }

  closePublishModal() {
    this.setState({
      showPublishModal: false,
      appToPublish: null
    });
  }

  openRejectModal(app) {
    this.setState({
      showRejectModal: true,
      appToReject: app
    });
  }

  closeRejectModal() {
    this.setState({
      showRejectModal: false,
      appToReject: null
    });
  }

  showRejectionReasonModal(app) {
    this.setState({
      showRejectionReasonModal: true,
      appWithRejectionReason: app
    });
  }

  closeRejectionReasonModal() {
    this.setState({
      showRejectionReasonModal: false,
      appWithRejectionReason: null
    });
  }

  render() {
    const {
      apps,
      filter,
      setFilterStatusFn,
      isDeleting,
      deletionError,
      deleteAppFn,
      approveAppFn,
      rejectAppFn
    } = this.props;
    return (
      <div>
        {this.state.appWithRejectionReason && (
          <RejectionReasonModal
            app={this.state.appWithRejectionReason}
            showModal={this.state.showRejectionReasonModal}
            closeModalFn={this.closeRejectionReasonModal}
          />
        )}
        {this.state.appToPublish && (
          <PublishAppForm
            app={this.state.appToPublish}
            showModal={this.state.showPublishModal}
            approveAppFn={approveAppFn}
            closeModalFn={this.closePublishModal}
          />
        )}
        {this.state.appToReject && (
          <RejectAppForm
            app={this.state.appToReject}
            showModal={this.state.showRejectModal}
            rejectAppFn={rejectAppFn}
            closeModalFn={this.closeRejectModal}
          />
        )}

        <table className="table table-hover apps-list">
          <thead className="search-bar">
            <tr>
              <th />
              <th>
                <SearchInput
                  value={this.state.query}
                  onChangeFn={(query) => {
                    this.setState({ query });
                    if (!query) {
                      this.props.setFilterQueryFn(query);
                    } else {
                      this.setFilterQueryFnDebounced(query);
                    }
                  }}
                  placeholder="Filter by id, name, vendor or author"
                />
              </th>
              <th />
              <th />
              <th />
              <th>
                <SelectField
                  value={filter.status}
                  onChangeFn={setFilterStatusFn}
                  options={optionsStatusFilter()}
                />
              </th>
              <th />
            </tr>
          </thead>
          <thead className="no-top-border">
            <tr>
              <th className="column-width-12" />
              <th className="column-width-36">Component name, ID, vendor and author</th>
              <th className="column-width-14">Type</th>
              <th className="column-width-8" colSpan="2">
                Version
              </th>
              <th className="column-width-15">Status</th>
              <th>Manage</th>
            </tr>
          </thead>
          <tbody>
            {apps.map((app) => (
              <AppsTableRow
                key={app.id}
                app={app}
                openPublishModal={this.openPublishModal}
                openRejectModal={this.openRejectModal}
                showRejectionReasonModal={this.showRejectionReasonModal}
                deleteAppFn={deleteAppFn}
                isDeleting={isDeleting}
                deletionError={deletionError}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AppsTable;
