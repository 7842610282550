import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, FormSection } from 'redux-form';
import Tab from 'react-bootstrap/lib/Tab';
import Tabs from 'react-bootstrap/lib/Tabs';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Icon, Vendor, LongDescription } from '../../../../components/apps/detail';
import MarkdownHelp from './MarkdownHelp';
import { AVAILABLE_OPTIONS, COMPONENT_TYPE_DATA_APP } from '../../models/App';
import {
  TextField,
  TextAreaField,
  MultiSelectField,
  BooleanField,
  JsonEditorField,
  ToggleButtonGroupRadioField,
  SelectField,
  TextAreaMarkdownWithPreviewField
} from '../../../../common/form/fields';
import {
  normalizeBoolean,
  normalizeObject,
  normalizeEmptyStringToNull
} from '../../../../common/form/normalizers';
import InlineNotification from '../../../../components/InlineNotification';
import { Link as ExternalLink } from '../../../../components/externalLinks';
import ContactModal from '../../../../components/ContactModal';

class EditAppForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAdvancedRepoFields: false
    };
    this.handleClickShowAdvancedRepoFields = this.handleClickShowAdvancedRepoFields.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values) {
    const { updateAppSubmitFn, app } = this.props;
    const vendorId = app.vendor.id;
    return updateAppSubmitFn(vendorId, app.id, values);
  }

  render() {
    const {
      handleSubmit,
      app,
      type,
      formStore,
      error,
      pristine,
      submitting,
      editAppIconOpenModalFn,
      formId: FORM_ID,
      module,
      invalid,
      submitFailed
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="app-detail-header">
          <div className="image-box">
            <div className="wrapper">
              <Icon app={app} />
              <button type="button" className="btn btn-primary" onClick={editAppIconOpenModalFn}>
                Change icon
              </button>
            </div>
          </div>
          <div className="flags-box">
            <div className="wrapper">
              {/* The ID field is rendered without redux-form */}
              <FormGroup controlId={`${FORM_ID}-id`}>
                <ControlLabel>ID</ControlLabel>
                <FormControl type="text" value={app.id} disabled />
              </FormGroup>
              <Field
                formId={FORM_ID}
                name="name"
                component={TextField}
                label="Name"
                placeholder="Name of your component without its type suffix (e.g. MySQL)"
              />
              <Field
                formId={FORM_ID}
                name="type"
                component={SelectField}
                label="Type"
                data={AVAILABLE_OPTIONS.type}
                nameField="name"
                valueField="value"
              />
              {module === 'admin' && (
                <>
                  <Field
                    formId={FORM_ID}
                    name="categories"
                    component={MultiSelectField}
                    label="Categories"
                    options={AVAILABLE_OPTIONS.categories}
                    placeholder="Please select"
                    normalize={normalizeEmptyStringToNull}
                  />
                  <Field
                    formId={FORM_ID}
                    name="complexity"
                    component={SelectField}
                    label="Complexity"
                    data={AVAILABLE_OPTIONS.complexity}
                    nameField="name"
                    valueField="value"
                    placeholder="Please select"
                  />
                </>
              )}
              <Field
                formId={FORM_ID}
                name="shortDescription"
                component={TextAreaField}
                label="Short description"
                placeholder="One or two sentences describing your component"
              />
              <p className="help-block small margin-vertical-1em">
                <FontAwesomeIcon icon={['far', 'lightbulb']} /> Hint: Scroll down to edit Long
                description.
              </p>
              {this.renderRepository()}
              <Field
                formId={FORM_ID}
                name="licenseUrl"
                component={TextField}
                label="License URL"
                placeholder="URL where the end-user can find license information"
                normalize={normalizeEmptyStringToNull}
              />
              <Field
                formId={FORM_ID}
                name="documentationUrl"
                component={TextField}
                label="Documentation URL"
                placeholder="URL where the end-user can find documentation"
                normalize={normalizeEmptyStringToNull}
              />
              <Field
                formId={FORM_ID}
                name="sourceCodeUrl"
                component={TextField}
                label="Source code URL"
                placeholder="URL where the end-user can find component's source code"
                normalize={normalizeEmptyStringToNull}
              />
              {module === 'admin' && (
                <div>
                  <Field
                    formId={FORM_ID}
                    name="isPublic"
                    component={BooleanField}
                    label="Public"
                    normalize={normalizeBoolean}
                  />
                  <Field
                    formId={FORM_ID}
                    name="isDeprecated"
                    component={BooleanField}
                    label="Deprecated"
                    normalize={normalizeBoolean}
                  />
                </div>
              )}
              {type !== COMPONENT_TYPE_DATA_APP && (
                <>
                  <Field
                    formId={FORM_ID}
                    name="network"
                    component={ToggleButtonGroupRadioField}
                    label="Network"
                    data={[
                      { name: 'Bridge', value: 'bridge' },
                      { name: 'None', value: 'none' }
                    ]}
                    nameField="name"
                    valueField="value"
                  />
                  <Field
                    formId={FORM_ID}
                    name="defaultBucket"
                    component={BooleanField}
                    label="Default bucket"
                    normalize={normalizeBoolean}
                    description={
                      <>
                        Allow automatic bucket definition (so user doesn't have to define bucket
                        when configuring your component). Read more in the{' '}
                        <ExternalLink href="https://developers.keboola.com/extend/common-interface/folders/#default-bucket">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                  />
                  {formStore.defaultBucket && formStore.defaultBucket === true ? (
                    <Field
                      formId={FORM_ID}
                      name="defaultBucketStage"
                      component={ToggleButtonGroupRadioField}
                      label="Default bucket stage"
                      data={[
                        { name: 'In', value: 'in' },
                        { name: 'Out', value: 'out' }
                      ]}
                      nameField="name"
                      valueField="value"
                    />
                  ) : null}
                </>
              )}
              <Field
                formId={FORM_ID}
                name="uiOptions"
                component={MultiSelectField}
                options={Object.keys(
                  AVAILABLE_OPTIONS.uiOptions
                    .concat(formStore.uiOptions)
                    .reduce((uniqueItems, item) => {
                      return {
                        ...uniqueItems,
                        [item]: item
                      };
                    }, {})
                )}
                placeholder="Select which UI helpers you want to use"
                label="UI options"
                description={
                  <>
                    Specify how user interface of your component will look. Without any
                    configuration, the component cannot be configured via UI. The most basic option
                    is <code>genericDockerUI</code>. Read more in the{' '}
                    <ExternalLink href="https://developers.keboola.com/extend/component/ui-options/">
                      documentation
                    </ExternalLink>
                    .
                  </>
                }
              />
              <Field
                formId={FORM_ID}
                name="imageParameters"
                component={JsonEditorField}
                label="Image parameters"
                placeholder="Image parameters"
                normalizeOnBlur={normalizeObject}
              />
              <Field
                formId={FORM_ID}
                name="stackParameters"
                component={JsonEditorField}
                label="Stack parameters"
                placeholder="Stack parameters"
                normalizeOnBlur={normalizeObject}
              />
              {type !== COMPONENT_TYPE_DATA_APP && (
                <>
                  <Field
                    formPreview
                    formId={FORM_ID}
                    name="configurationSchema"
                    component={JsonEditorField}
                    label="Configuration schema"
                    placeholder="Configuration schema"
                    description={
                      <>
                        Allow user to fill the JSON using a set of defined inputs. Read more in the{' '}
                        <ExternalLink href="https://developers.keboola.com/extend/registration/configuration-schema/">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                    normalizeOnBlur={normalizeObject}
                  />
                  <Field
                    formPreview
                    formId={FORM_ID}
                    name="configurationRowSchema"
                    component={JsonEditorField}
                    label="Configuration row schema"
                    placeholder="Configuration row schema"
                    description={
                      <>
                        Allow user to fill the JSON using a set of defined inputs. It should be used
                        in combination with <code>genericDockerUI-rows</code> UI option. Read more
                        in the{' '}
                        <ExternalLink href="https://developers.keboola.com/integrate/storage/api/configurations/#configuration-rows">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                    normalizeOnBlur={normalizeObject}
                  />
                  <Field
                    formId={FORM_ID}
                    name="emptyConfiguration"
                    component={JsonEditorField}
                    label="Default configuration"
                    placeholder="Default configuration"
                    normalizeOnBlur={normalizeObject}
                    description={
                      <>
                        Configurations in Keboola Connection will be created with default options.
                        Read more in the{' '}
                        <ExternalLink href="https://developers.keboola.com/extend/component/ui-options/default-configuration/">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                  />
                  <Field
                    formId={FORM_ID}
                    name="emptyConfigurationRow"
                    component={JsonEditorField}
                    label="Default row configuration"
                    placeholder="Default row configuration"
                    normalizeOnBlur={normalizeObject}
                    description={
                      <>
                        Configuration rows in Keboola Connection will be created with default
                        options. Read more in the{' '}
                        <ExternalLink href="https://developers.keboola.com/extend/component/ui-options/default-configuration/">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                  />
                  <Field
                    formPreview
                    formId={FORM_ID}
                    name="createConfigurationRowSchema"
                    component={JsonEditorField}
                    label="Create configuration row schema"
                    placeholder="Create configuration row schema"
                    description={
                      <>
                        A form for creating multiple rows simultaneously. It must be used in
                        conjunction with the synchronous <code>prepareRows</code> action.
                      </>
                    }
                    normalizeOnBlur={normalizeObject}
                  />
                  <Field
                    formId={FORM_ID}
                    name="configurationDescription"
                    component={TextAreaMarkdownWithPreviewField}
                    placeholder="Description of your configuration. Markdown is supported."
                    rows="6"
                    normalize={normalizeEmptyStringToNull}
                    previewValue={formStore.configurationDescription}
                  />
                  <Field
                    formId={FORM_ID}
                    name="actions"
                    component={MultiSelectField}
                    options={formStore.actions || []}
                    placeholder="List of supported synchronous actions"
                    label="Actions"
                    creatable
                    description={
                      <>
                        Synchronous actions can be very helpful when you want to provide additional
                        functionality within your component (e.g. test user's configuration before
                        its execution). Read more in the{' '}
                        <ExternalLink href="https://developers.keboola.com/extend/common-interface/actions/">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                  />
                  <Field
                    formId={FORM_ID}
                    name="limits"
                    component={TextAreaField}
                    label="Service limits / Reasonable usage"
                    placeholder="Description of service or usage limits"
                    description={
                      <>
                        Define what is maximum expected usage of your component. This may vary
                        depending on the memory/time limits, API usage limits and other factors.
                      </>
                    }
                  />
                  <Field
                    formId={FORM_ID}
                    name="fees"
                    component={BooleanField}
                    label="Fees"
                    normalize={normalizeBoolean}
                    description={<>Check this if using the component implies additional fees.</>}
                  />
                  <Field
                    formId={FORM_ID}
                    name="stagingStorageInput"
                    component={SelectField}
                    label="Staging storage input"
                    data={AVAILABLE_OPTIONS.stagingStorageInput}
                    nameField="name"
                    valueField="value"
                    description={
                      <>
                        Keep set to Local if you're not sure. Read more in the{' '}
                        <ExternalLink href="https://developers.keboola.com/extend/common-interface/folders/">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                  />
                  <Field
                    formId={FORM_ID}
                    name="stagingStorageOutput"
                    component={SelectField}
                    label="Staging storage output"
                    data={AVAILABLE_OPTIONS.stagingStorageOutput}
                    nameField="name"
                    valueField="value"
                    description={
                      <>
                        Keep set to Local if you're not sure. Read more in the{' '}
                        <ExternalLink href="https://developers.keboola.com/extend/common-interface/folders/">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                  />
                  <Field
                    formId={FORM_ID}
                    name="logger"
                    component={ToggleButtonGroupRadioField}
                    label="Logger"
                    data={[
                      { name: 'Standard', value: 'standard' },
                      { name: 'Gelf', value: 'gelf' }
                    ]}
                    nameField="name"
                    valueField="value"
                    description={
                      <>
                        Keep set to Standard if you're not sure. Select Gelf (advanced options will
                        show up) to configure sending of structured event messages. Read more in the{' '}
                        <ExternalLink href="https://developers.keboola.com/extend/common-interface/logging/#gelf-logger">
                          documentation
                        </ExternalLink>
                        .
                      </>
                    }
                  />
                </>
              )}
              {formStore.logger && formStore.logger === 'gelf' ? (
                <Field
                  formId={FORM_ID}
                  name="loggerConfiguration"
                  component={JsonEditorField}
                  label="Logger configuration"
                  placeholder="Logger configuration"
                  normalizeOnBlur={normalizeObject}
                />
              ) : null}
              {module === 'admin' ? (
                <div>
                  <Field
                    formId={FORM_ID}
                    name="forwardToken"
                    component={BooleanField}
                    label="Forward token"
                    normalize={normalizeBoolean}
                  />
                  <Field
                    formId={FORM_ID}
                    name="forwardTokenDetails"
                    component={BooleanField}
                    label="Forward token details"
                    normalize={normalizeBoolean}
                  />
                  <Field
                    formId={FORM_ID}
                    name="requiredMemory"
                    component={TextField}
                    label="Required memory"
                    placeholder="Maximum memory your container will use (e.g. 128m)"
                  />
                  <Field
                    formId={FORM_ID}
                    name="processTimeout"
                    component={TextField}
                    label="Process timeout"
                    placeholder="Maximum processing time in seconds"
                  />
                  <Field
                    formId={FORM_ID}
                    name="permissions"
                    component={JsonEditorField}
                    label="Permissions"
                    placeholder="Permissions"
                    normalizeOnBlur={normalizeObject}
                  />
                  <Field
                    formId={FORM_ID}
                    name="features"
                    component={MultiSelectField}
                    label="Features"
                    placeholder="Select which features you want to use"
                    options={AVAILABLE_OPTIONS.features}
                  />
                  <Field
                    formId={FORM_ID}
                    component={SelectField}
                    name="dataTypeSupport"
                    label="Data type support"
                    placeholder="Select how Native Data Types are supported"
                    data={AVAILABLE_OPTIONS.dataTypeSupport}
                    nameField="name"
                    valueField="value"
                  />
                  <Field
                    formId={FORM_ID}
                    component={SelectField}
                    name="allowedProcessorPosition"
                    label="Allowed processor position"
                    placeholder="Select which processor position is allowed"
                    data={AVAILABLE_OPTIONS.allowedProcessorPosition}
                    nameField="name"
                    valueField="value"
                  />
                </div>
              ) : (
                this.renderReadOnlyAdminFields()
              )}
            </div>
          </div>
          <div className="vendor-box vendor-box-view-mode font-size-smaller">
            <div className="wrapper">
              <Vendor app={app} />
            </div>
          </div>
        </div>
        <div className="app-detail-long-description">
          <div className="row">
            <div className="col-md-6 col-md-offset-3">
              <ControlLabel>Long description</ControlLabel>
              <Tabs
                defaultActiveKey={1}
                id="modules-home-components-apps-EditAppForm-longDescription"
              >
                <Tab eventKey={1} title="Edit">
                  <Field
                    formId={FORM_ID}
                    name="longDescription"
                    component={TextAreaField}
                    placeholder="Markdown description of the component"
                    rows="10"
                  />
                </Tab>
                <Tab eventKey={2} title="Preview">
                  <LongDescription
                    description={formStore.longDescription}
                    noDescriptionMessage="Nothing to preview"
                  />
                </Tab>
              </Tabs>

              {submitFailed && invalid && (
                <InlineNotification type="danger">
                  You probably filled some values incorrectly. Check for errors above.
                </InlineNotification>
              )}

              {error && <InlineNotification type="danger">{error}</InlineNotification>}

              <div className="text-center margin-vertical-3em">
                <button
                  disabled={pristine || submitting}
                  type="submit"
                  className="btn btn-primary btn-lg"
                >
                  Save
                </button>
                <p className="help-block small margin-vertical-1em">
                  It may take up to 5 minutes to propagate changes to Keboola Connection.
                </p>
              </div>
            </div>
            <div className="col-md-3">
              <MarkdownHelp />
            </div>
          </div>
        </div>
      </form>
    );
  }

  handleClickShowAdvancedRepoFields() {
    this.setState({
      showAdvancedRepoFields: true
    });
  }

  renderRepository() {
    const { formId: FORM_ID } = this.props;
    return (
      <div className="form-group-group">
        <div className="form-group-group-title">Repository</div>
        <div className="form-group-group-body">
          <FormSection name="repository">
            {this.state.showAdvancedRepoFields ? (
              <React.Fragment>
                <Field
                  formId={FORM_ID}
                  name="type"
                  component={SelectField}
                  label="Type"
                  data={AVAILABLE_OPTIONS.repository.type}
                  nameField="name"
                  valueField="value"
                  placeholder="Please select ..."
                />
                <Field
                  formId={FORM_ID}
                  name="uri"
                  component={TextField}
                  label="Image name"
                  placeholder="Image name (e.g. keboola/mysql-extractor)"
                />
              </React.Fragment>
            ) : (
              <p
                className="background-white help-block small"
                style={{ padding: '.5em 0', margin: '1em 0 0' }}
              >
                Using{' '}
                <ExternalLink href="https://developers.keboola.com/extend/component/deployment/">
                  deploy script
                </ExternalLink>{' '}
                is recommended way to configure Repository type and Image name. However, if you need
                to set these options manually{' '}
                <button
                  type="button"
                  onClick={this.handleClickShowAdvancedRepoFields}
                  className="btn btn-link btn-link-as-href"
                >
                  click here.
                </button>
              </p>
            )}
            <Field
              formId={FORM_ID}
              name="tag"
              component={TextField}
              label="Tag"
              placeholder="Tag (e.g. v1.5.3)"
            />
          </FormSection>
        </div>
      </div>
    );
  }

  renderReadOnlyAdminFields() {
    const { formId: FORM_ID, app } = this.props;
    return (
      <div>
        <FormGroup controlId={`${FORM_ID}-requiredMemory`}>
          <ControlLabel>Required memory</ControlLabel>
          <FormControl type="text" value={app.requiredMemory || 'default'} disabled />
        </FormGroup>
        <FormGroup controlId={`${FORM_ID}-processTimeout`}>
          <ControlLabel>Process timeout</ControlLabel>
          <FormControl type="text" value={app.processTimeout || 'default'} disabled />
        </FormGroup>
        <p className="help-block small margin-vertical-1em">
          <FontAwesomeIcon icon={['far', 'lightbulb']} /> Hint: <ContactModal /> to change Required
          memory or Process timeout.
        </p>
      </div>
    );
  }
}
EditAppForm.propTypes = {
  app: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  formStore: PropTypes.object.isRequired,
  updateAppSubmitFn: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  editAppIconOpenModalFn: PropTypes.func.isRequired
};

export default EditAppForm;
