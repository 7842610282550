import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.closeModalIfOk = this.closeModalIfOk.bind(this);
  }

  render() {
    return (
      <div>
        {React.cloneElement(this.props.children, { onClick: this.showModal })}
        {this.renderModal()}
      </div>
    );
  }

  showModal() {
    this.setState({ show: true });
  }

  closeModal() {
    this.setState({ show: false });
  }

  closeModalIfOk(result) {
    if (result === 'ok') {
      this.closeModal();
    }
  }

  onConfirm() {
    const confirmResult = this.props.onConfirm();
    if (this.props.closeOnOkResolve) {
      return confirmResult.then(this.closeModalIfOk);
    } else {
      this.closeModal();
    }
  }

  renderModal() {
    return (
      <div className="modal-container">
        <Modal show={this.state.show} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{this.props.text}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={this.onConfirm}
              disabled={this.props.isConfirming}
              bsSize="large"
              bsStyle={this.props.buttonStyle}
            >
              {this.props.buttonLabel}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ConfirmModal.propTypes = {
  closeOnOkResolve: PropTypes.bool,
  isConfirming: PropTypes.bool,
  children: PropTypes.any,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonStyle: PropTypes.string,
  text: PropTypes.any.isRequired
};

ConfirmModal.defaultProps = {
  isConfirming: false,
  closeOnOkResolve: false,
  buttonStyle: 'danger'
};

export default ConfirmModal;
