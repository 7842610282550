import React from 'react';

import Link from '../../../components/Link';
import AppsList from '../components/apps/AppsList';
import HowItWorks from '../components/HowItWorks';
import { routes } from '../../../routesConstants';
import topNineApps from '../data/topNineApps';

export default () => {
  return (
    <div className="homepage">
      <div className="container">
        <div className="title-and-action">
          <h1>Create your component for Keboola Connection</h1>
          <p>
            Make your component a part of a powerful, secure and open data processing framework.
            <br />
            Connect it to dozens of data sources and make it accessible to all KBC users.
          </p>
          <Link to={routes.ROUTE_AUTH_CREATE_ACCOUNT} className="btn btn-success btn-lg">
            Start by creating an account
          </Link>
        </div>
      </div>

      <HowItWorks />

      <div className="container">
        <div className="homepage-apps">
          <h3 className="text-primary">Hardest working components</h3>
          <div>
            <AppsList apps={topNineApps} showComponentType={false} />
          </div>
          <div className="text-center margin-vertical-4em">
            <Link to={routes.ROUTE_OPEN_COMPONENTS} className="btn btn-primary btn-lg">
              More components
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
