import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { getToken } from '../../auth/reducers/tokens';
import { getProfile } from '../../auth/reducers/profile';
import { fetchProxyWithTokenRefresh } from '../../actions';
import { refreshToken } from '../../auth/actions/token';

export const ADMIN_FETCH_ALL_USERS_REQUEST = 'ADMIN_FETCH_ALL_USERS_REQUEST';
export const ADMIN_FETCH_ALL_USERS_SUCCESS = 'ADMIN_FETCH_ALL_USERS_SUCCESS';
export const ADMIN_FETCH_ALL_USERS_FAILURE = 'ADMIN_FETCH_ALL_USERS_FAILURE';

export const loadAllUsersRequest = () => ({ type: ADMIN_FETCH_ALL_USERS_REQUEST });

export const loadAllUsersSuccess = (data, apiFilter) => ({
  type: ADMIN_FETCH_ALL_USERS_SUCCESS,
  data,
  apiFilter
});

export const loadAllUsersFailure = (vendor, msg) => ({
  type: ADMIN_FETCH_ALL_USERS_FAILURE,
  errorMessage: msg
});

export const fetchAllUsers = (apiFilter) => (dispatch, getState) => {
  const state = getState();
  const apiFilterParam = apiFilter ? `?filter=${apiFilter}` : '';
  const params = {
    method: 'GET',
    headers: {
      Authorization: getToken(state)
    }
  };
  dispatch(loadAllUsersRequest());
  return dispatch(fetchProxyWithTokenRefresh(`/admin/users${apiFilterParam}`, params)).then(
    (response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(loadAllUsersSuccess(data, apiFilter));
          return 'ok';
        } else {
          dispatch(loadAllUsersFailure(data.errorMessage));
          return Promise.reject({
            status: response.status,
            message: data.errorMessage || 'Could not fetch users'
          });
        }
      });
    }
  );
};

// remove user or add user to vendor

export const ADMIN_SET_USER_VENDOR_REQUEST = 'ADMIN_SET_USER_VENDOR_REQUEST';
export const ADMIN_SET_USER_VENDOR_SUCCESS = 'ADMIN_SET_USER_VENDOR_SUCCESS';
export const ADMIN_SET_USER_VENDOR_FAILURE = 'ADMIN_SET_USER_VENDOR_FAILURE';

export const setUserVendorRequest = (vendor, username) => ({
  type: ADMIN_SET_USER_VENDOR_REQUEST,
  vendor,
  username
});
export const setUserVendorSuccess = (vendor, username) => ({
  type: ADMIN_SET_USER_VENDOR_SUCCESS,
  vendor,
  username
});
export const setUserVendorFailure = (vendor, username, errorMessage) => ({
  type: ADMIN_SET_USER_VENDOR_FAILURE,
  vendor,
  username,
  errorMessage
});

const notifyAfterVendorsUpdate = (vendor, username, action) => {
  return notify({
    message:
      action === 'remove'
        ? `The user ${username} has been removed from the vendor ${vendor}.`
        : `The user ${username} has been added to the vendor ${vendor}.`,
    status: 'success',
    dismissible: true,
    dismissAfter: 6000,
    position: POSITIONS.topCenter
  });
};

export const setUserVendor = (action, username, vendor) => (dispatch, getState) => {
  const params = {
    method: action === 'remove' ? 'DELETE' : 'POST',
    headers: {
      Authorization: getToken(getState())
    }
  };
  const isUpdatingHimself = username === getProfile(getState()).email;
  dispatch(setUserVendorRequest(vendor, username));
  return dispatch(
    fetchProxyWithTokenRefresh(`/admin/users/${username}/vendors/${vendor}`, params)
  ).then((response) => {
    if (response.ok) {
      if (isUpdatingHimself) {
        return dispatch(refreshToken()).then(() => {
          return dispatch(fetchAllUsers()).then(() => {
            dispatch(setUserVendorSuccess(vendor, username));
            dispatch(notifyAfterVendorsUpdate(vendor, username, action));
            return 'ok';
          });
        });
      } else {
        return dispatch(fetchAllUsers()).then(() => {
          dispatch(setUserVendorSuccess(vendor, username));
          dispatch(notifyAfterVendorsUpdate(vendor, username, action));
          return 'ok';
        });
      }
    } else {
      return response.json().then((data) => {
        dispatch(
          setUserVendorFailure(
            vendor,
            username,
            data.errorMessage || data.message || 'There was a problem'
          )
        );
        throw new SubmissionError({
          _error: data.errorMessage || 'Error while setting user vendor'
        });
      });
    }
  });
};

// onsite filter - via filter search bar
export const ADMIN_ALL_USERS_SET_FILTER = 'ADMIN_ALL_USERS_SET_FILTER';
export const setUsersFilter = (filter) => ({ type: ADMIN_ALL_USERS_SET_FILTER, filter });

// onsite filter
export const ADMIN_ALL_USERS_SET_VENDOR_FILTER = 'ADMIN_ALL_USERS_SET_VENDOR_FILTER';
export const setUsersVendorFilter = (vendor) => ({
  type: ADMIN_ALL_USERS_SET_VENDOR_FILTER,
  vendor
});

// onsite filter
export const ADMIN_ALL_USERS_SET_STATUS_FILTER = 'ADMIN_ALL_USERS_SET_STATUS_FILTER';
export const setUsersStatusFilter = (status) => ({
  type: ADMIN_ALL_USERS_SET_STATUS_FILTER,
  status
});

// manage vendors modal action
export const ADMIN_ALL_USERS_MANAGE_VENDORS = 'ADMIN_ALL_USERS_MANAGE_VENDORS';
export const manageUserVendors = (show, user) => ({
  type: ADMIN_ALL_USERS_MANAGE_VENDORS,
  show,
  user
});
