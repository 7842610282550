import { combineReducers } from 'redux';
import _ from 'lodash';
import list from './list';
import filter from './filter';
import details from './details';
import changes from './changes';
import appDelete from './appDelete';
import { getFilter } from './filter';
import { sortApps } from '../../../../utils/helpers';

export default combineReducers({
  list,
  filter,
  details,
  changes,
  appDelete
});

const filterQuery = (apps, query) => {
  if (query.length < 2) {
    return apps;
  }

  const queryLowerCase = query.toLowerCase();
  const propsToSearch = ['id', 'name', 'type', 'createdBy'];
  const arrayPropsToSearch = ['actions', 'features', 'uiOptions'];

  return apps
    .map((app) => {
      const vendorName = app.vendor?.name || '';

      if (
        propsToSearch.some((key) => app[key].toLowerCase().includes(queryLowerCase)) ||
        vendorName.toLowerCase().includes(queryLowerCase)
      ) {
        return app;
      }

      const arrayMatch = arrayPropsToSearch.find((key) => app[key].includes(query));
      if (arrayMatch) {
        return { ...app, matchedByKey: arrayMatch };
      }

      return false;
    })
    .filter(Boolean);
};

const filterStatus = (apps, status) => {
  if (status === '') {
    return apps;
  }
  return apps.filter((app) => {
    return app.isPublic === (status === 'public');
  });
};

export const getApps = (state) => {
  return state.admin.apps.list;
};

export const getFilteredApps = (state) => {
  const { query, status } = getFilter(state);
  return sortApps(filterStatus(filterQuery(getApps(state), query), status));
};
