import React, { Component } from 'react';
import { connect } from 'react-redux';
import VendorsTable from '../components/VendorsTable';
import {
  setVendorsFilter,
  editVendor,
  onUpdateVendor,
  toggleVendorApprove,
  approveVendor
} from '../actions/vendors';
import {
  getVendorEditorState,
  getFilter,
  getFilteredVendors,
  getToggleApprove
} from '../reducers/vendors';

class Vendors extends Component {
  render() {
    return (
      <div className="container">
        <VendorsTable
          vendors={this.props.vendors}
          showApproveModal={this.props.showApproveModal}
          filter={this.props.filter}
          vendorEditorState={this.props.vendorEditorState}
          approveVendor={this.props.approveVendor}
          onToggleVendorApprove={this.props.onToggleVendorApprove}
          onUpdateVendor={this.props.onUpdateVendor}
          onToggleEditVendor={this.props.onToggleEditVendor}
          onChangeFilter={this.props.setVendorsFilter}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vendors: getFilteredVendors(state),
  showApproveModal: getToggleApprove(state),
  filter: getFilter(state),
  vendorEditorState: getVendorEditorState(state)
});

export default connect(mapStateToProps, {
  approveVendor,
  onToggleVendorApprove: toggleVendorApprove,
  setVendorsFilter,
  onUpdateVendor,
  onToggleEditVendor: editVendor
})(Vendors);
