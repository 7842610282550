import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { getToken, getAccessToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';
import { fetchProfile } from '../../auth/actions/profile';

export const HOME_ENABLE_MFA_REQUEST = 'HOME_ENABLE_MFA_REQUEST';
export const HOME_ENABLE_MFA_SUCCESS = 'HOME_ENABLE_MFA_SUCCESS';
export const HOME_ENABLE_MFA_FAILURE = 'HOME_ENABLE_MFA_FAILURE';

export const enableMfaRequest = () => ({
  type: HOME_ENABLE_MFA_REQUEST
});

export const enableMfaSuccess = () => ({
  type: HOME_ENABLE_MFA_SUCCESS
});

export const enableMfaFailure = () => ({
  type: HOME_ENABLE_MFA_FAILURE
});

export const enableMfa = () => (dispatch, getState) => {
  dispatch(enableMfaRequest());

  return dispatch(
    fetchProxyWithTokenRefresh('/auth/mfa', {
      method: 'POST',
      headers: {
        Authorization: getToken(getState())
      },
      body: JSON.stringify({
        accessToken: getAccessToken(getState())
      })
    })
  ).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        dispatch(enableMfaSuccess());
        return data.secretCode;
      } else {
        dispatch(enableMfaFailure());
        throw new SubmissionError({
          _error: data.errorMessage || 'Could not enable MFA.'
        });
      }
    });
  });
};

export const HOME_CONFIRM_MFA_REQUEST = 'HOME_CONFIRM_MFA_REQUEST';
export const HOME_CONFIRM_MFA_SUCCESS = 'HOME_CONFIRM_MFA_SUCCESS';
export const HOME_CONFIRM_MFA_FAILURE = 'HOME_CONFIRM_MFA_FAILURE';

export const confirmMfaRequest = () => ({
  type: HOME_CONFIRM_MFA_REQUEST
});

export const confirmMfaSuccess = () => ({
  type: HOME_CONFIRM_MFA_SUCCESS
});

export const confirmMfaFailure = () => ({
  type: HOME_CONFIRM_MFA_FAILURE
});

export const confirmMfa =
  ({ code }) =>
  (dispatch, getState) => {
    dispatch(confirmMfaRequest());

    return dispatch(
      fetchProxyWithTokenRefresh('/auth/mfa/confirm', {
        method: 'POST',
        headers: {
          Authorization: getAccessToken(getState())
        },
        body: JSON.stringify({
          accessToken: getAccessToken(getState()),
          code: code
        })
      })
    ).then((response) => {
      if (response.ok) {
        return dispatch(fetchProfile()).then(() => {
          dispatch(confirmMfaSuccess());
          dispatch(
            notify({
              message: 'Multi-factor authentication has been set up successfully.',
              status: 'success',
              dismissible: true,
              dismissAfter: 6000,
              position: POSITIONS.topCenter
            })
          );
        });
      } else {
        dispatch(confirmMfaFailure());
        return response.json().then((error) => {
          throw new SubmissionError({
            _error: error.errorMessage || 'Could not confirm MFA setup.'
          });
        });
      }
    });
  };
