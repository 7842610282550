import { jwtDecode } from 'jwt-decode';

import { getRefreshToken } from '../reducers/tokens';
import { fetchProxy } from '../../actions';

export const AUTH_TOKEN_REFRESH_REQUEST = 'AUTH_TOKEN_REFRESH_REQUEST';
export const AUTH_TOKEN_REFRESH_SUCCESS = 'AUTH_TOKEN_REFRESH_SUCCESS';
export const AUTH_TOKEN_REFRESH_FAILURE = 'AUTH_TOKEN_REFRESH_FAILURE';

export const decodeTokenPayload = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return {};
  }
};

export const tokenRefreshRequest = () => {
  return {
    type: AUTH_TOKEN_REFRESH_REQUEST
  };
};

export const tokenRefreshSuccess = (data) => {
  return {
    type: AUTH_TOKEN_REFRESH_SUCCESS,
    payload: {
      token: data.token,
      accessToken: data.accessToken,
      tokenPayload: decodeTokenPayload(data.token)
    }
  };
};

export const tokenRefreshFailure = (errorMessage) => {
  return {
    type: AUTH_TOKEN_REFRESH_FAILURE,
    errorMessage: errorMessage
  };
};

export const refreshToken = () => (dispatch, getState) => {
  dispatch(tokenRefreshRequest());
  return dispatch(
    fetchProxy('/auth/token', {
      method: 'GET',
      headers: {
        Authorization: getRefreshToken(getState())
      }
    })
  ).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        return dispatch(tokenRefreshSuccess(data));
      } else {
        return dispatch(tokenRefreshFailure(data.errorMessage || 'Could not refresh token.'));
      }
    });
  });
};
