import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';

// onsite filter - via filter search bar
export const ADMIN_VENDORS_SET_FILTER = 'ADMIN_VENDORS_SET_FILTER';
export const setVendorsFilter = (filter) => ({ type: ADMIN_VENDORS_SET_FILTER, filter });

// vendor editor modal action
export const ADMIN_VENDORS_ON_EDIT = 'ADMIN_VENDORS_ON_EDIT';
export const editVendor = (show, vendor) => ({ type: ADMIN_VENDORS_ON_EDIT, show, vendor });

// toggle vendor approve modal
export const ADMIN_VENDORS_TOGGLE_APPROVE = 'ADMIN_VENDORS_TOGGLE_APPROVE';
export const toggleVendorApprove = (show) => ({ type: ADMIN_VENDORS_TOGGLE_APPROVE, show });

export const ADMIN_UPDATE_VENDOR_REQUEST = 'ADMIN_UPDATE_VENDOR_REQUEST';
export const ADMIN_UPDATE_VENDOR_SUCCESS = 'ADMIN_UPDATE_VENDOR_SUCCESS';
export const ADMIN_UPDATE_VENDOR_FAILURE = 'ADMIN_UPDATE_VENDOR_FAILURE';

export const updateVendorRequest = () => ({ type: ADMIN_UPDATE_VENDOR_REQUEST });
export const updateVendorSuccess = () => ({ type: ADMIN_UPDATE_VENDOR_SUCCESS });
export const updateVendorFailure = (errorMessage) => ({
  type: ADMIN_UPDATE_VENDOR_FAILURE,
  errorMessage
});

export const onUpdateVendor = (vendor, body) => (dispatch, getState) => {
  const state = getState();
  const isCreating = !vendor;
  // if we dont have vendor then we create vendor otherwise update existing
  const url = isCreating ? `/admin/vendors` : `/vendors/${vendor}`;
  const params = {
    method: isCreating ? 'POST' : 'PATCH',
    body: JSON.stringify(body),
    headers: {
      Authorization: getToken(state)
    }
  };
  dispatch(updateVendorRequest());
  return dispatch(fetchProxyWithTokenRefresh(url, params)).then((response) => {
    if (response.ok) {
      return dispatch(fetchVendors()).then(() => {
        dispatch(updateVendorSuccess());
        dispatch(
          notify({
            message: isCreating
              ? 'The vendor has been created.'
              : 'The vendor details have been updated.',
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      });
    } else {
      return response.json().then((data) => {
        dispatch(updateVendorFailure(data.errorMessage || data.message || 'There was a problem'));
        throw new SubmissionError({
          _error: data.errorMessage || 'API call failed, contact support@keboola.com'
        });
      });
    }
  });
};

export const ADMIN_APPROVE_VENDOR_REQUEST = 'ADMIN_APPROVE_VENDOR_REQUEST';
export const ADMIN_APPROVE_VENDOR_SUCCESS = 'ADMIN_APPROVE_VENDOR_SUCCESS';
export const ADMIN_APPROVE_VENDOR_FAILURE = 'ADMIN_APPROVE_VENDOR_FAILURE';

export const approveVendorRequest = () => ({ type: ADMIN_APPROVE_VENDOR_REQUEST });
export const approveVendorSuccess = () => ({ type: ADMIN_APPROVE_VENDOR_SUCCESS });
export const approveVendorFailure = (errorMessage) => ({
  type: ADMIN_APPROVE_VENDOR_FAILURE,
  errorMessage
});

export const approveVendor = (formValues) => (dispatch, getState) => {
  const state = getState();
  const url = `/admin/vendors/${formValues.tmpId}/approve`;
  const params = {
    method: 'POST',
    body: JSON.stringify({ newId: formValues.newId }),
    headers: {
      Authorization: getToken(state)
    }
  };
  dispatch(approveVendorRequest());
  return dispatch(fetchProxyWithTokenRefresh(url, params)).then((response) => {
    if (response.ok) {
      return dispatch(fetchVendors()).then(() => {
        dispatch(approveVendorSuccess());
        dispatch(
          notify({
            message: `Vendor ${formValues.newId} has been approved.`,
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      });
    } else {
      return response.json().then((data) => {
        dispatch(approveVendorFailure(data.errorMessage || data.message || 'There was a problem'));
        throw new SubmissionError({
          _error: data.errorMessage || 'API call failed, contact support@keboola.com'
        });
      });
    }
  });
};

export const ADMIN_FETCH_VENDORS_SUCCESS = 'ADMIN_FETCH_VENDORS_SUCCESS';
export const ADMIN_FETCH_VENDORS_REQUEST = 'ADMIN_FETCH_VENDORS_REQUEST';
export const ADMIN_FETCH_VENDORS_FAILURE = 'ADMIN_FETCH_VENDORS_FAILURE';

const vendorsFetchRequest = () => {
  return {
    type: ADMIN_FETCH_VENDORS_REQUEST
  };
};

export const vendorsFetchSuccess = (data) => {
  return {
    type: ADMIN_FETCH_VENDORS_SUCCESS,
    payload: {
      vendors: data
    }
  };
};

const vendorsFetchFailure = () => ({
  type: ADMIN_FETCH_VENDORS_FAILURE
});

export const fetchVendors = () => (dispatch, getState) => {
  const params = {
    method: 'GET',
    headers: {
      Authorization: getToken(getState())
    }
  };

  dispatch(vendorsFetchRequest());
  return dispatch(fetchProxyWithTokenRefresh('/admin/vendors', params)).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        dispatch(vendorsFetchSuccess(data));
        return 'ok';
      } else {
        dispatch(vendorsFetchFailure());
        return Promise.reject({
          status: response.status,
          message: data.errorMessage || 'Could not fetch vendors.'
        });
      }
    });
  });
};
