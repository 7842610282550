import React from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../../../../components/Link';
import { routeParts, routes } from '../../../../routesConstants';
import { RemoveAppConfirmationModal } from '../../../home/components/apps/confirmationModals';
import { Icon } from '../../../../components/apps/detail';
import AppsTableAppStatus from '../../../home/components/apps/AppsTableAppStatus';
import AppStatus from '../../../home/components/apps/AppStatus';
import { getComponentTypeLabel } from '../../../../helpers';

class AppsTableRow extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.app.updatedOn !== nextProps.app.updatedOn ||
      this.props.app.matchedByKey !== nextProps.app.matchedByKey
    );
  }

  render() {
    const { app } = this.props;
    const linkToDetail = `${routes.ROUTE_ADMIN_COMPONENTS}/${app.id}`;
    const linkToEdit = `${routes.ROUTE_ADMIN_COMPONENTS}/${app.id}${routeParts.EDIT}`;
    const linkToChanges = `${routes.ROUTE_ADMIN_COMPONENTS}/${app.id}${routeParts.CHANGES}`;

    return (
      <tr>
        <td className="full-height">
          <Link className="block-link" to={linkToDetail}>
            <Icon app={app} />
          </Link>
        </td>
        <td className="full-height">
          <div className="app-name">
            {app.matchedByKey && (
              <small className="text-muted">
                <FontAwesomeIcon icon="info-circle" className="icon-addon-margin-right" />
                Matched by <code>{app.matchedByKey}</code> parameter
              </small>
            )}
            <Link className="block-link" to={linkToDetail}>
              <h4>{app.name ? app.name : <span>&nbsp;</span>}</h4>
              <div className="small">{app.id}</div>
              <div>
                {app.vendor.name}, {app.createdBy}
              </div>
            </Link>
          </div>
        </td>
        <td>{getComponentTypeLabel(app.type)}</td>
        <td>{app.version}</td>
        <td>
          <AppsTableAppStatus
            publishingStatus={app.publishingStatus}
            showRejectionReasonFn={() => this.props.showRejectionReasonModal(app)}
          />
          {app.publishingStatus === 'requested' && (
            <small>
              requested on {format(parseISO(app.publishingRequest.createdOn), 'dd/MM/yyyy')}
            </small>
          )}
          {!app.isPublic && !app.isDeprecated && (
            <div>
              <button
                onClick={() => this.props.openPublishModal(app)}
                className="btn btn-link btn-link-as-href"
              >
                Publish
              </button>
              {app.publishingStatus === 'requested' && (
                <span>
                  {' or '}
                  <button
                    onClick={() => this.props.openRejectModal(app)}
                    className="btn btn-link btn-link-as-href"
                  >
                    Reject
                  </button>
                  <br />
                </span>
              )}
            </div>
          )}
        </td>
        <td>
          <AppStatus
            isPublic={app.isPublic}
            isDeprecated={app.isDeprecated}
            expiredOn={app.expiredOn}
          />
        </td>
        <td>
          <Link to={linkToEdit}>Edit</Link>
          <br />
          <RemoveAppConfirmationModal
            error={this.props.deletionError}
            app={app}
            deleteAppFn={this.props.deleteAppFn}
            isDeleting={this.props.isDeleting}
          />
          <Link to={linkToChanges}>Changes</Link>
        </td>
      </tr>
    );
  }
}

AppsTableRow.propTypes = {
  app: PropTypes.object.isRequired,
  openPublishModal: PropTypes.func.isRequired,
  openRejectModal: PropTypes.func.isRequired,
  showRejectionReasonModal: PropTypes.func.isRequired,
  deleteAppFn: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  deletionError: PropTypes.string
};

export default AppsTableRow;
