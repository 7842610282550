import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';

const HOME_UPLOAD_ICON_REQUEST = 'HOME_UPLOAD_ICON_REQUEST';
const HOME_UPLOAD_ICON_SUCCESS = 'HOME_UPLOAD_ICON_SUCCESS';
const HOME_UPLOAD_ICON_FAILURE = 'HOME_UPLOAD_ICON_FAILURE';

const uploadIconRequest = () => {
  return {
    type: HOME_UPLOAD_ICON_REQUEST
  };
};

const uploadIconSuccess = () => {
  return {
    type: HOME_UPLOAD_ICON_SUCCESS
  };
};

const uploadIconFailure = () => {
  return {
    type: HOME_UPLOAD_ICON_FAILURE,
    error: true
  };
};

const getLinkForIconUpload = (vendorId, appId) => (dispatch, getState) => {
  const params = {
    method: 'POST',
    headers: {
      Authorization: getToken(getState())
    }
  };

  return dispatch(
    fetchProxyWithTokenRefresh(`/vendors/${vendorId}/apps/${appId}/icon`, params)
  ).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        return data;
      } else {
        dispatch(uploadIconFailure());
        throw new SubmissionError({
          _error: data.message || data.errorMessage || 'Could not initiate icon upload.'
        });
      }
    });
  });
};

export const uploadAppIcon =
  (vendorId, appId, { file }) =>
  (dispatch, getState) => {
    dispatch(uploadIconRequest());

    return dispatch(getLinkForIconUpload(vendorId, appId)).then((data) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const url = data.link;
        reader.onload = (event) => {
          resolve(
            // this fetch is an exception, doesn't have to use proxy
            // it's an API call to AWS S3
            fetch(url, {
              method: 'PUT',
              body: event.target.result,
              headers: {
                'Content-type': 'image/png'
              }
            }).then((response) => {
              if (response.ok) {
                dispatch(uploadIconSuccess());
                dispatch(
                  notify({
                    message:
                      'The component icon has been updated. The change may take some time to take effect.',
                    status: 'success',
                    dismissible: true,
                    dismissAfter: 8000,
                    position: POSITIONS.topCenter
                  })
                );
              } else {
                dispatch(uploadIconFailure());
                throw new SubmissionError({
                  _error: 'Could not upload icon to server.'
                });
              }
            })
          );
        };
        reader.onerror = () => {
          dispatch(uploadIconFailure());
          reject(
            new SubmissionError({
              _error: 'Could not read source image file.'
            })
          );
        };
        reader.readAsArrayBuffer(file);
      });
    });
  };
