import { fetchProxyWithTokenRefresh } from '../../actions';
import { getToken } from '../../auth/reducers/tokens';
export const ADMIN_GET_APP_DETAIL_REQUEST = 'ADMIN_GET_APP_DETAIL_REQUEST';
export const ADMIN_GET_APP_DETAIL_SUCCESS = 'ADMIN_GET_APP_DETAIL_SUCCESS';
export const ADMIN_GET_APP_DETAIL_FAILURE = 'ADMIN_GET_APP_DETAIL_FAILURE';

const getAppDetailRequest = () => {
  return {
    type: ADMIN_GET_APP_DETAIL_REQUEST
  };
};

export const getAppDetailSuccess = (data) => {
  return {
    type: ADMIN_GET_APP_DETAIL_SUCCESS,
    payload: {
      app: data
    }
  };
};

const getAppDetailFailure = () => ({
  type: ADMIN_GET_APP_DETAIL_FAILURE
});

export const getAppDetail = (appId) => (dispatch, getState) => {
  dispatch(getAppDetailRequest());
  const params = {
    method: 'GET',
    headers: {
      Authorization: getToken(getState())
    }
  };
  return dispatch(fetchProxyWithTokenRefresh(`/admin/apps/${appId}`, params)).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        dispatch(getAppDetailSuccess(data));
        return 'ok';
      } else {
        dispatch(getAppDetailFailure());
        return Promise.reject({
          status: response.status,
          message: data.errorMessage || 'Could not fetch component detail.'
        });
      }
    });
  });
};
