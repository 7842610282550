import * as actions from '../../actions/vendors.js';

export const initialState = '';

export default (state = initialState, action) => {
  if (action.type === actions.HOME_SET_USERS_FILTER) return action.filter;
  return state;
};

export const getUsersFilter = (state) => state.home.developers.filter;
