import React from 'react';
import Link from '../../../../components/Link';
import ComponentBadge from '../../../../components/ComponentBadge';
import { routes } from '../../../../routesConstants';
import { Icon } from '../../../../components/apps/detail';
import { componentTypes } from '../../../home/models/App';
import { getComponentTypeLabel } from '../../../../helpers';

export default ({ app, showComponentType = true }) => {
  return (
    <Link
      to={`${routes.ROUTE_OPEN_COMPONENTS}/${app.id}`}
      className="tile app-box with-hover-effect"
    >
      <div>
        <Icon app={app} />
        <h5>
          {app.name}
          {componentTypes.includes(app.type) && showComponentType && (
            <> {getComponentTypeLabel(app.type)}</>
          )}
        </h5>
        <p>{app.shortDescription}</p>
        <ComponentBadge flags={app.uiOptions} />
      </div>
    </Link>
  );
};
