import * as actions from '../../actions/apps';

export default (state = {}, action) => {
  switch (action.type) {
    case actions.HOME_FETCH_APP_DETAIL_SUCCESS:
      const app = action.payload.app;
      return {
        ...state,
        [app.id]: app
      };
    default:
      return state;
  }
};

export const getAppDetail = (state, appId) => {
  return state.home.apps.details[appId];
};
