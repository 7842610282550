import { createWhitelistFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';
import getStoredStateMigrateV4 from 'redux-persist/lib/integration/getStoredStateMigrateV4';

// note that only first level can be synced
const keysToSyncFilter = createWhitelistFilter(
  'auth',
  ['tokens', 'profile'],
  ['tokens', 'profile']
);

export default () => {
  return {
    key: 'primary',
    storage,
    whitelist: ['auth'],
    throttle: 250,
    transforms: [keysToSyncFilter],
    // automatic migration
    getStoredState: getStoredStateMigrateV4({
      whitelist: ['auth'],
      transforms: [keysToSyncFilter]
    })
  };
};
