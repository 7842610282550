import FarceActions from 'farce/Actions';
import { SubmissionError } from 'redux-form';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';
import { routes } from '../../../routesConstants';
import config from '../../../../config';

const HOME_UPDATE_APP_REQUEST = 'HOME_UPDATE_APP_REQUEST';
const HOME_UPDATE_APP_SUCCESS = 'HOME_UPDATE_APP_SUCCESS';
const HOME_UPDATE_APP_FAILURE = 'HOME_UPDATE_APP_FAILURE';

const vendorUpdateAppRequest = () => {
  return {
    type: HOME_UPDATE_APP_REQUEST
  };
};

const vendorUpdateAppFailure = () => {
  return {
    type: HOME_UPDATE_APP_FAILURE,
    error: true
  };
};

const vendorUpdateAppSuccess = () => {
  return {
    type: HOME_UPDATE_APP_SUCCESS
  };
};

const redirectAfterVendorUpdateAppSuccess = (appId) => {
  return FarceActions.push(config.BASE_PATH + `${routes.ROUTE_HOME_COMPONENTS}/${appId}`);
};

export const updateAppSubmit = (vendorId, appId, data) => (dispatch, getState) => {
  dispatch(vendorUpdateAppRequest());

  const params = {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      Authorization: getToken(getState())
    }
  };

  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendorId}/apps/${appId}`, params)).then(
    (response) => {
      if (response.ok) {
        dispatch(vendorUpdateAppSuccess());
        dispatch(redirectAfterVendorUpdateAppSuccess(appId));
      } else {
        dispatch(vendorUpdateAppFailure());
        return response.json().then((error) => {
          throw new SubmissionError({
            _error: error.message || error.errorMessage || 'Error while updating component.'
          });
        });
      }
    }
  );
};
