import * as loginActionTypes from '../actions/login';
import * as logoutActionTypes from '../actions/logout';
import * as tokenActionTypes from '../actions/token';
import * as profileActionTypes from '../actions/profile';

export const initialState = {
  name: null,
  email: null,
  vendors: [],
  currentVendor: null,
  isAdmin: false,
  mfa: null
};

const getVendorsFromTokenPayload = (tokenPayload) => {
  if (!tokenPayload.profile) {
    return initialState.vendors;
  }
  return tokenPayload.profile.split(',').filter((vendorId) => vendorId.indexOf('_v') !== 0);
};

const getIsAdminFromTokenPayload = (tokenPayload) => {
  if (!tokenPayload['custom:isAdmin']) {
    return initialState.isAdmin;
  }
  return parseInt(tokenPayload['custom:isAdmin'], 10) === 1;
};

export default (state = initialState, action) => {
  let vendors = initialState.vendors;
  switch (action.type) {
    case loginActionTypes.AUTH_LOGIN_RECEIVE_DATA:
      vendors = getVendorsFromTokenPayload(action.payload.tokenPayload);
      return {
        ...state,
        name: action.payload.tokenPayload.name,
        email: action.payload.tokenPayload.email,
        vendors: vendors,
        isAdmin: getIsAdminFromTokenPayload(action.payload.tokenPayload),
        currentVendor: vendors.length > 0 ? vendors[0] : null
      };
    case logoutActionTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      };
    case tokenActionTypes.AUTH_TOKEN_REFRESH_SUCCESS:
      vendors = getVendorsFromTokenPayload(action.payload.tokenPayload);
      const isInCurrentVendor = vendors.includes(state.currentVendor);
      const newCurrentVendor = vendors.length > 0 ? vendors[0] : null;
      return {
        ...state,
        name: action.payload.tokenPayload.name,
        email: action.payload.tokenPayload.email,
        vendors: vendors,
        isAdmin: getIsAdminFromTokenPayload(action.payload.tokenPayload),
        currentVendor: isInCurrentVendor ? state.currentVendor : newCurrentVendor
      };
    case profileActionTypes.AUTH_USER_SELECT_VENDOR:
      return { ...state, currentVendor: action.vendor };
    case profileActionTypes.AUTH_FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        mfa: action.payload.data.mfa
      };
    default:
      return state;
  }
};

export const getProfile = (state) => {
  return state.auth.profile;
};

export const hasFetchedProfile = (state) => {
  return state.auth.profile.mfa !== null;
};

export const getAllVendors = (state) => {
  return getProfile(state).vendors;
};
export const getCurrentVendor = (state) => {
  return getProfile(state).currentVendor;
};

export const getIsAdmin = (state) => {
  return getProfile(state).isAdmin;
};
