import * as loginActionTypes from '../actions/login';
import * as logoutActionTypes from '../actions/logout';
import * as tokenActionTypes from '../actions/token';

export const initialState = {
  token: null,
  accessToken: null,
  refreshToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case loginActionTypes.AUTH_LOGIN_RECEIVE_DATA:
      return {
        token: action.payload.data.token,
        accessToken: action.payload.data.accessToken,
        refreshToken: action.payload.data.refreshToken
      };
    case logoutActionTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      };
    case tokenActionTypes.AUTH_TOKEN_REFRESH_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        accessToken: action.payload.accessToken
      };
    default:
      return state;
  }
};

export const getToken = (state) => {
  return state.auth.tokens.token ? state.auth.tokens.token : null;
};

export const getRefreshToken = (state) => {
  return state.auth.tokens.refreshToken ? state.auth.tokens.refreshToken : null;
};

export const getAccessToken = (state) => {
  return state.auth.tokens.accessToken ? state.auth.tokens.accessToken : null;
};

export const getIsAuthenticated = (state) => {
  return !!state.auth.tokens.token && !!state.auth.tokens.accessToken;
};
