import * as actionTypes from '../../actions/appDelete';

export const initialState = {
  deleting: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_DELETE_APP_REQUEST:
      return {
        ...state,
        deleting: true,
        error: null
      };
    case actionTypes.ADMIN_DELETE_APP_SUCCESS:
      return {
        ...state,
        deleting: false,
        error: null
      };
    case actionTypes.ADMIN_DELETE_APP_FAILURE:
      return {
        ...state,
        deleting: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export const getIsDeleting = (state) => {
  return state.admin.apps.appDelete.deleting;
};
export const getDeletionError = (state) => {
  return state.admin.apps.appDelete.error;
};
