import {
  setFilterQuery,
  setFilterType,
  setFilterVendor,
  setFilterStage,
  OPEN_APPS_SET_FILTER_QUERY as QUERY,
  OPEN_APPS_SET_FILTER_TYPE as TYPE,
  OPEN_APPS_SET_FILTER_VENDOR as VENDOR,
  OPEN_APPS_SET_FILTER_STAGE as STAGE
} from '../modules/open/actions/apps';
import {
  setFilterQuery as setAdminFilterQuery,
  setFilterStatus as setAdminFilterStatus,
  ADMIN_APPS_SET_FILTER_QUERY as ADMIN_QUERY,
  ADMIN_APPS_SET_FILTER_STATUS as ADMIN_STATUS
} from '../modules/admin/actions/apps';

const openQueryParameterMiddleware = (api) => (next) => (action) => {
  const params = new URLSearchParams(window.location.search);

  // init params when app load on components page
  const setValue = (value) => (value === 'null' ? null : value);
  if (action.type === '@@farce/INIT' || action.type === '@@found/RESOLVE_MATCH') {
    if (window.location.pathname === '/components') {
      if (params.has('query')) api.dispatch(setFilterQuery(setValue(params.get('query'))));
      if (params.has('type')) api.dispatch(setFilterType(setValue(params.get('type'))));
      if (params.has('vendorId')) api.dispatch(setFilterVendor(setValue(params.get('vendorId'))));
      if (params.has('stage')) api.dispatch(setFilterStage(setValue(params.get('stage'))));
    } else if (window.location.pathname === '/admin') {
      if (params.has('query')) api.dispatch(setAdminFilterQuery(setValue(params.get('query'))));
      if (params.has('status')) api.dispatch(setAdminFilterStatus(setValue(params.get('status'))));
    }
  }

  // update url when parameters change
  if ([QUERY, TYPE, VENDOR, STAGE, ADMIN_QUERY, ADMIN_STATUS].includes(action.type)) {
    Object.keys(action.payload).map((key) => params.set(key, action.payload[key]));
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  }

  return next(action);
};

export default openQueryParameterMiddleware;
