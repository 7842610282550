import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { reduxForm } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InlineNotification from '../../../../components/InlineNotification';
import { Link } from '../../../../components/externalLinks';
import { Icon } from '../../../../components/apps/detail';
import { hasIcon, hasRepository, hasAllFieldsForPublishing } from '../../../../utils/helpers';

const FORM_ID = 'HomeRequestAppApprovalForm';

class RequestApprovalForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { requestApprovalFn, closeModalFn, app } = this.props;
    return requestApprovalFn(app.vendor.id, app.id).then(() => {
      closeModalFn();
    });
  }

  render() {
    const { app, showModal, closeModalFn, handleSubmit, submitting, error } = this.props;
    return (
      <Modal
        show={showModal}
        onHide={closeModalFn}
        bsSize="large"
        className="request-approval-form"
      >
        <Row>
          <Col md={7}>
            <Modal.Header closeButton>
              <Modal.Title>Request component approval</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Icon app={app} />
              <p>
                You are about to request approval of the component{' '}
                <strong>
                  {app.name} <br />({app.id}).
                </strong>
              </p>
              <p>After our team approves the component, it will become public.</p>
              {error && <InlineNotification type="danger">{error}</InlineNotification>}
            </Modal.Body>
            <Modal.Footer>
              <form onSubmit={handleSubmit(this.handleSubmit)}>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={submitting || !hasAllFieldsForPublishing(app)}
                >
                  Request approval
                </button>
              </form>
            </Modal.Footer>
          </Col>
          <Col md={5}>
            <div className="margin-vertical-4em">
              <p className="font-weight-bold margin-vertical-2em">
                Before requesting approval, the component has to have the following attributes
                defined:
              </p>
              <ul className="list-unstyled text-left">
                <li>
                  <FontAwesomeIcon
                    className={`text-${
                      hasIcon(app) ? 'success' : 'danger'
                    } icon-addon-margin-right`}
                    icon={hasIcon(app) ? 'check' : 'exclamation-triangle'}
                  />{' '}
                  Icon
                </li>
                <li>
                  <FontAwesomeIcon
                    className={`text-${app.name ? 'success' : 'danger'} icon-addon-margin-right`}
                    icon={app.name ? 'check' : 'exclamation-triangle'}
                  />{' '}
                  Name
                </li>
                <li>
                  <FontAwesomeIcon
                    className={`text-${app.type ? 'success' : 'danger'} icon-addon-margin-right`}
                    icon={app.type ? 'check' : 'exclamation-triangle'}
                  />{' '}
                  Type
                </li>
                <li>
                  <FontAwesomeIcon
                    className={`text-${
                      hasRepository(app) ? 'success' : 'danger'
                    } icon-addon-margin-right`}
                    icon={hasRepository(app) ? 'check' : 'exclamation-triangle'}
                  />{' '}
                  Repository
                </li>
                <li>
                  <FontAwesomeIcon
                    className={`text-${
                      app.shortDescription ? 'success' : 'danger'
                    } icon-addon-margin-right`}
                    icon={app.shortDescription ? 'check' : 'exclamation-triangle'}
                  />{' '}
                  Short description
                </li>
                <li>
                  <FontAwesomeIcon
                    className={`text-${
                      app.longDescription ? 'success' : 'danger'
                    } icon-addon-margin-right`}
                    icon={app.longDescription ? 'check' : 'exclamation-triangle'}
                  />{' '}
                  Long description
                </li>
                <li>
                  <FontAwesomeIcon
                    className={`text-${
                      app.licenseUrl ? 'success' : 'danger'
                    } icon-addon-margin-right`}
                    icon={app.licenseUrl ? 'check' : 'exclamation-triangle'}
                  />{' '}
                  License URL
                </li>
                <li>
                  <FontAwesomeIcon
                    className={`text-${
                      app.documentationUrl ? 'success' : 'danger'
                    } icon-addon-margin-right`}
                    icon={app.documentationUrl ? 'check' : 'exclamation-triangle'}
                  />{' '}
                  Documentation URL
                </li>
              </ul>
              <Link href="https://developers.keboola.com/extend/publish/#component-review">
                Read more
              </Link>{' '}
              in a documentation.
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
}
RequestApprovalForm.propTypes = {
  app: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  requestApprovalFn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_ID
})(RequestApprovalForm);
