import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { fetchProxyWithTokenRefresh } from '../../actions';
import { hasUsers } from '../reducers/developers/list';
import { getToken } from '../../auth/reducers/tokens';
import { refreshToken } from '../../../modules/auth/actions/token';
import { getProfile } from '../../auth/reducers/profile';
import { fetchVendors } from '../../open/actions/vendors';

export const HOME_FETCH_USERS_REQUEST = 'HOME_FETCH_USERS_REQUEST';
export const HOME_FETCH_USERS_SUCCESS = 'HOME_FETCH_USERS_SUCCESS';
export const HOME_FETCH_USERS_FAILURE = 'HOME_FETCH_USERS_FAILURE';

export const HOME_SET_USERS_FILTER = 'HOME_SET_USERS_FILTER';

export const setUsersFilter = (filter) => ({ type: HOME_SET_USERS_FILTER, filter: filter });
export const loadUsersRequest = () => ({ type: HOME_FETCH_USERS_REQUEST });
export const loadUsersSuccess = (vendor, data) => ({
  type: HOME_FETCH_USERS_SUCCESS,
  data: data,
  vendor: vendor
});
export const loadUsersFailure = (vendor, msg) => ({
  type: HOME_FETCH_USERS_FAILURE,
  errorMessage: msg,
  vendor: vendor
});

export const loadUsers = (vendor, forceReload) => (dispatch, getState) => {
  const state = getState();
  if (!forceReload && hasUsers(state, vendor)) {
    return Promise.resolve('ok');
  }
  const params = {
    method: 'GET',
    headers: {
      Authorization: getToken(state)
    }
  };
  dispatch(loadUsersRequest());
  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendor}/users`, params)).then(
    (response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(loadUsersSuccess(vendor, data));
          return 'ok';
        } else {
          dispatch(loadUsersFailure(vendor, data.errorMessage));
          return Promise.reject({
            status: response.status,
            message: data.errorMessage || 'Could not fetch users.'
          });
        }
      });
    }
  );
};

export const HOME_USER_REMOVE_REQUEST = 'HOME_USER_REMOVE_REQUEST';
export const HOME_USER_REMOVE_SUCCESS = 'HOME_USER_REMOVE_SUCCESS';
export const HOME_USER_REMOVE_FAILURE = 'HOME_USER_REMOVE_FAILURE';

export const removeUserRequest = (vendor, username) => ({
  type: HOME_USER_REMOVE_REQUEST,
  vendor,
  username
});
export const removeUserSuccess = (vendor, username) => ({
  type: HOME_USER_REMOVE_SUCCESS,
  vendor,
  username
});
export const removeUserFailure = (vendor, username) => ({
  type: HOME_USER_REMOVE_FAILURE,
  vendor,
  username
});

const notifyAfterUserRemoval = (vendor, username) => {
  return notify({
    message: `The user ${username} has been removed from the vendor ${vendor}.`,
    status: 'success',
    dismissible: true,
    dismissAfter: 6000,
    position: POSITIONS.topCenter
  });
};

export const removeUser = (vendor, username) => (dispatch, getState) => {
  const state = getState();
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: getToken(state)
    }
  };
  const isLeaving = getProfile(state).email === username;
  dispatch(removeUserRequest(vendor, username));
  const dispatchSuccess = () => {
    dispatch(removeUserSuccess(vendor, username));
    dispatch(notifyAfterUserRemoval(vendor, username));
  };
  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendor}/users/${username}`, params)).then(
    (response) => {
      if (response.ok) {
        if (isLeaving) {
          return dispatch(refreshToken())
            .then(() => {
              const newVendor = getProfile(getState()).currentVendor;
              return newVendor ? dispatch(loadUsers(newVendor, true)) : Promise.resolve();
            })
            .then(dispatchSuccess);
        } else {
          dispatchSuccess();
        }
      } else {
        return response.json().then((data) => {
          dispatch(removeUserFailure(vendor, username, data.errorMessage));
          throw new SubmissionError({
            _error: data.errorMessage || 'Error while deleting user'
          });
        });
      }
    }
  );
};

export const HOME_INVITE_USER_REQUEST = 'HOME_INVITE_USER_REQUEST';
export const HOME_INVITE_USER_SUCCESS = 'HOME_INVITE_USER_SUCCESS';
export const HOME_INVITE_USER_FAILURE = 'HOME_INVITE_USER_FAILURE';

export const inviteUserRequest = (vendor, email) => ({
  type: HOME_INVITE_USER_REQUEST,
  vendor,
  email
});
export const inviteUserSuccess = (vendor, email) => ({
  type: HOME_INVITE_USER_SUCCESS,
  vendor,
  email
});
export const inviteUserFailure = (vendor, email, errorMessage) => ({
  type: HOME_INVITE_USER_FAILURE,
  vendor,
  email,
  errorMessage
});

export const inviteUser =
  ({ email, vendor }) =>
  (dispatch, getState) => {
    const state = getState();
    const params = {
      method: 'POST',
      headers: {
        Authorization: getToken(state)
      }
    };
    dispatch(inviteUserRequest(vendor, email));
    return dispatch(
      fetchProxyWithTokenRefresh(`/vendors/${vendor}/invitations/${email}`, params)
    ).then((response) => {
      if (response.ok) {
        dispatch(inviteUserSuccess(vendor, email));
        dispatch(
          notify({
            message: `An invitation link was sent to ${email}.`,
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      } else {
        return response.json().then((data) => {
          dispatch(
            inviteUserFailure(
              vendor,
              email,
              data.errorMessage || data.message || 'There was a problem'
            )
          );
          throw new SubmissionError({
            _error: data.errorMessage || 'Error while inviting user'
          });
        });
      }
    });
  };

export const HOME_GENERATE_SERVICE_ACCOUNT_REQUEST = 'HOME_GENERATE_SERVICE_ACCOUNT_REQUEST';
export const HOME_GENERATE_SERVICE_ACCOUNT_SUCCESS = 'HOME_GENERATE_SERVICE_ACCOUNT_SUCCESS';
export const HOME_GENERATE_SERVICE_ACCOUNT_FAILURE = 'HOME_GENERATE_SERVICE_ACCOUNT_FAILURE';

export const generateServiceUserRequest = (vendor) => ({
  type: HOME_GENERATE_SERVICE_ACCOUNT_REQUEST,
  vendor
});
export const generateServiceUserSuccess = (vendor, data) => {
  return {
    type: HOME_GENERATE_SERVICE_ACCOUNT_SUCCESS,
    vendor,
    data
  };
};

export const generateServiceUserFailure = (vendor) => ({
  type: HOME_GENERATE_SERVICE_ACCOUNT_FAILURE,
  vendor
});

export const generateServiceUser = (vendor, body) => (dispatch, getState) => {
  const state = getState();
  const params = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Authorization: getToken(state)
    }
  };
  dispatch(generateServiceUserRequest(vendor));
  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendor}/credentials`, params)).then(
    (response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(generateServiceUserSuccess(vendor, data));
          return dispatch(loadUsers(vendor, true)).then(() => data);
        } else {
          dispatch(generateServiceUserFailure(vendor, data.errorMessage));
          throw new SubmissionError({
            _error: data.errorMessage || 'Error while generating user'
          });
        }
      });
    }
  );
};

export const HOME_UPDATE_VENDOR_REQUEST = 'HOME_UPDATE_VENDOR_REQUEST';
export const HOME_UPDATE_VENDOR_SUCCESS = 'HOME_UPDATE_VENDOR_SUCCESS';
export const HOME_UPDATE_VENDOR_FAILURE = 'HOME_UPDATE_VENDOR_FAILURE';

export const updateVendorRequest = () => ({ type: HOME_UPDATE_VENDOR_REQUEST });
export const updateVendorSuccess = () => ({ type: HOME_UPDATE_VENDOR_SUCCESS });
export const updateVendorFailure = (errorMessage) => ({
  type: HOME_UPDATE_VENDOR_FAILURE,
  errorMessage
});

export const updateVendor = (vendor, body) => (dispatch, getState) => {
  const params = {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: {
      Authorization: getToken(getState())
    }
  };
  dispatch(updateVendorRequest());
  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendor}`, params)).then((response) => {
    if (response.ok) {
      return dispatch(fetchVendors(true)).then(() => {
        dispatch(updateVendorSuccess());
        dispatch(
          notify({
            message: 'The vendor details have been updated.',
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      });
    } else {
      return response.json().then((data) => {
        dispatch(updateVendorFailure(data.errorMessage || data.message || 'There was a problem'));
        throw new SubmissionError({
          _error: data.errorMessage || 'API call failed, contact support@keboola.com'
        });
      });
    }
  });
};
