import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import PropTypes from 'prop-types';
import { diffJson } from 'diff';

const AppChangesDiffModal = ({ changes, showModal, closeModalFn }) => {
  return (
    <Modal show={showModal} onHide={closeModalFn} bsSize="lg">
      <Modal.Header closeButton>
        <Modal.Title>Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {changes.map((change, index) => {
          return (
            <div key={index}>
              <h5>{change.attribute}</h5>
              <div className="admin-app-changes-diff">
                {diffJson(change.oldValue, change.newValue).map((part, partIndex) => {
                  return (
                    <span
                      className={part.added ? 'added-part' : part.removed ? 'removed-part' : ''}
                      key={partIndex}
                    >
                      {part.value}
                    </span>
                  );
                })}
              </div>
            </div>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

AppChangesDiffModal.propTypes = {
  changes: PropTypes.array.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired
};

export default AppChangesDiffModal;
