import * as loginActionTypes from '../actions/login';
import * as logoutActionTypes from '../actions/logout';
import * as mfaActions from '../actions/mfa';

export const initialState = {
  email: null,
  isWaitingForMfa: false,
  session: null,
  challenge: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case mfaActions.AUTH_LOGIN_WAITING_FOR_MFA:
      return {
        isWaitingForMfa: true,
        session: action.payload.data.session,
        email: action.payload.data.email,
        challenge: action.payload.data.challenge
      };
    case mfaActions.AUTH_LOGIN_CANCEL_MFA:
    case loginActionTypes.AUTH_LOGIN_RECEIVE_DATA:
    case logoutActionTypes.AUTH_LOGOUT_SUCCESS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export const getIsWaitingForMfa = (state) => {
  return state.auth.mfa.isWaitingForMfa;
};

export const getMfaSession = (state) => {
  return state.auth.mfa.session;
};

export const getEmail = (state) => {
  return state.auth.mfa.email;
};

export const getMfaChallenge = (state) => {
  return state.auth.mfa.challenge;
};
