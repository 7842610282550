import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const icons = {
  danger: <FontAwesomeIcon icon={'exclamation-triangle'} />,
  success: <FontAwesomeIcon icon={'check'} />
};

const InlineNotification = ({ type, children }) => {
  return (
    <div className={'alert alert-' + type}>
      {icons[type]} {children}
    </div>
  );
};
InlineNotification.propTypes = {
  type: PropTypes.oneOf(['danger', 'success']).isRequired,
  children: PropTypes.node.isRequired
};

export default InlineNotification;
