import React from 'react';
import PropTypes from 'prop-types';

const NewLineToBr = ({ text }) => {
  const lines = text.split('\n');
  const result = [];

  lines.forEach((item, index) => {
    result.push(<span key={index}>{item}</span>);
    if (index < lines.length - 1) {
      result.push(<br key={index + '-br'} />);
    }
  });

  return <div>{result}</div>;
};
NewLineToBr.propTypes = {
  text: PropTypes.string.isRequired
};

export default NewLineToBr;
