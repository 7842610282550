import FarceActions from 'farce/Actions';
import { SubmissionError } from 'redux-form';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';
import { routes } from '../../../routesConstants';
import config from '../../../../config';

const ADMIN_UPDATE_APP_REQUEST = 'ADMIN_UPDATE_APP_REQUEST';
const ADMIN_UPDATE_APP_SUCCESS = 'ADMIN_UPDATE_APP_SUCCESS';
const ADMIN_UPDATE_APP_FAILURE = 'ADMIN_UPDATE_APP_FAILURE';

const updateAppRequest = () => {
  return {
    type: ADMIN_UPDATE_APP_REQUEST
  };
};

const updateAppFailure = () => {
  return {
    type: ADMIN_UPDATE_APP_FAILURE
  };
};

const updateAppSuccess = () => {
  return {
    type: ADMIN_UPDATE_APP_SUCCESS
  };
};

const redirectAfterUpdateAppSuccess = (appId) => {
  return FarceActions.push(config.BASE_PATH + `${routes.ROUTE_ADMIN_COMPONENTS}/${appId}`);
};

export const updateApp = (appId, data) => (dispatch, getState) => {
  dispatch(updateAppRequest());

  const params = {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      Authorization: getToken(getState())
    }
  };

  return dispatch(fetchProxyWithTokenRefresh(`/admin/apps/${appId}`, params)).then((response) => {
    if (response.ok) {
      dispatch(updateAppSuccess());
      dispatch(redirectAfterUpdateAppSuccess(appId));
    } else {
      dispatch(updateAppFailure());
      return response.json().then((error) => {
        throw new SubmissionError({
          _error: error.errorMessage || 'Error while updating component.'
        });
      });
    }
  });
};
