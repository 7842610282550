import * as actions from '../../actions/apps';
import * as deprecateAppActions from '../../actions/apps/deprecateApp';
import { getFilter } from './filter';
import { sortApps } from '../../../../utils/helpers';

export const initialState = {
  appsByVendor: {},
  errorMessage: null
};

export default (state = initialState, action) => {
  let vendorId,
    vendorApps,
    appToUpdate,
    index,
    partBefore,
    partAfter,
    newVendorApps,
    newAppsAfterUpdate;

  switch (action.type) {
    case actions.HOME_FETCH_APPS_REQUEST:
      return { ...state, errorMessage: null };
    case actions.HOME_FETCH_APPS_SUCCESS:
      const newAppsByVendor = {
        ...state.appsByVendor,
        [action.vendor]: [...(state.appsByVendor[action.vendor] || []), ...action.data]
      };

      return { ...state, appsByVendor: newAppsByVendor, errorMessage: null };
    case actions.HOME_FETCH_APPS_FAILURE:
      return { ...state, errorMessage: action.errorMessage };
    case actions.HOME_DELETE_APP_SUCCESS:
      const vendorAppsBeforeDelete = state.appsByVendor[action.vendor] || [];
      const vendorAppsAfterDelete = vendorAppsBeforeDelete.filter((a) => a.id !== action.appId);
      const newAppsAfterDelete = { ...state.appsByVendor, [action.vendor]: vendorAppsAfterDelete };
      return { ...state, appsByVendor: newAppsAfterDelete, errorMessage: null };
    case actions.HOME_APP_APPROVAL_SUCCESS:
    case actions.HOME_FETCH_APP_DETAIL_SUCCESS:
    case deprecateAppActions.HOME_DEPRECATE_APP_SUCCESS:
      const appAfterUpdate = action.payload.app;
      vendorId = appAfterUpdate.vendor.id;
      vendorApps = state.appsByVendor[vendorId] || [];
      if (vendorApps.length === 0) {
        return state;
      }
      appToUpdate = vendorApps.find((app) => app.id === appAfterUpdate.id);
      index = vendorApps.indexOf(appToUpdate);
      partBefore = index === 0 ? [] : vendorApps.slice(0, index);
      partAfter = index === vendorApps.length - 1 ? [] : vendorApps.slice(index + 1);
      newVendorApps = partBefore.concat([appAfterUpdate], partAfter);
      newAppsAfterUpdate = { ...state.appsByVendor, [vendorId]: newVendorApps };
      return { ...state, appsByVendor: newAppsAfterUpdate, errorMessage: null };
    default:
      return state;
  }
};

const localizeState = (state) => state.home.apps.list;

function appContains(app, value) {
  const propsToSearch = ['id', 'name', 'createdBy', 'type'];
  return propsToSearch.reduce(
    (memo, prop) => memo || (app[prop] || '').toLowerCase().indexOf(value.toLowerCase()) >= 0,
    false
  );
}

export const getVendorApps = (state, vendor) => {
  const allAppsByVendor = localizeState(state).appsByVendor;
  return allAppsByVendor.hasOwnProperty(vendor) ? allAppsByVendor[vendor] : [];
};

export const getFilteredVendorApps = (state, vendor) => {
  const filter = getFilter(state);
  const vendorApps = getVendorApps(state, vendor);
  return sortApps(vendorApps.filter((a) => appContains(a, filter)));
};

export const hasLoadedApps = (state, vendor) =>
  localizeState(state).appsByVendor[vendor] !== undefined;
