import * as listActions from '../../actions/apps';
import * as deleteActions from '../../actions/appDelete';
import * as approveActions from '../../actions/appApprove';
import * as rejectionActions from '../../actions/appReject';
import * as appDetailActions from '../../actions/appDetail';

export default (state = [], action) => {
  switch (action.type) {
    case listActions.ADMIN_APPS_FETCH_SUCCESS:
      return [...state, ...action.payload];
    case deleteActions.ADMIN_DELETE_APP_SUCCESS:
      return state.filter((app) => app.id !== action.payload);
    case approveActions.ADMIN_APPROVE_APP_SUCCESS:
    case rejectionActions.ADMIN_REJECT_APP_SUCCESS:
    case appDetailActions.ADMIN_GET_APP_DETAIL_SUCCESS:
      if (state.length === 0) {
        return state;
      }
      const newApp = action.payload.app;
      const approvedAppInStore = state.find((app) => app.id === newApp.id);
      const index = state.indexOf(approvedAppInStore);
      const partBefore = index === 0 ? [] : state.slice(0, index);
      const partAfter = index === state.length - 1 ? [] : state.slice(index + 1);
      return partBefore.concat([newApp], partAfter);
    default:
      return state;
  }
};

export const getApps = (state) => {
  return state.admin.apps.list;
};
