import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { reduxForm } from 'redux-form';

import PropTypes from 'prop-types';
import InlineNotification from '../../../components/InlineNotification';

const FORM_ID = 'DisableMfaModal';

class DisableMfaModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleSubmit() {
    const { user, disableMfaFn } = this.props;
    return disableMfaFn(user.username).then(() => {
      this.closeModal();
    });
  }

  closeModal() {
    this.props.destroy();
    this.props.closeModalFn();
  }

  render() {
    if (!this.props.show) {
      return null;
    }
    const { handleSubmit, user, show, error } = this.props;
    return (
      <Modal show={show} onHide={this.closeModal}>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Disable MFA</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <p>
              You're about to disable MFA for the user <strong>{user.username}</strong>.
            </p>
            <p>
              Proceed with caution, this action have <strong>security implications.</strong>
            </p>
            {error && <InlineNotification type="danger">{error}</InlineNotification>}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                type="submit"
                bsSize="large"
                disabled={this.props.submitting}
                bsStyle="danger"
              >
                Disable
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

DisableMfaModal.propTypes = {
  show: PropTypes.bool.isRequired,
  disableMfaFn: PropTypes.func.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  user: PropTypes.object
};

export default reduxForm({
  form: FORM_ID
})(DisableMfaModal);
