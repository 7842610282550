export default [
  {
    title: 'Docker Extension Quick Start',
    link: 'https://developers.keboola.com/extend/component/tutorial/'
  },
  {
    title: 'Common Interface',
    link: 'https://developers.keboola.com/extend/common-interface/'
  },
  {
    title: 'About Docker',
    link: 'https://developers.keboola.com/extend/component/docker-tutorial/'
  },
  {
    title: 'Component Registration',
    link: 'https://developers.keboola.com/extend/publish/'
  },
  {
    title: 'Component Deployment',
    link: 'https://developers.keboola.com/extend/component/deployment/'
  }
];
