import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { fetchProxyWithTokenRefresh } from '../../actions';
import { getToken } from '../../auth/reducers/tokens';
import { hasLoadedApps } from '../reducers/apps/list';

export const HOME_FETCH_APPS_REQUEST = 'HOME_FETCH_APPS_REQUEST';
export const HOME_FETCH_APPS_SUCCESS = 'HOME_FETCH_APPS_SUCCESS';
export const HOME_FETCH_APPS_FAILURE = 'HOME_FETCH_APPS_FAILURE';

export const loadAppsRequest = (vendor) => ({ type: HOME_FETCH_APPS_REQUEST, vendor: vendor });
export const loadAppsSuccess = (vendor, data) => ({
  type: HOME_FETCH_APPS_SUCCESS,
  data: data,
  vendor: vendor
});
export const loadAppsFailure = (vendor, msg) => ({
  type: HOME_FETCH_APPS_FAILURE,
  errorMessage: msg,
  vendor: vendor
});

export const loadVendorApps =
  (vendor, forceLoad) =>
  (dispatch, getState, offset = 0, limit = 1000) => {
    const state = getState();
    if (!forceLoad && hasLoadedApps(state, vendor)) {
      return Promise.resolve('ok');
    }

    if (offset === 0) {
      dispatch(loadAppsRequest(vendor));
    }

    const params = {
      method: 'GET',
      headers: {
        Authorization: getToken(state)
      }
    };

    const query = new URLSearchParams({
      offset,
      limit
    });

    return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendor}/apps?${query}`, params)).then(
      (response) => {
        return response.json().then((data) => {
          if (response.ok) {
            dispatch(loadAppsSuccess(vendor, data));

            if (data.length < limit) {
              return 'ok';
            }

            return loadVendorApps(vendor, true)(dispatch, getState, offset + limit);
          } else {
            dispatch(loadAppsFailure(vendor, data.errorMessage));
            return Promise.reject({
              status: response.status,
              message: data.errorMessage || "Could not fetch vendor's apps"
            });
          }
        });
      }
    );
  };

export const HOME_APPS_SET_FILTER = 'HOME_APPS_SET_FILTER';
export const setFilter = (filter) => ({ type: HOME_APPS_SET_FILTER, filter });

export const HOME_FETCH_APP_DETAIL_REQUEST = 'HOME_FETCH_APP_DETAIL_REQUEST';
export const HOME_FETCH_APP_DETAIL_SUCCESS = 'HOME_FETCH_APP_DETAIL_SUCCESS';
export const HOME_FETCH_APP_DETAIL_FAILURE = 'HOME_FETCH_APP_DETAIL_FAILURE';

const fetchAppDetailRequest = () => {
  return {
    type: HOME_FETCH_APP_DETAIL_REQUEST
  };
};

export const fetchAppDetailSuccess = (data) => {
  return {
    type: HOME_FETCH_APP_DETAIL_SUCCESS,
    payload: {
      app: data
    }
  };
};

const fetchAppDetailFailure = () => ({
  type: HOME_FETCH_APP_DETAIL_FAILURE
});

export const fetchAppDetail = (vendorId, appId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    dispatch(fetchAppDetailRequest());
    dispatch(
      fetchProxyWithTokenRefresh(`/vendors/${vendorId}/apps/${appId}`, {
        method: 'GET',
        headers: {
          Authorization: getToken(getState())
        }
      })
    ).then((response) => {
      response.json().then((data) => {
        if (response.ok) {
          dispatch(fetchAppDetailSuccess(data));
          return resolve('ok');
        } else {
          dispatch(fetchAppDetailFailure());
          return reject({
            status: response.status,
            message: data.errorMessage || 'Could not fetch component detail.'
          });
        }
      });
    });
  });
};

export const HOME_DELETE_APP_REQUEST = 'HOME_DELETE_APP_REQUEST';
export const HOME_DELETE_APP_SUCCESS = 'HOME_DELETE_APP_SUCCESS';
export const HOME_DELETE_APP_FAILURE = 'HOME_DELETE_APP_FAILURE';

export const deleteAppRequest = (appId) => ({ type: HOME_DELETE_APP_REQUEST, appId });
export const deleteAppSuccess = (appId, vendor) => ({
  type: HOME_DELETE_APP_SUCCESS,
  appId,
  vendor
});
export const deleteAppFailure = (appId, msg) => ({
  type: HOME_DELETE_APP_FAILURE,
  errorMessage: msg,
  appId
});

export const deleteApp = (vendor, appId) => (dispatch, getState) => {
  const state = getState();
  dispatch(deleteAppRequest(appId));
  const params = {
    method: 'DELETE',
    headers: {
      Authorization: getToken(state)
    }
  };

  return dispatch(fetchProxyWithTokenRefresh(`/vendors/${vendor}/apps/${appId}`, params)).then(
    (response) => {
      if (!response.ok) {
        return response.json().then((data) => dispatch(deleteAppFailure(appId, data.errorMessage)));
      } else {
        dispatch(deleteAppSuccess(appId, vendor));
        dispatch(
          notify({
            message: `The component has been removed.`,
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      }
    }
  );
};

export const HOME_APP_APPROVAL_REQUEST = 'HOME_APP_APPROVAL_REQUEST';
export const HOME_APP_APPROVAL_SUCCESS = 'HOME_APP_APPROVAL_SUCCESS';
export const HOME_APP_APPROVAL_FAILURE = 'HOME_APP_APPROVAL_FAILURE';

const approveAppRequest = () => ({ type: HOME_APP_APPROVAL_REQUEST });

export const approveAppSuccess = (data) => ({
  type: HOME_APP_APPROVAL_SUCCESS,
  payload: {
    app: data
  }
});

const approveAppFailure = () => ({ type: HOME_APP_APPROVAL_FAILURE });

export const approveApp = (vendor, appId) => (dispatch, getState) => {
  const state = getState();
  dispatch(approveAppRequest());
  const params = {
    method: 'POST',
    headers: {
      Authorization: getToken(state)
    }
  };
  return dispatch(
    fetchProxyWithTokenRefresh(`/vendors/${vendor}/apps/${appId}/publish`, params)
  ).then((response) => {
    return response.json().then((data) => {
      if (response.ok) {
        dispatch(approveAppSuccess(data));
        dispatch(
          notify({
            message: `The component has been sent for approval. We'll respond shortly.`,
            status: 'success',
            dismissible: true,
            dismissAfter: 6000,
            position: POSITIONS.topCenter
          })
        );
        return 'ok';
      } else {
        dispatch(approveAppFailure());
        throw new SubmissionError({
          _error: data.errorMessage || 'Error while requesting component approval.'
        });
      }
    });
  });
};
