import * as actionTypes from '../../actions/vendors.js';
import { getUsersFilter } from './filter';

export const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HOME_FETCH_USERS_SUCCESS:
      return {
        ...state.data,
        [action.vendor]: action.data
      };
    case actionTypes.HOME_USER_REMOVE_SUCCESS:
      const oldVendorUsers = state[action.vendor] ? state[action.vendor] : [];
      const newVendorUsers = oldVendorUsers.filter((u) => u.username !== action.username);
      return {
        ...state.data,
        [action.vendor]: newVendorUsers
      };
    default:
      return state;
  }
};

const localizeState = (state) => state.home.developers.list;

const includes = (a, b) => {
  return a.toLowerCase().includes(b.toLowerCase());
};

const containsUser = (user, filter) =>
  includes(user.username, filter) || includes(user.name, filter);

export const hasUsers = (state, vendor) => localizeState(state)[vendor] !== undefined;

export const getUsers = (state, vendor) => localizeState(state)[vendor] || [];

export const getHumanUsersFiltered = (state, vendor) => {
  const filter = getUsersFilter(state);
  return getUsers(state, vendor).filter((user) => containsUser(user, filter));
};
