import { SubmissionError } from 'redux-form';
import { addNotification as notify, POSITIONS } from 'reapop';

import { getToken } from '../../auth/reducers/tokens';
import { fetchProxyWithTokenRefresh } from '../../actions';

export const ADMIN_APPROVE_APP_REQUEST = 'ADMIN_APPROVE_APP_REQUEST';
export const ADMIN_APPROVE_APP_SUCCESS = 'ADMIN_APPROVE_APP_SUCCESS';
export const ADMIN_APPROVE_APP_FAILURE = 'ADMIN_APPROVE_APP_FAILURE';

export const approveAppRequest = () => {
  return {
    type: ADMIN_APPROVE_APP_REQUEST
  };
};

export const approveAppSuccess = (app) => {
  return {
    type: ADMIN_APPROVE_APP_SUCCESS,
    payload: {
      app: app
    }
  };
};
export const approveAppFailure = () => {
  return {
    type: ADMIN_APPROVE_APP_FAILURE
  };
};

export const approveApp = (appId) => (dispatch, getState) => {
  dispatch(approveAppRequest());
  const params = {
    method: 'POST',
    headers: {
      Authorization: getToken(getState())
    }
  };
  return dispatch(fetchProxyWithTokenRefresh(`/admin/apps/${appId}/publish`, params)).then(
    (response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(approveAppSuccess(data));
          dispatch(
            notify({
              message: `The component has been published.`,
              status: 'success',
              dismissible: true,
              dismissAfter: 6000,
              position: POSITIONS.topCenter
            })
          );
          return 'ok';
        } else {
          dispatch(approveAppFailure());
          throw new SubmissionError({
            _error: data.errorMessage || 'Error while publishing component.'
          });
        }
      });
    }
  );
};
