import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import ReactBootstrapMenuItem from 'react-bootstrap/lib/MenuItem';

import NavItem from '../components/NavItem';
import Link from '../components/Link';
import MenuItem from '../components/MenuItem';
import { routes } from '../routesConstants';
import Logo from '../components/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavbarHome = ({
  allVendors,
  currentVendor,
  onSelectVendor,
  isAdmin,
  logoutFn,
  currentPath
}) => {
  return (
    <Navbar>
      <Navbar.Header>
        <Navbar.Brand>
          <Link className="navbar-brand" to={routes.ROUTE_HOME}>
            <Logo />
          </Link>
        </Navbar.Brand>
      </Navbar.Header>
      <Nav activeHref={currentPath} pullRight>
        <NavItem href={routes.ROUTE_HOME}>Components</NavItem>
        <NavItem href={routes.ROUTE_HOME_DEVELOPERS}>Developers</NavItem>
        <NavItem href={routes.ROUTE_HOME_SERVICE_ACCOUNTS}>Service accounts</NavItem>
        <NavDropdown title={currentVendor} id="admin-navbar-nav-account-dropdown">
          <ReactBootstrapMenuItem header>Vendor</ReactBootstrapMenuItem>
          {allVendors.map((vendorId, idx) => (
            <ReactBootstrapMenuItem
              key={idx}
              disabled={vendorId === currentVendor}
              onClick={() => (vendorId !== currentVendor ? onSelectVendor(vendorId) : null)}
              className={vendorId === currentVendor ? 'current-vendor' : ''}
            >
              {vendorId}
              {vendorId === currentVendor && (
                <span>
                  {' '}
                  <FontAwesomeIcon icon={'check'} pull="right" className="text-color-primary" />
                </span>
              )}
            </ReactBootstrapMenuItem>
          ))}
          <ReactBootstrapMenuItem divider />
          {isAdmin && <MenuItem href={routes.ROUTE_ADMIN}>Switch to admin</MenuItem>}
          {isAdmin && <ReactBootstrapMenuItem divider />}
          <MenuItem href={routes.ROUTE_HOME_PROFILE}>
            Profile <FontAwesomeIcon icon={'user'} pull="right" />
          </MenuItem>
          <ReactBootstrapMenuItem onClick={logoutFn}>
            Logout <FontAwesomeIcon icon={'long-arrow-alt-left'} pull="right" />
          </ReactBootstrapMenuItem>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
};

NavbarHome.propTypes = {
  logoutFn: PropTypes.func.isRequired,
  allVendors: PropTypes.array.isRequired,
  currentVendor: PropTypes.string.isRequired,
  onSelectVendor: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired
};

export default NavbarHome;
