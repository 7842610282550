import validateJs from '../../../utils/validate';
import { filterObject } from '../../../utils/helpers';
import { COMPONENT_TYPE_CODE_PATTERN } from './App';

const allowedProperties = [
  'name',
  'type',
  {
    property: 'repository',
    allowedProperties: ['type', 'uri', 'tag', 'options']
  },
  'shortDescription',
  'longDescription',
  'licenseUrl',
  'documentationUrl',
  'sourceCodeUrl',
  'network',
  'defaultBucket',
  'defaultBucketStage',
  'uiOptions',
  'imageParameters',
  'stackParameters',
  'configurationSchema',
  'configurationRowSchema',
  'configurationDescription',
  'emptyConfiguration',
  'emptyConfigurationRow',
  'createConfigurationRowSchema',
  'actions',
  'fees',
  'limits',
  'logger',
  'loggerConfiguration',
  'stagingStorageInput',
  'stagingStorageOutput',
  'dataTypeSupport',
  'allowedProcessorPosition'
];

const JSON_FIELD_MAX_LENGTH = 256 * 1024; // same limit is in Developer Portal (256kB)
const SMALLER_JSON_FIELD_MAX_LENGTH = 4 * 1024; // to have something smaller and sane
const TEXT_FIELD_MAX_LENGTH = 64 * 1024; // limit of TEXT in MySQL

const filterNotAllowedFields = (values) => {
  return filterObject(allowedProperties, values);
};

const homeConstraints = {
  name: {
    presence: { allowEmpty: false },
    length: {
      maximum: 128
    }
  },
  type: {
    presence: { allowEmpty: false },
    length: {
      maximum: 20
    }
  },
  shortDescription: {
    length: {
      maximum: 255
    }
  },
  'repository.uri': {
    notStartLikeUrl: {
      message: 'cannot be an URL. Please use only [repository/]vendor/component part.'
    },
    length: {
      maximum: 255
    }
  },
  licenseUrl: {
    url: true,
    length: {
      maximum: 255
    }
  },
  documentationUrl: {
    url: true,
    length: {
      maximum: 255
    }
  },
  sourceCodeUrl: {
    url: true,
    length: {
      maximum: 255
    }
  },
  imageParameters: {
    object: {
      maximumLength: JSON_FIELD_MAX_LENGTH
    }
  },
  stackParameters: {
    object: {
      maximumLength: JSON_FIELD_MAX_LENGTH
    }
  },
  configurationSchema: {
    object: {
      maximumLength: JSON_FIELD_MAX_LENGTH
    }
  },
  emptyConfiguration: {
    object: {
      maximumLength: JSON_FIELD_MAX_LENGTH
    }
  },
  emptyConfigurationRow: {
    object: {
      maximumLength: JSON_FIELD_MAX_LENGTH
    }
  },
  configurationRowSchema: {
    object: {
      maximumLength: JSON_FIELD_MAX_LENGTH
    }
  },
  createConfigurationRowSchema: {
    object: {
      maximumLength: JSON_FIELD_MAX_LENGTH
    }
  },
  configurationDescription: {
    length: {
      maximum: TEXT_FIELD_MAX_LENGTH
    }
  },
  loggerConfiguration: {
    object: {
      maximumLength: SMALLER_JSON_FIELD_MAX_LENGTH
    }
  },
  actions: {
    array: {
      maximumLength: SMALLER_JSON_FIELD_MAX_LENGTH
    }
  },
  uiOptions: {
    array: {
      maximumLength: SMALLER_JSON_FIELD_MAX_LENGTH
    }
  },
  longDescription: {
    length: {
      maximum: TEXT_FIELD_MAX_LENGTH
    }
  }
};

const validateWithConstraints = (app, constraints) => {
  let errors = {
    repository: {
      options: {}
    }
  };
  const result = validateJs.validate(app, constraints);
  if (result) {
    Object.keys(result).forEach((key) => {
      switch (key) {
        case 'repository.type':
          errors.repository = { ...errors.repository, type: result[key][0] };
          break;
        case 'repository.tag':
          errors.repository = { ...errors.repository, tag: result[key][0] };
          break;
        case 'repository.uri':
          errors.repository = { ...errors.repository, uri: result[key][0] };
          break;
        default:
          errors[key] = result[key][0];
      }
    });
  }

  if (app.type === COMPONENT_TYPE_CODE_PATTERN && !errors.configurationSchema) {
    if (
      !app.configurationSchema?.supported_components ||
      (typeof app.configurationSchema.supported_components === 'object' &&
        !Array.isArray(app.configurationSchema.supported_components)) ||
      app.configurationSchema.supported_components.length < 1
    ) {
      errors.configurationSchema =
        'Please specify compatible component IDs in "supported_components" parameter.';
    } else {
      errors.configurationSchema = undefined;
    }
  }

  return errors;
};

const validate = (app) => {
  return validateWithConstraints(app, homeConstraints);
};

export {
  allowedProperties,
  filterNotAllowedFields,
  homeConstraints as constraints,
  validate,
  validateWithConstraints
};
