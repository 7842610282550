import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

import { DescriptionBlock } from '../custom';

const BooleanField = ({ formId, input, label, description, meta: { touched, error } }) => {
  const checked = input.value === true;
  return (
    <FormGroup controlId={`${formId}-${input.name}`}>
      <Checkbox inline checked={checked} {...input}>
        {label}
      </Checkbox>
      {touched && error && <HelpBlock>{error}</HelpBlock>}
      {description && <DescriptionBlock>{description}</DescriptionBlock>}
    </FormGroup>
  );
};
BooleanField.propTypes = {
  formId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.node,
  meta: PropTypes.object.isRequired
};

export default BooleanField;
