import * as serviceAccountsActions from '../../actions/serviceAccounts';
import * as vendorsActions from '../../actions/vendors';
import { getFilterQuery } from './filter';

export const initialState = {};

export default (state = initialState, action) => {
  let vendorServiceAccounts, newVendorServiceAccounts;
  switch (action.type) {
    case serviceAccountsActions.HOME_FETCH_SERVICE_ACCOUNTS_SUCCESS:
      return {
        [action.payload.vendorId]: action.payload.data
      };
    case vendorsActions.HOME_USER_REMOVE_SUCCESS:
      vendorServiceAccounts = state[action.vendor] ? state[action.vendor] : [];
      newVendorServiceAccounts = vendorServiceAccounts.filter((account) => {
        return account.username !== action.username;
      });
      return {
        ...state,
        [action.vendor]: newVendorServiceAccounts
      };
    case vendorsActions.HOME_GENERATE_SERVICE_ACCOUNT_SUCCESS:
      vendorServiceAccounts = state[action.vendor] ? state[action.vendor] : [];
      const { password, ...newAccount } = action.data;
      vendorServiceAccounts.push(newAccount);
      return {
        ...state,
        [action.vendor]: vendorServiceAccounts
      };
    default:
      return state;
  }
};

export const getServiceAccounts = (state, vendorId) => {
  return state.home.serviceAccounts.list[vendorId] ? state.home.serviceAccounts.list[vendorId] : [];
};

export const getServiceAccountsFiltered = (state, vendorId) => {
  return getServiceAccounts(state, vendorId).filter((account) => {
    return account.username.toLowerCase().indexOf(getFilterQuery(state)) !== -1;
  });
};
