import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';

import { validate } from '../../models/SelectVendorForm';
import { SelectField } from '../../../../common/form/fields';
import InlineNotification from '../../../../components/InlineNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FORM_ID = 'SelectVendorForm';

class SelectVendorForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(formValues) {
    const { selectVendorFn } = this.props;
    return selectVendorFn(formValues);
  }

  render() {
    const { handleSubmit, vendors, error, pristine, submitting, switchToCreateVendorFn } =
      this.props;
    return (
      <div className="static-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Vendor</h4>
            </div>
            <p className="text-center">You're not a member of any vendor.</p>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <div className="modal-body">
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-lg btn-primary"
                    onClick={() => {
                      switchToCreateVendorFn();
                    }}
                  >
                    <FontAwesomeIcon icon={'plus'} /> Create new vendor
                  </button>
                </div>
                <br />
                <p className="text-center">OR</p>

                <Field
                  formId={FORM_ID}
                  name="vendorId"
                  component={SelectField}
                  data={vendors}
                  nameField="name"
                  valueField="id"
                  placeholder="Select the vendor you want to join ..."
                />

                {error && <InlineNotification type="danger">{error}</InlineNotification>}
              </div>
              <div className="modal-footer">
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    disabled={pristine || submitting}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

SelectVendorForm.propTypes = {
  vendors: PropTypes.array.isRequired,
  selectVendorFn: PropTypes.func.isRequired,
  switchToCreateVendorFn: PropTypes.func.isRequired
};

export default reduxForm({
  validate: validate,
  form: FORM_ID
})(SelectVendorForm);
