import { hasVendors } from '../reducers/vendors';
import { fetchProxy } from '../../actions';

export const OPEN_FETCH_VENDORS_SUCCESS = 'OPEN_FETCH_VENDORS_SUCCESS';
export const OPEN_FETCH_VENDORS_REQUEST = 'OPEN_FETCH_VENDORS_REQUEST';
export const OPEN_FETCH_VENDORS_FAILURE = 'OPEN_FETCH_VENDORS_FAILURE';

const vendorsFetchRequest = () => {
  return {
    type: OPEN_FETCH_VENDORS_REQUEST
  };
};

export const vendorsFetchSuccess = (data) => {
  return {
    type: OPEN_FETCH_VENDORS_SUCCESS,
    payload: data
  };
};

const vendorsFetchFailure = () => ({
  type: OPEN_FETCH_VENDORS_FAILURE
});

export const fetchVendors = (forceReload) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    if (!forceReload && hasVendors(getState())) {
      return resolve('ok');
    }
    dispatch(vendorsFetchRequest());
    dispatch(fetchProxy('/vendors')).then((response) => {
      response.json().then((data) => {
        if (!response.ok) {
          dispatch(vendorsFetchFailure());
          return reject({
            status: response.status,
            message: data.errorMessage || 'Could not fetch vendors'
          });
        }

        dispatch(vendorsFetchSuccess(data));
        return resolve('ok');
      });
    });
  });
};
