import { combineReducers } from 'redux';
import * as vendorActionTypes from '../actions/vendors';

const list = (state = [], action) => {
  switch (action.type) {
    case vendorActionTypes.OPEN_FETCH_VENDORS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  list
});

export const hasVendors = (state) => {
  return state.open.vendors.list.length > 0;
};

export const getVendorsList = (state) => {
  return state.open.vendors.list.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });
};
