import FarceActions from 'farce/Actions';
import { SubmissionError } from 'redux-form';

import { getMfaSession, getEmail, getMfaChallenge } from '../reducers/mfa';
import { decodeTokenPayload } from './token';
import { waitingForMfa } from './mfa';
import { routes } from '../../../routesConstants';
import { fetchProxy } from '../../actions';
import config from '../../../../config';

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';
export const AUTH_LOGIN_RECEIVE_DATA = 'AUTH_LOGIN_RECEIVE_DATA';

const loginRequest = () => {
  return {
    type: AUTH_LOGIN_REQUEST
  };
};

export const loginReceiveData = (data) => {
  return {
    type: AUTH_LOGIN_RECEIVE_DATA,
    payload: {
      data: data,
      tokenPayload: decodeTokenPayload(data.token)
    }
  };
};

const loginSuccess = () => {
  return {
    type: AUTH_LOGIN_SUCCESS
  };
};

const loginFailure = () => {
  return {
    type: AUTH_LOGIN_FAILURE
  };
};

const redirectAfterLoginSuccess = () => {
  return FarceActions.push(config.BASE_PATH + routes.ROUTE_HOME);
};

export const login = (credentials) => (dispatch) => {
  dispatch(loginRequest());

  return dispatch(
    fetchProxy('/auth/login', {
      method: 'POST',
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  ).then(
    (response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(loginSuccess());
          if (!!data.challenge && !!data.session) {
            // mfa
            dispatch(
              waitingForMfa({
                email: credentials.email,
                session: data.session,
                challenge: data.challenge
              })
            );
          } else {
            dispatch(loginReceiveData(data));
            dispatch(redirectAfterLoginSuccess());
          }
        } else {
          dispatch(loginFailure());
          throw new SubmissionError({
            _error: data.errorMessage || 'There was a problem signing you in'
          });
        }
      });
    },
    (error) => {
      // todo: network err, global reducer?
    }
  );
};

export const loginWithMfa = (formValues) => (dispatch, getState) => {
  dispatch(loginRequest());

  let credentials = {
    email: getEmail(getState()),
    session: getMfaSession(getState()),
    code: formValues.code
  };

  const mfaChallenge = getMfaChallenge(getState());

  if (mfaChallenge !== null) {
    credentials.challenge = mfaChallenge;
  }

  return dispatch(
    fetchProxy('/auth/login', {
      method: 'POST',
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  ).then(
    (response) => {
      return response.json().then((data) => {
        if (response.ok) {
          dispatch(loginSuccess());
          dispatch(loginReceiveData(data));
          dispatch(redirectAfterLoginSuccess());
        } else {
          dispatch(loginFailure());
          throw new SubmissionError({
            _error: data.errorMessage || 'There was a problem signing you in'
          });
        }
      });
    },
    (error) => {
      // todo: network err, global reducer?
    }
  );
};
