import React from 'react';

import Footer from './Footer';
import NavbarOpen from './NavbarOpen';
import config from '../../config';

export default ({
  children,
  match: { location } // from router
}) => {
  return (
    <div>
      <NavbarOpen currentPath={location.pathname.slice(config.BASE_PATH.length)} />
      {children}
      <div className="container">
        <Footer />
      </div>
    </div>
  );
};
