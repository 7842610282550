import { loadingBarReducer } from 'react-redux-loading-bar';
import { persistCombineReducers } from 'redux-persist';
import { reducer as notificationsReducer } from 'reapop';
import foundReducer from 'found/foundReducer';
import { reducer as formReducer } from 'redux-form';
import configureStorage from '../configureStorage';
import auth from './auth/reducers/index';
import open from './open/reducers/index';
import home from './home/reducers/index';
import admin from './admin/reducers/index';

const devPortalApp = persistCombineReducers(configureStorage(), {
  // 3rd party reducers
  found: foundReducer,
  loadingBar: loadingBarReducer,
  form: formReducer,
  notifications: notificationsReducer(),
  // our reducers (per module)
  auth,
  open,
  home,
  admin
});

export default devPortalApp;
