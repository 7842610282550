import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { reduxForm, Field } from 'redux-form';
import { QRCodeSVG } from 'qrcode.react';

import { TextField } from '../../../common/form/fields';
import { validate } from '../models/ConfirmMfaForm';
import InlineNotification from '../../../components/InlineNotification';
import { insertCharAfterEachNthChar } from '../../../utils/helpers';

const generateKeyUri = (email, code) => {
  return `otpauth://totp/${email}?secret=${code}&issuer=Keboola%20Developer%20Portal`;
};

const FORM_ID = 'ConfirmSoftwareMfaModal';

class ConfirmSoftwareMfaModal extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      secretCodeRevealed: false
    };
  }

  handleSubmit(formValues) {
    const { confirmSoftwareMfaFn, closeModalFn } = this.props;
    return confirmSoftwareMfaFn(formValues).then(() => {
      closeModalFn();
    });
  }

  render() {
    const { handleSubmit, closeModalFn, show, secretCode, error } = this.props;
    return (
      <Modal show={show} onHide={closeModalFn}>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm MFA setup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {secretCode ? this.renderCode() : <p>Generating ...</p>}
            </div>
            <Field
              formId={FORM_ID}
              name="code"
              component={TextField}
              label="Code"
              placeholder="Enter code from application"
              autoFocus
            />
            {error && <InlineNotification type="danger">{error}</InlineNotification>}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                type="submit"
                bsSize="large"
                disabled={this.props.submitting || this.props.pristine}
                bsStyle="primary"
              >
                Confirm
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  renderCode() {
    const { email, secretCode } = this.props;
    return this.state.secretCodeRevealed ? (
      <React.Fragment>
        <p>
          Type this Time Based code (without spaces) to your favorite app and enter the code you see
          in the app.
        </p>
        <p>
          <code>{insertCharAfterEachNthChar(secretCode, 4)}</code>
        </p>
        <p className="text-center small">
          <button
            type="button"
            className="btn btn-link btn-link-as-href"
            onClick={() => {
              this.setState({ secretCodeRevealed: false });
            }}
          >
            Show QR code.
          </button>
        </p>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <p>Scan this QR code using your favorite app and enter the code you see in the app.</p>
        <p>
          <QRCodeSVG value={generateKeyUri(email, secretCode)} level="H" size={192} />
        </p>
        <p className="text-center small">
          <button
            type="button"
            className="btn btn-link btn-link-as-href"
            onClick={() => {
              this.setState({ secretCodeRevealed: true });
            }}
          >
            Can't scan code? Reveal it.
          </button>
        </p>
      </React.Fragment>
    );
  }
}

ConfirmSoftwareMfaModal.propTypes = {
  confirmSoftwareMfaFn: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  secretCode: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

export default reduxForm({
  form: FORM_ID,
  validate: validate
})(ConfirmSoftwareMfaModal);
