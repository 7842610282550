import validateJs from '../../../utils/validate';

export const constraints = {
  id: {
    presence: { allowEmpty: false },
    length: {
      minimum: 3,
      maximum: 64
    },
    format: {
      pattern: '^[a-z0-9]+(?:-[a-z0-9]+)*$',
      message: (value) => {
        if (value.endsWith('-')) return 'can not end with dash.';

        return 'can only contain alphanumeric characters and dash.';
      }
    }
  },
  name: {
    presence: { allowEmpty: false },
    length: {
      maximum: 128
    }
  },
  type: {
    presence: { allowEmpty: false }
  }
};

export const validate = (app) => {
  let errors = {};
  const result = validateJs.validate(app, constraints);
  if (result) {
    Object.keys(result).forEach((key) => {
      errors[key] = result[key][0];
    });
  }
  return errors;
};
