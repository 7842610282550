import React, { Component, Fragment } from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import { reduxForm } from 'redux-form';

import PropTypes from 'prop-types';
import InlineNotification from '../../../components/InlineNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FORM_ID = 'HomeDeleteUserModal';

class DeleteUserModal extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { user, deleteUserFn, vendor, closeModalFn } = this.props;
    return deleteUserFn(vendor, user.username).then(() => {
      closeModalFn();
    });
  }

  render() {
    const { handleSubmit, deletionType, user, vendor, show, closeModalFn, error } = this.props;
    const isLeaving = deletionType === 'leaving';
    return (
      <Modal show={show} onHide={closeModalFn}>
        <form onSubmit={handleSubmit(this.handleSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>{isLeaving ? 'Leave vendor' : 'Remove developer'}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            {isLeaving ? (
              <Fragment>
                <p className="text-danger">
                  <FontAwesomeIcon icon={'sign-out-alt'} size="3x" />
                </p>
                <p>
                  You are about to leave the vendor <strong>{vendor}</strong>.
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <p className="text-danger">
                  <FontAwesomeIcon icon={['far', 'trash-alt']} size="3x" />
                </p>
                <p>
                  You are about to remove <strong>{user.username}</strong> from the vendor{' '}
                  <strong>{vendor}</strong>.
                </p>
              </Fragment>
            )}
            {error && <InlineNotification type="danger">{error}</InlineNotification>}
          </Modal.Body>
          <Modal.Footer>
            <div className="text-center">
              <Button
                type="submit"
                bsSize="large"
                disabled={this.props.submitting}
                bsStyle="danger"
              >
                {isLeaving ? 'Leave' : 'Remove'}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

DeleteUserModal.propTypes = {
  vendor: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  deleteUserFn: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  deletionType: PropTypes.oneOf(['leaving', 'removing']).isRequired
};

export default reduxForm({
  form: FORM_ID
})(DeleteUserModal);
