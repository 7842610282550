import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Dropzone from 'react-dropzone';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { Link } from '../../../../components/externalLinks';

export const FORM_ID = 'EditAppIconForm';

const hiddenFileField = ({ input }) => {
  return <input {...input} type="hidden" />;
};

class EditAppIconForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      iconPreview: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleSubmit(values) {
    const { uploadAppIconFn, editAppIconCloseModalFn, app, reset } = this.props;
    const vendorId = app.vendor.id;
    return uploadAppIconFn(vendorId, app.id, values).then(() => {
      editAppIconCloseModalFn();
      reset();
    });
  }

  handleReset() {
    this.props.reset();
    this.setState({
      iconPreview: null
    });
  }

  render() {
    const {
      handleSubmit,
      change,
      pristine,
      submitting,
      formStore,
      showModal,
      editAppIconCloseModalFn
    } = this.props;
    const error = this.state.error || this.props.error;
    return (
      <div>
        <Modal
          show={showModal}
          onHide={editAppIconCloseModalFn}
          className={`change-app-icon text-center ${error && 'has-error'}`}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change component icon</Modal.Title>
            <p className="help-block">
              Make sure that component icon meets{' '}
              <Link href="https://developers.keboola.com/extend/publish/#component-icon">
                Component Icon Guidelines
              </Link>
              .
            </p>
          </Modal.Header>
          <form onSubmit={handleSubmit(this.handleSubmit)}>
            <Modal.Body>
              {!error && formStore.file && this.state.iconPreview ? (
                <img
                  className="max-width-100"
                  alt="New component icon preview"
                  src={this.state.iconPreview}
                  onLoad={() => URL.revokeObjectURL(this.state.iconPreview)}
                />
              ) : (
                <Dropzone
                  multiple={false}
                  accept={{ 'image/png': [] }}
                  onDropAccepted={(files) => {
                    const image = new Image();
                    image.onload = () => {
                      if (image.height !== 256 || image.width !== 256) {
                        change('file', null);
                        this.setState({
                          error: 'Please choose square image with resolution 256x256px',
                          iconPreview: null
                        });
                      } else {
                        change('file', files[0]);
                        this.setState({ error: null, iconPreview: image.src });
                      }
                    };
                    image.src = URL.createObjectURL(files[0]);
                  }}
                  onDropRejected={() => {
                    change('file', null);
                    this.setState({
                      error: 'Only PNG files are supported.',
                      iconPreview: null
                    });
                  }}
                  ref={(dropzoneRef) => {
                    this.dropzoneRef = dropzoneRef;
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({
                        className: `dropzone cursor-pointer`
                      })}
                    >
                      <div>
                        <input {...getInputProps()} />
                        {error && (
                          <p className="font-size-1-5em font-weight-bold text-danger">{error}</p>
                        )}
                        <p>
                          <span
                            className={`dropzone-title font-weight-bold ${
                              !error && 'font-size-1-5em'
                            }`}
                          >
                            Drop PNG image here
                          </span>
                          <br />
                          (required resolution 256x256px)
                        </p>
                        <p className="margin-vertical-1em">OR</p>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            this.dropzoneRef.open();
                          }}
                        >
                          Choose file
                        </button>
                        <Field name="file" component={hiddenFileField} />
                      </div>
                    </div>
                  )}
                </Dropzone>
              )}
            </Modal.Body>
            {!this.state.error && formStore.file && this.state.iconPreview && (
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  disabled={pristine || submitting}
                  onClick={this.handleReset}
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={pristine || submitting}
                >
                  Save
                </button>
              </Modal.Footer>
            )}
          </form>
        </Modal>
      </div>
    );
  }
}

EditAppIconForm.propTypes = {
  app: PropTypes.object.isRequired,
  uploadAppIconFn: PropTypes.func.isRequired,
  formStore: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  editAppIconCloseModalFn: PropTypes.func.isRequired
};

export default reduxForm({
  form: FORM_ID
})(EditAppIconForm);
