import React from 'react';
import Link from 'found/Link';
import config from '../../config';

export default ({ children, to, ...rest }) => {
  return (
    <Link to={config.BASE_PATH + to} {...rest}>
      {children}
    </Link>
  );
};
