import React from 'react';
import PropTypes from 'prop-types';

function selectElementContents(element) {
  const range = document.createRange();
  range.selectNodeContents(element);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

const ClickToSelect = ({ children }) => {
  return (
    <div
      onClick={(event) => {
        selectElementContents(event.target);
      }}
    >
      {children}
    </div>
  );
};
ClickToSelect.propTypes = {
  children: PropTypes.element.isRequired
};

export default ClickToSelect;
