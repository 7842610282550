import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getProfile } from '../../auth/reducers/profile';
import { getVendors } from '../reducers/vendors';
import { enableMfa, confirmMfa } from '../../home/actions/enableMfa';
import UserDetail from '../../home/components/UserDetail';
import { editVendor, onUpdateVendor } from '../../admin/actions/vendors';
import { getVendorEditorState } from '../../admin/reducers/vendors';

const propTypes = {
  profile: PropTypes.object.isRequired,
  vendors: PropTypes.array.isRequired
};

class Profile extends Component {
  render() {
    const {
      profile,
      vendors,
      vendorEditorState,
      onToggleEditVendor,
      onUpdateVendor,
      enableMfaFn,
      confirmMfaFn
    } = this.props;
    return (
      <div className="container user-detail">
        <UserDetail
          onToggleEditVendor={onToggleEditVendor}
          onUpdateVendor={onUpdateVendor}
          vendorEditorState={vendorEditorState}
          vendors={vendors}
          profile={profile}
          enableMfaFn={enableMfaFn}
          confirmMfaFn={confirmMfaFn}
        />
      </div>
    );
  }
}

Profile.propTypes = propTypes;

const mapStateToProps = (state) => {
  const profile = getProfile(state);
  const vendors = getVendors(state);
  return {
    vendorEditorState: getVendorEditorState(state),
    profile: profile,
    vendors: vendors.filter((vendor) => {
      return profile.vendors.includes(vendor.id);
    })
  };
};

export default connect(mapStateToProps, {
  onToggleEditVendor: editVendor,
  onUpdateVendor,
  enableMfaFn: enableMfa,
  confirmMfaFn: confirmMfa
})(Profile);
