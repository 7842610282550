import React from 'react';
import withRouter from 'found/withRouter';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Head from '../../../components/Head';
import AppsList from '../components/apps/AppsList';
import FilterBar from '../components/apps/FilterBar';
import { setFilterQuery, setFilterType, setFilterVendor, setFilterStage } from '../actions/apps';
import { getVisibleApps } from '../reducers/apps/list';

class Apps extends React.Component {
  render() {
    const { apps, filter, setFilterQueryFn, setFilterVendorFn, setFilterTypeFn, setFilterStageFn } =
      this.props;
    return (
      <>
        <Head title="Components" />
        <div className="open-apps-filter-wrapper">
          <div className="container">
            <FilterBar
              filter={filter}
              setFilterQueryFn={setFilterQueryFn}
              setFilterVendorFn={setFilterVendorFn}
              setFilterTypeFn={setFilterTypeFn}
              setFilterStageFn={setFilterStageFn}
            />
          </div>
        </div>
        <div className="container">
          {apps.length !== 0 ? (
            <AppsList apps={apps} />
          ) : (
            <div className="text-center margin-vertical-4em">
              <p>
                <FontAwesomeIcon icon={['far', 'meh']} size="3x" />
              </p>
              <p className="font-size-1-5em">
                Could not find any component matching your criteria.
              </p>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const localState = state.open.apps;
  return {
    apps: getVisibleApps(localState.list, localState.filter),
    filter: localState.filter
  };
};

Apps = withRouter(
  connect(mapStateToProps, {
    setFilterQueryFn: setFilterQuery,
    setFilterVendorFn: setFilterVendor,
    setFilterTypeFn: setFilterType,
    setFilterStageFn: setFilterStage
  })(Apps)
);

export default Apps;
