import * as actionTypes from '../../actions/apps';

export default (state = {}, action) => {
  if (action.type === actionTypes.OPEN_FETCH_APP_DETAIL_SUCCESS) {
    return { ...state, [action.appId]: action.response };
  } else {
    return state;
  }
};

export const getAppDetail = (state, appId) => {
  return state[appId];
};

export const hasAppDetail = (state, appId) => {
  return !(state[appId] === undefined);
};
