import validateJs from '../../../utils/validate';

const constraints = {
  vendor: {
    presence: { allowEmpty: false }
  },

  email: {
    presence: { allowEmpty: false },
    email: true
  }
};

export const validate = (app) => {
  let errors = {};
  const result = validateJs.validate(app, constraints);
  if (result) {
    Object.keys(result).forEach((key) => {
      errors[key] = result[key][0];
    });
  }
  return errors;
};
