import React from 'react';
import PropTypes from 'prop-types';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import ReactBootstrapMenuItem from 'react-bootstrap/lib/MenuItem';
import ReactBootstrapNavItem from 'react-bootstrap/lib/NavItem';

import Link from '../components/Link';
import MenuItem from '../components/MenuItem';
import NavItem from '../components/NavItem';
import { routes } from '../routesConstants';
import Logo from '../components/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NavbarAdmin = ({ allVendors, onSelectVendor, profile, logoutFn, currentPath }) => {
  return (
    <Navbar>
      <Navbar.Header>
        <Navbar.Brand>
          <Link className="navbar-brand" to={routes.ROUTE_ADMIN}>
            <Logo />
          </Link>
        </Navbar.Brand>
      </Navbar.Header>
      <Nav activeHref={currentPath} pullRight>
        <NavItem href={routes.ROUTE_ADMIN}>Components</NavItem>
        <NavItem href={routes.ROUTE_ADMIN_DEVELOPERS}>Developers</NavItem>
        <NavItem href={routes.ROUTE_ADMIN_VENDORS}>Vendors</NavItem>
        <NavDropdown title={profile.name} id="admin-super-navbar-nav-account-dropdown">
          <ReactBootstrapNavItem disabled>
            <FontAwesomeIcon icon={'exclamation-triangle'} /> Using as admin
          </ReactBootstrapNavItem>
          <ReactBootstrapMenuItem divider />
          <ReactBootstrapMenuItem header>Switch to vendor</ReactBootstrapMenuItem>
          {allVendors.map((v, idx) => (
            <ReactBootstrapMenuItem key={idx} active={false} onClick={() => onSelectVendor(v)}>
              {v}
            </ReactBootstrapMenuItem>
          ))}
          <ReactBootstrapMenuItem divider />
          <MenuItem href={routes.ROUTE_ADMIN_PROFILE}>
            User Detail <FontAwesomeIcon icon={'user'} pull="right" />
          </MenuItem>
          <ReactBootstrapMenuItem onClick={logoutFn}>
            Logout <FontAwesomeIcon icon={'long-arrow-alt-left'} pull="right" />
          </ReactBootstrapMenuItem>
        </NavDropdown>
      </Nav>
    </Navbar>
  );
};

NavbarAdmin.propTypes = {
  allVendors: PropTypes.array.isRequired,
  onSelectVendor: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  logoutFn: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired
};

export default NavbarAdmin;
