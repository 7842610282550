import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../../../components/Link';
import { routes } from '../../../routesConstants';

const howItWorksSections = [
  {
    icon: 'user',
    title: 'Create an account',
    text: 'We will confirm your account and vendor'
  },
  {
    icon: 'info-circle',
    title: 'Explore Resources & API',
    text: 'Learn more about how to make a KBC component',
    link: routes.ROUTE_OPEN_RESOURCES
  },
  {
    icon: 'pencil-alt',
    title: 'Create & Upload component',
    text: 'Use our resources and API'
  },
  {
    icon: ['far', 'paper-plane'],
    title: 'Make your component Public',
    text: 'We will check your component before it goes public'
  }
];

const renderRightPart = (section) => {
  if (section.link) {
    return (
      <div className="right-part">
        <h6>{section.title}</h6>
        <p>
          <Link to={section.link}>{section.text}</Link>
        </p>
      </div>
    );
  } else {
    return (
      <div className="right-part">
        <h6>{section.title}</h6>
        <p>{section.text}</p>
      </div>
    );
  }
};

export default () => {
  return (
    <div className="how-it-works">
      <div className="container">
        <div className="divider divider-big" />
        {howItWorksSections.map((section, index) => {
          return (
            <div key={index}>
              <div className="item">
                <div className="left-part hidden-xs" />
                <div className="middle-part">
                  <div className="icon">
                    <FontAwesomeIcon icon={section.icon} />
                  </div>
                </div>
                {renderRightPart(section)}
              </div>
              <div
                className={
                  index === howItWorksSections.length - 1 ? 'divider divider-big' : 'divider'
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
