import React from 'react';
import LoadingBar from 'react-redux-loading-bar';
import NotificationsSystem from 'reapop';

import theme from '../../reapop-theme/index';
import Head from '../components/Head';

export default ({ children }) => {
  return (
    <>
      <Head />
      <LoadingBar className="loading-bar" />
      <NotificationsSystem theme={theme} />
      {children}
    </>
  );
};
