export default {
  control: (provided, state) => {
    return {
      ...provided,
      borderColor: '#c9cbcd'
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      zIndex: 5 // Due to Ace Editor (ace_gutter has "z-index: 4")
    };
  },
  multiValue: (provided, state) => {
    return {
      ...provided,
      backgroundColor: '#2AB4E2'
    };
  },
  multiValueLabel: (provided, state) => {
    return {
      ...provided,
      color: '#FFFFFF',
      fontWeight: 700
    };
  },
  multiValueRemove: (provided, state) => {
    return {
      ...provided,
      color: '#FFFFFF'
    };
  }
};
