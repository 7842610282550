import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AppsTable from '../components/apps/AppsTable';
import NoApps from '../components/apps/NoApps';
import { getFilter } from '../reducers/apps/filter';
import { getFilteredVendorApps, getVendorApps } from '../reducers/apps/list';
import { isDeletingApp, getDeleteErrorMessage } from '../reducers/apps/deleteApp';
import { getCurrentVendor } from '../../auth/reducers/profile';
import { approveApp, deleteApp, setFilter } from '../actions/apps';
import { deprecateApp } from '../actions/apps/deprecateApp';

class Index extends React.Component {
  render() {
    return (
      <div className="container">
        {this.props.apps.length !== 0 ? (
          <AppsTable
            apps={this.props.filteredApps}
            deleteApp={this.props.deleteApp}
            isDeletingAppFn={this.props.isDeletingAppFn}
            approveApp={this.props.approveApp}
            getDeleteAppErrorFn={this.props.getDeleteAppErrorFn}
            filter={this.props.filter}
            onChangeFilterFn={this.props.setFilter}
            deprecateAppFn={this.props.deprecateAppFn}
          />
        ) : (
          <NoApps />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const currentVendor = getCurrentVendor(state);
  return {
    filter: getFilter(state),
    isDeletingAppFn: isDeletingApp(state),
    getDeleteAppErrorFn: getDeleteErrorMessage(state),
    apps: getVendorApps(state, currentVendor),
    filteredApps: getFilteredVendorApps(state, currentVendor)
  };
};

Index.propTypes = {
  apps: PropTypes.array.isRequired,
  filteredApps: PropTypes.array.isRequired,
  deleteApp: PropTypes.func.isRequired,
  isDeletingAppFn: PropTypes.func.isRequired,
  approveApp: PropTypes.func.isRequired,
  getDeleteAppErrorFn: PropTypes.func.isRequired,
  deprecateAppFn: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired
};

export default connect(mapStateToProps, {
  approveApp,
  deleteApp,
  setFilter,
  deprecateAppFn: deprecateApp
})(Index);
