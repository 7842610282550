import validate from 'validate.js';

const checkMaximumLength = (value, maximumLength) => {
  return validate.single(JSON.stringify(value), { length: { maximum: maximumLength } });
};

validate.validators.object = function (value, options, key, attributes) {
  if (
    typeof value === 'undefined' ||
    value === null ||
    (typeof value === 'object' && !Array.isArray(value))
  ) {
    if (options && options.maximumLength) {
      return checkMaximumLength(value, options.maximumLength);
    }
    return null;
  }

  const error = 'is not valid JSON object';

  try {
    const parsed = JSON.parse(value);
    if (parsed && typeof parsed === 'object') {
      if (options && options.maximumLength) {
        return checkMaximumLength(parsed, options.maximumLength);
      }
      return null;
    }
  } catch (e) {
    return error;
  }
  return error;
};

validate.validators.array = function (value, options, key, attributes) {
  if (typeof value === 'undefined' || value === null || Array.isArray(value)) {
    if (options && options.maximumLength) {
      return checkMaximumLength(value, options.maximumLength);
    }
    return null;
  }
  return 'is not valid JSON array';
};

validate.validators.notStartLikeUrl = function (value, options, key, attributes) {
  if (!value) {
    return null;
  }
  if (
    !validate({ key: value }, { key: { format: /^https:\/\/.*/ } }) ||
    !validate({ key: value }, { key: { format: /^http:\/\/.*/ } })
  ) {
    return options && options.message ? options.message : 'cannot be an URL.';
  }
  return null;
};

validate.validators.urlWithAllowEmpty = function (value, options, key, attributes) {
  if (!value) {
    return null;
  }
  return validate.single(value, { url: true });
};

validate.validators.memoryLimit = function (value, options) {
  if (validate({ key: value }, { key: { format: /^\d+[bkmg]$/ } })) {
    return options && options.message
      ? options.message
      : 'accepts a positive integer, followed by a suffix of b, k, m or g.';
  }

  return null;
};

export default validate;
