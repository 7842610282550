import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';

import { DescriptionBlock } from '../custom';

const TextAreaField = ({
  formId,
  input,
  label,
  placeholder,
  description,
  rows,
  meta: { touched, error }
}) => {
  return (
    <FormGroup
      controlId={`${formId}-${input.name}`}
      validationState={touched && error ? 'error' : null}
    >
      {label && <ControlLabel>{label}</ControlLabel>}
      <FormControl
        {...input}
        componentClass="textarea"
        placeholder={placeholder}
        rows={rows || ''}
      />
      {touched && error && <HelpBlock>{error}</HelpBlock>}
      {description && <DescriptionBlock>{description}</DescriptionBlock>}
    </FormGroup>
  );
};
TextAreaField.propTypes = {
  formId: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  description: PropTypes.node,
  rows: PropTypes.string,
  meta: PropTypes.object.isRequired
};

export default TextAreaField;
