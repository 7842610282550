import FarceActions from 'farce/Actions';

import { getAccessToken } from '../reducers/tokens';
import { routes } from '../../../routesConstants';
import { fetchProxy } from '../../actions';
import config from '../../../../config';

export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_FAILURE = 'AUTH_LOGOUT_FAILURE';

const logoutRequest = () => ({
  type: AUTH_LOGOUT_REQUEST
});

export const logoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS
});

const redirectAfterLogout = () => {
  return FarceActions.push(config.BASE_PATH + routes.ROUTE_AUTH_LOGIN);
};

export const logout = () => (dispatch, getState) => {
  dispatch(logoutRequest());

  return dispatch(
    fetchProxy('/auth/logout', {
      method: 'POST',
      headers: {
        Authorization: getAccessToken(getState())
      }
    })
  ).then(() => {
    dispatch(logoutSuccess());
    dispatch(redirectAfterLogout());
    // we don't care if something happen (e.g. accessToken expired)
    // and logout user anyway
  });
};
