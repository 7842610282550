const normalizeBoolean = (value) => {
  return value === true;
};

const normalizeObject = (value) => {
  if (value === '') {
    return null;
  } else {
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  }
};

const normalizeEmptyStringToNull = (value) => {
  if (value === '') {
    return null;
  }
  return value;
};

const normalizeEmail = (value) => {
  return value.trim();
};

export { normalizeBoolean, normalizeObject, normalizeEmptyStringToNull, normalizeEmail };
