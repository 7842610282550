import React, { Component } from 'react';

import Link from '../components/Link';
import Logo from '../components/Logo';

class NavbarOpenSimple extends Component {
  render() {
    return (
      <nav className="navbar navbar-default navbar-simple">
        <div className="container">
          <div className="navbar-header">
            <Link className="navbar-brand" to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavbarOpenSimple;
