import * as actionTypes from '../../actions/apps';

export const initialState = {
  query: '',
  status: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_APPS_SET_FILTER_QUERY:
      return {
        ...state,
        query: action.payload.query
      };
    case actionTypes.ADMIN_APPS_SET_FILTER_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
    default:
      return state;
  }
};

export const getFilter = (state) => {
  return state.admin.apps.filter;
};
