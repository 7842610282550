import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { enableMfa, confirmMfa } from '../actions/enableMfa';
import { getProfile } from '../../auth/reducers/profile';
import EnableSoftwareMfaForm from '../components/EnableSoftwareMfaForm';

class EnableMfa extends React.Component {
  render() {
    const { confirmMfaFn, enableMfaFn, profile } = this.props;
    return (
      <div className="static-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Multi-factor authentication</h4>
            </div>
            <div className="modal-body">
              <p className="text-center">
                <img
                  src={process.env.PUBLIC_URL + '/images/mfa.svg'}
                  alt="Multi-factor authentication logo"
                  className="mfa-form-logo"
                />
              </p>
              <p className="text-center">
                To continue using this application, you must enable Multi-factor authentication.
              </p>
            </div>
            <div className="modal-footer">
              <div className="text-center">
                <EnableSoftwareMfaForm
                  email={profile.email}
                  enableMfaFn={enableMfaFn}
                  confirmMfaFn={confirmMfaFn}
                  buttonText="Set up using an app"
                  buttonClass="btn btn-primary btn-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EnableMfa.propTypes = {
  enableMfaFn: PropTypes.func.isRequired,
  confirmMfaFn: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  };
};

export default connect(mapStateToProps, {
  enableMfaFn: enableMfa,
  confirmMfaFn: confirmMfa
})(EnableMfa);
