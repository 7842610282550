import React from 'react';
import PropTypes from 'prop-types';

import EditVendorModal from '../../admin/components/EditVendorModal';
import NewLineToBr from '../../../components/NewLineToBr';
import { EmailLink } from '../../../components/externalLinks';
import EnableSoftwareMfaForm from './EnableSoftwareMfaForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class UserDetail extends React.Component {
  render() {
    const { vendors, profile, vendorEditorState, onToggleEditVendor, onUpdateVendor } = this.props;

    return (
      <div>
        <EditVendorModal
          onUpdateVendor={onUpdateVendor}
          onHide={() => onToggleEditVendor(false, {})}
          show={vendorEditorState.show}
          vendor={vendorEditorState.vendor}
        />
        <h1 className="text-center">Profile</h1>

        <div className="row">
          <div className="col-md-4 col-md-offset-2 col-sm-6">
            <h3 className="text-center text-color-primary">Details</h3>

            <div className="tiles-container">
              <div className="tile">
                <div>
                  <ul className="list-unstyled font-size-smaller">
                    <li>
                      <strong>{profile.name}</strong>
                    </li>
                    <li>
                      <EmailLink email={profile.email} />
                    </li>
                  </ul>
                  <hr />
                  {this.renderMfa()}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <h3 className="text-center text-color-primary">Vendors</h3>
            <div className="tiles-container">
              {vendors.map((vendorDetail, index) => {
                return (
                  <div className="tile tile-vendors-list" key={index}>
                    <div>
                      <h5 className="font-size-smaller">
                        {vendorDetail.name}
                        <button
                          onClick={() => {
                            onToggleEditVendor(true, vendorDetail);
                          }}
                          className="btn btn-link btn-link-as-href pull-right"
                        >
                          <FontAwesomeIcon icon={'pencil-alt'} />
                        </button>
                      </h5>
                      <ul className="list-unstyled font-size-smaller">
                        <li>
                          <span>Vendor ID: </span>
                          <code>{vendorDetail.id}</code>
                        </li>
                        <li>
                          <EmailLink email={vendorDetail.email} />
                        </li>
                        <li>
                          <NewLineToBr text={vendorDetail.address} />
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMfa() {
    const { profile, enableMfaFn, confirmMfaFn } = this.props;
    return (
      <div className="font-size-smaller">
        <p>
          Multi-factor authentication:{' '}
          <strong>
            {profile.mfa !== false ? 'Enabled' : 'Disabled'}
            {profile.mfa === 'SMS_MFA' && ' (SMS)'}
          </strong>
        </p>
        {profile.mfa === 'SMS_MFA' && (
          <p className="text-danger">
            SMS based verification is deprecated. Please click the button bellow to setup the MFA
            using an app.
          </p>
        )}
        <div className="text-center">
          <EnableSoftwareMfaForm
            enableMfaFn={enableMfaFn}
            confirmMfaFn={confirmMfaFn}
            email={profile.email}
            buttonText={
              profile.mfa === 'SOFTWARE_TOKEN_MFA' ? 'Change device' : 'Set up using an app'
            }
            buttonClass="btn btn-primary"
          />
        </div>
      </div>
    );
  }
}

UserDetail.propTypes = {
  vendors: PropTypes.array.isRequired,
  profile: PropTypes.object.isRequired,
  vendorEditorState: PropTypes.object.isRequired,
  onToggleEditVendor: PropTypes.func.isRequired,
  onUpdateVendor: PropTypes.func.isRequired
};

export default UserDetail;
