/**
 * Removes null values from object
 * @param object
 * @returns {{}}
 */
export const removeNulls = (object) => {
  let newObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key] !== null) {
      if (typeof object[key] === 'object' && !Array.isArray(object[key])) {
        newObject[key] = removeNulls(object[key]);
      } else {
        newObject[key] = object[key];
      }
    }
  });
  return newObject;
};

export const filterObject = (definition, object) => {
  const filteredFields = {};
  definition.forEach((field) => {
    if (typeof field === 'string' && object.hasOwnProperty(field)) {
      filteredFields[field] = object[field];
    }
    if (typeof field === 'object' && object.hasOwnProperty(field.property)) {
      filteredFields[field.property] = {};
      field.allowedProperties.forEach((subField) => {
        if (object[field.property].hasOwnProperty(subField)) {
          filteredFields[field.property][subField] = object[field.property][subField];
        }
      });
    }
  });
  return filteredFields;
};

export const logException = (ex, context) => {
  window.Raven.captureException(ex, {
    extra: context
  });
  /*eslint no-console:0*/
  window.console && console.error && console.error(ex);
};

export const findNameById = (id, objectsWithName) => {
  const found = objectsWithName.find((o) => o.id === id);
  return found ? found.name : id || '[empty-string]';
};

export const insertCharAfterEachNthChar = (text, position, character = ' ') => {
  if (text.length === 0) {
    return '';
  }
  let result = [];
  let remaining = text;
  while (remaining.length > position) {
    result.push(remaining.substr(0, position));
    remaining = remaining.substr(position);
  }
  result.push(remaining);
  return result.join(character);
};

// Sort apps: public -> private -> deprecated, than order by name
export const sortApps = (apps) => {
  return apps.sort((a, b) => {
    if (!a.matchedByKey && b.matchedByKey) return 1;
    if (a.matchedByKey && !b.matchedByKey) return -1;
    if (!a.isPublic && b.isPublic) return 1;
    if (a.isPublic && !b.isPublic) return -1;
    if (a.isDeprecated && !b.isDeprecated) return 1;
    if (!a.isDeprecated && b.isDeprecated) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    return 0;
  });
};

export const hasIcon = (app) => !!(app.icon && (app.icon['128'] || app.icon['64']));

export const getIcon = (app) => {
  if (!hasIcon(app)) {
    return process.env.PUBLIC_URL + '/images/default-app-icon.png';
  }

  // fallback to 64px if 128px variant is in legacy format (incremental number istead of Date.now())
  if (!/\d{12}\.png$/.test(app.icon['128'])) {
    return app.icon['64'];
  }

  return app.icon['128'];
};

export const hasRepository = (app) => !!(app.repository && app.repository.uri);

export const hasAllFieldsForPublishing = (app) =>
  !!(
    hasIcon(app) &&
    hasRepository(app) &&
    app.name &&
    app.type &&
    app.shortDescription &&
    app.longDescription &&
    app.licenseUrl &&
    app.documentationUrl
  );

const STACK_ENDINGS = [
  'keboola\\.com',
  'keboola\\.cloud',
  'keboola-testing\\.com',
  'keboola\\.dev'
];

export const createKbcComponentUrl = (projectUrl, appType, appId) => {
  if (appType === 'extractor' || appType === 'writer' || appType === 'application') {
    const regex = new RegExp(`https://\\S+\\.(${STACK_ENDINGS.join('|')})/admin/projects/\\S[^/]+`);
    const matchedURLs = projectUrl.match(regex);
    if (matchedURLs && matchedURLs[0]) {
      const strippedProjectUrl = matchedURLs[0];
      return `${strippedProjectUrl}/components/${appId}`;
    }
  }
  return null;
};
